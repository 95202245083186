import React from 'react';
import RequestcallData from '../../../component/Admin/requestCallData';
import Layout from '../../../component/Admin/Layout/Layout';



const Requestcalldata: React.FunctionComponent = () => {

    return (
      <Layout>
        <RequestcallData/>
      </Layout>
  );
}

export default Requestcalldata;