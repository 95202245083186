import { createMuiTheme } from '@material-ui/core/styles';
// Create a theme instance.
const theme = createMuiTheme({
    typography: {
        fontFamily: [
          'Montserrat',
          'sans-serif',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
        ].join(','),
      },
});

export default theme;