import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { MdArrowBack } from "react-icons/md";
import Layout from './Layout/Layout';
import Admin_Api from '../../API/Admin_Api';
import Api from '../../API/Api';
import { Link, useHistory } from 'react-router-dom';
import { useSnackBar } from '../../Context/Snackbar/withSnackbar';
import { useEffect, useState } from 'react';
import moment from 'moment';

function VolunteerReqView(props: any) {
  const { showSnackBar } = useSnackBar();

  const [data,setData]=useState<any>({})
  const id = props.match.params.id
 useEffect(() => {
  const getData = async()=>{
    await Admin_Api.Volunteer_request_view(id)
    .then(response=>{
        setData(response.data.data)
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        if (error.response.data.message) {
          showSnackBar(error.response.data.message, 'error');
        } else if (error.response.data.error) {
          showSnackBar(error.response.data.error, 'error');
        } else if (error.response.data.errors) {
          showSnackBar(error.response.data.errors.join('\n'), 'error');
        }
      } else {
        showSnackBar(error.message, 'error');
      }
    });
  }
  getData();
 }, [])  
 const {
   center_name,
  center_state,
  center_zip,created_at,
    email,
  requested_by,
  source_of_info,
  subscribe_mail,
  volunteer_opportunity,  
 } = data
  return (
    <>
      <Layout>
        <Container>
          <Typography component="div" className="centers-admin-panel">
            <Typography component="div" className="centers-admin-header">
              <Typography component="div" className="centers-admin-header-top-bar">
                <Typography component="h3" className="centers-admin-section-text flex items-center"><Link to="/volunteer_list"><MdArrowBack className="mr-1 text-pc-1" /></Link>Volunteer Request</Typography>
              </Typography>
            </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <span className="text-tc-5 text-xs font-notosans">Center Name</span>
                  <p className="font-notosans">{center_name}</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <span className="text-tc-5 text-xs font-notosans">Center State</span>
                  <p className="font-notosans">{center_state}</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <span className="text-tc-5 text-xs font-notosans">Zip Code</span>
                  <p className="font-notosans">{center_zip}</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <span className="text-tc-5 text-xs font-notosans">Volunteer Opportunity</span>
                  <p className="font-notosans">{volunteer_opportunity}</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <span className="text-tc-5 text-xs font-notosans">Requested On</span>
                  <p className="font-notosans">{moment(created_at).format('MM/DD/YYYY')}</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <span className="text-tc-5 text-xs font-notosans">Requested By</span>
                  <p className="font-notosans">{requested_by}</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <span className="text-tc-5 text-xs font-notosans">Email</span>
                  <p className="font-notosans">{email}</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <span className="text-tc-5 text-xs font-notosans">How did you hear about volunteer opportunities as RAINN? </span>
                  <p className="font-notosans">{source_of_info}</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <span className="text-tc-5 text-xs font-notosans">Join RAINN's mailing list</span>
                  <p className="font-notosans">{subscribe_mail}</p>
                </Grid>
              </Grid>  

          </Typography>
        </Container>
      </Layout>
    </>
  );
}

export default VolunteerReqView