import React from 'react'
import CenterInfo from '../component/CenterData'
import Layout from '../component/Layout/Layout'

const CenterDetail:React.FunctionComponent = (props:any) => {
  return (
    <div>
      <Layout >
      <CenterInfo {...props}/>
      </Layout>
    </div>
  )
}

export default CenterDetail
