import React from 'react';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container';

const states = [
    { state: 'Alabama' },
    { state: 'Arkansas' },
    { state: 'New York' },
    { state: 'California' },
];

function uploadCallData()
{
    return(
        <>
        <Container>
        <Typography component="div" className="rainn-lg-container centers-admin-panel">
            <Typography component="div" className="centers-admin-header">
                <Typography component="div" className="centers-admin-header-top-bar">
                    <Typography component="h3" className="centers-admin-section-text">Upload Call Data</Typography>
                </Typography>
            </Typography>
            <Typography component="div" className="pt-6 pb-16">
                <Typography component="p" className="text-pc-1 text-2xl">Click the button below to upload call data.</Typography>
            </Typography>
            <Grid container spacing={2} className="bg-tc-7 p-3">
                <Grid item xs={12}>
                    <Typography component="h3" className="centers-admin-section-text">Upload Call Data</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button className="button-primary">Upload</Button>
                </Grid>
            </Grid>
        </Typography>
        </Container>
        </>
    );
}

export default uploadCallData