import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Popover from '@material-ui/core/Popover';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import Api from '../../API/Admin_Api';
import { CircularProgress } from '@material-ui/core';
import { MdModeEdit, MdDelete } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useSnackBar } from '../../Context/Snackbar/withSnackbar';
import moment from 'moment';
interface Data {
  Action: any;
  id: 0;
  title: string;
  start_date: any;
  end_date: any;
}

type Order = 'asc' | 'desc';
interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: 'title',
    numeric: false,
    disablePadding: true,
    label: 'OPPORTUNITY NAME',
  },
  {
    id: 'start_date',
    numeric: false,
    disablePadding: false,
    label: 'START DATE',
  },
  { id: 'end_date', numeric: false, disablePadding: false, label: 'END DATE' },
  { id: 'Action', numeric: false, disablePadding: false, label: 'ACTION' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
);

export default function EnhancedTable(props: any) {
  const { showSnackBar } = useSnackBar();
  const classes = useStyles();
  const [rows, setRows] = useState<any>([]);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('title');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(true);
  const [emptyData, setemptyData] = React.useState(false);
  const [deleteById, setDeleteById] = useState('');
  const [count, setCount] = useState(0);
  const [title, setTitle] = useState('');

  const getdata = async () => {
    await Api.Opportunity_List(page + 1, rowsPerPage, order, orderBy)
      .then((response: any) => {
        if (response.data && response.data.success) {
          setLoading(false);
          setemptyData(false);
          if (!response.data.data.records.length) {
            setemptyData(true);
          } else {
            setRows(response.data.data.records);
            setCount(response.data.data.records_total);
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  };

  useEffect(() => {
    getdata();
  }, [page, rowsPerPage, order, orderBy]);

  const handleDelete = (id: number) => {
    Api.Opportunity_delete(id)
      .then((response: any) => {
        if (response.data && response.data.success) {
          handleClose();
          setRows(rows.filter((row: any) => row.id !== id));
          showSnackBar(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [anchorEl, setAnchorEl] = React.useState<SVGElement | null>(null);

  const handleClick = (
    event: React.MouseEvent<SVGElement>,
    deleteId: any,
    titleName: string
  ) => {
    setDeleteById(deleteId);
    setTitle(titleName);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Container>
        <Typography
          component="div"
          className="rainn-lg-container centers-admin-panel"
        >
          <Typography
            component="div"
            className="flex justify-between items-center py-5 border-b border-tc-6 mb-3"
          >
            <Typography component="h1" className="centers-admin-section-text">
              Opportunity List
            </Typography>
          </Typography>
        </Typography>

        <Popover
          className="centers-admin-popover"
          id={id}
          open={open}
          anchorEl={anchorEl}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 0, left: 0 }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
        >
          <Typography component="div" className="p-3 text-center">
            <p className="font-notosans text-pc-1 mb-3">
              Are you sure you want to remove <span>{title}</span> ?
            </p>
            <Button
              className="button-primary button-small mr-2"
              onClick={() => handleDelete(deleteById as any)}
            >
              Yes
            </Button>

            <Button
              className="button-secondary button-small"
              onClick={handleClose}
            >
              No
            </Button>
          </Typography>
        </Popover>

        {emptyData ? (
          <Typography component="div" className="text-center pt-10 pb-10">
            No Data Found
          </Typography>
        ) : (
          <Typography component="div" className="centers-admin-table">
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                {loading ? (
                  <CircularProgress disableShrink />
                ) : (
                  <TableBody>
                    {rows?.map((row: any, index: any) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.title}
                          </TableCell>
                          <TableCell>
                            <Typography>{row.start_date}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{row.end_date}</Typography>
                          </TableCell>
                          <TableCell>
                            { row.is_actionable &&
                            <span className="flex items-center">
                              <Link to={`opportunity_edit/${row.id}`}>
                                <MdModeEdit
                                  title="edit"
                                  className="centers-admin-table-icon"
                                />
                              </Link>
                              <MdDelete
                                title="delete"
                                className="centers-admin-table-icon"
                                onClick={(e) =>
                                  handleClick(e, row.id, row.title)
                                }
                              />
                            </span>
                    }
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Typography>
        )}
      </Container>
    </>
  );
}
