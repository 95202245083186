import React from 'react';
import OpportunityListData from '../../../component/Admin/opportunityList';
import Layout from '../../../component/Admin/Layout/Layout';



const OpportunityList: React.FunctionComponent = () => {

    return (
      <Layout>
        <OpportunityListData/>
      </Layout>
  );
}

export default OpportunityList;