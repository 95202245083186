import React from 'react';
import Form from '../component/Volunteer/VolunteerForm';
import Layout from '../component/Layout/Layout';
import StaticHomeContent from '../component/StaticHomeContent';


const VolunteerSearch: React.FunctionComponent = (props:any) => {
    return (
      <Layout>
      <Form/>
      <StaticHomeContent {...props}/>
    </Layout>
  );
}

export default VolunteerSearch;