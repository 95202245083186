import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import PageNotFound from '../../component/PageNotFound';
type Props = {
  [key: string]: any;
};


const ProtectedRoute: React.FunctionComponent<Props> = (protected_route) => {
  return (
    <Route
      path={protected_route.path}
      render={(props) => {
        const token:any = JSON.parse(localStorage.getItem('token')as any)
        if(token === null)
        {
          return <Redirect to='/login'/>
        }
        const decodedToken: any = jwt_decode(token?.token as any);
        const currentDate = new Date();
        let permission = false;
        if(protected_route.roles && protected_route.roles.length > 0) { 
          // permission = token?.role.split(',').map((val:any)=>protected_route.roles.includes(val)); 
          permission = token?.role.split(',').some((val:any)=>protected_route.roles.includes(val)) 

          } 
        if (decodedToken?.exp * 1000 > currentDate.getTime() && token !== '') {
         
         return(
           <>
          {permission ?
           <protected_route.component
              {...props}
              protected_routes={protected_route.routes}
            />
             : <PageNotFound/>
          } 
          </>
          );
         
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
