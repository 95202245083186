import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Autocomplete } from '@material-ui/lab';
import Container from '@material-ui/core/Container';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import VolunteerList from './volunteerReqList';
import { useForm } from '../../hooks/useForm';
import api from '../../API/Api';
import moment from 'moment';
import { useSnackBar } from '../../Context/Snackbar/withSnackbar';
import Admin_Api from '../../API/Admin_Api';

function VolunteerListSearch() {
  const { showSnackBar } = useSnackBar();

  const entryInput = {
    data: {
      categories: [
        { name: { en: '', es: '' }, options: [] },
        { name: { en: '', es: '' }, options: [] },
        {
          name: { en: '', es: '' },
          options: [
            {
              id: '',
              name: {
                en: '',
                es: '',
              },
            },
          ],
        },
      ],
      states: [],
      center_count: [],
    },
  };
  const [formFieldInfo, setformFieldInfo] = useState(entryInput);
  const [States, setStates] = useState(null);
  const [StatesCode, setStatesCode] = useState('');
  const [opportunityInfo, setopportunityInfo] = useState([]);
  const [opportunityInfoId, setopportunityInfoId] = useState([]);
  const [opportunity,setOpportunity]= useState([{id:'',title:''}])
  useEffect(() => {
    api
      .getFormData()
      .then((response: any) => {
        if (response.data && response.data.success) {
          setformFieldInfo(response.data);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
      Admin_Api.Opportunity_form_data()
      .then((response: any) => {
        if (response.data && response.data.success) {
          setOpportunity(response.data.data)
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  }, []);

  const Initial = {
    zip: '',
  };
  const { Values, setValues, handleChange, resetForm } = useForm(Initial);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const searchInitial = {
    state: '',
    zip: 0,
    categoryId: [],
    startDate: '',
  };
  const [searchData, setSearchData] = useState(searchInitial);

  const handleStartDate = (date: Date | null) => {
    setStartDate(moment(date).format('MM/DD/YYYY') as any);
  };

  const handleApply = () => {
    setSearchData({
      state: StatesCode,
      zip: Values.zip | 0,
      categoryId: opportunityInfoId,
      startDate: startDate as any,
    });
  };

  const handleClear = () => {
    setValues(Initial);
    setStartDate(null);
    setStates(null);
    setStatesCode('');
    setopportunityInfo([]);
    setopportunityInfoId([]);
    setSearchData(searchInitial);
  };

  return (
    <>
      <Container>
        <Typography
          component="div"
          className="rainn-lg-container centers-admin-panel"
        >
          <Typography
            component="div"
            className="flex justify-between items-center py-5 border-b border-tc-6 mb-3"
          >
            <Typography component="h1" className="centers-admin-section-text">
              Volunteer Request
            </Typography>
          </Typography>
          <Grid container spacing={3} className="bg-tc-7 w-full m-0">
            <Grid item md={3} xs={12}>
              <Autocomplete
                disableClearable
                clearOnEscape
                options={formFieldInfo?.data.states}
                getOptionLabel={(option: any) => {
                  return option.name;
                }}
                openOnFocus
                value={States}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setStatesCode(newValue.code);
                  }
                  setStates(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="centers-admin-autocomplete"
                    label="Select state"
                  />
                )}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                type="number"
                className="centers-admin-input"
                label="Add Zip Code"
                name="zip"
                value={Values.zip}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <Autocomplete
                multiple
                limitTags={2}
                id="opportunityInfo"
                options={opportunity}
                getOptionLabel={(option) => option.title as any}
                value={opportunityInfo}
                onChange={(event, newInput) => {
                  if (newInput !== null) {
                    setopportunityInfoId(
                      JSON.parse(JSON.stringify(newInput)).map(
                        (doc: { id: string }) => doc.id
                      )
                    );
                    setopportunityInfo(newInput as any);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="centers-admin-autocomplete"
                    label="Select Volunteer opportunity"
                  />
                )}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="start-date-picker"
                  label="Requested on"
                  value={startDate}
                  onChange={handleStartDate}
                  className="centers-admin-date-picker"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} className="flex justify-end">
              <Button className="button-primary mr-3" onClick={handleApply}>
                Apply
              </Button>
              <Button className="button-transparent" onClick={handleClear}>
                Clear
              </Button>
            </Grid>
          </Grid>
        </Typography>
        <VolunteerList
          state={searchData.state}
          zip={searchData.zip}
          volunteerOpportunityId={searchData.categoryId}
          requestedOn={searchData.startDate}
        />
      </Container>
    </>
  );
}
export default VolunteerListSearch;
