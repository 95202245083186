import axios from 'axios'
export const instance = axios.create({
    //baseURL: 'https://centers-staging.rainn.org/api',
     baseURL:'https://centers.rainn.org/api',
    withCredentials: false,
});
export default {
    getFormData: () =>
    instance({
        'method':'GET',
        'url':'/form-data/center-search-form',
       
    }),
    getImage: (appno:string,fd:any) =>
    instance({
        'method':'POST',
        'url':`/auth/upload-logo/MIM1625455559`,
         'params':fd,
         headers: {
          'content-type': 'multipart/form-data'
      }
    }),
    searchCenters : (state: any,zip:any,radius:number,categoryIds:string[]) =>
    instance({
        'method':'GET',
        'url':'/search/centers',
        'params': {
               'zip':zip,
               'state':state,
               'radius':radius,
               'categoryIds':[categoryIds].join(","),
        },
    }),
    // notificationAPI : (centerId:string,message:string,token:any) =>
    // instance({
    //     'method':'POST',
    //     'url':'/search/center/send-message-notification',
    //     'params':{
    //         'centerId':centerId,
    //         'message':message,
    //         'recaptchaToken':token
    //     }
    // }),
    logInAPI: (email:string,password:string) =>
    instance({
        'method':'POST',
        'url':'/auth/login',
        data:{
          email,
          password
        },
      }),
      ForgotPassword: (email:string) =>
      instance({
          'method':'POST',
          'url':'/auth/password/generate-password-reset-link',
          data:{
            email,
          },
        }),  
      Application_number: (app_no:string) =>
      instance({
          'method':'GET',
          'url':`/auth/get-center-application/${app_no}`,
        }),  
      ResetPassword: (confirmPassword:string,password:string,token:string | null) =>
      instance({
          'method':'POST',
          'url':'/auth/password/reset-password',
          'data':{
            confirmPassword,
            password,
            token
          },
        }),
        UploadImage : (appno:string,file:any)=>{
          instance({
            'method':'POST',
            'url':`/auth/upload-logo/${appno}`,
            'params':file
          })
        },
        Registration: (center:any,collaboration:any,hotline:any,information:any,primary_contact:any,secondary:any,training:any,step:any,token:string,confidentiality:boolean,discrimination:boolean,service:boolean) =>
      instance({
          'method':'POST',
          'url':'/auth/register-center',
           'data':{
             "center":center,
             "collaboration_with_the_state_coalition":collaboration,
             "hotline_info":hotline,
             "information_on_service":information,
            "user":primary_contact,
            "staff_training":training,
            "affiliate_terms_of_service":service ,
            "confidentiality_policy": confidentiality,
            "non_discrimination_policy":discrimination ,
            "step":step,
            "token":token
           }
        }),
        Registration_step1: (center:any,primary_contact:any,secondary:any,token:string) =>
      instance({
          'method':'POST',
          'url':'/auth/register-center',
           'data':{
             "center":center,
            "user":primary_contact,
            "step":1,
            "token":token
          }
        }),
        Volunteer_opportunity_option: () =>
        instance({
            'method':'GET',
            'url':'/form-data/volunteer-form/get-opportunity-option',
           
        }),   
        Volunteer_opportunity_by_id: (id:number) =>
        instance({
            'method':'GET',
            'url':`/search/get-center-opportunity/${id}`,          
        }),   
        
        Volunteer_form_option: () =>
        instance({
            'method':'GET',
            'url':'/form-data/volunteer-form/get-source-of-info',
           
        }),
        
        Volunteer_search: (state_code:string,zip:any,radius:number) =>
        instance({
            'method':'GET',
            'url':'/search/volunteer-center',
           'params':{
                state_code,
                zip,
                radius
           }
        }),
        Volunteer_search_save: (id:number,data:any) =>
        instance({
            'method':'POST',
            'url':`/search/save-volunteer-request/${id}`,
           'data':data
        }),   
}