import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link, useHistory } from 'react-router-dom';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MdKeyboardArrowRight } from 'react-icons/md';
import moment from 'moment';
import Admin_Api from '../../API/Admin_Api';
import { useSnackBar } from '../../Context/Snackbar/withSnackbar';
import Api from '../../API/Api';

function VolunteerNeeds(props: any) {
  const { showSnackBar } = useSnackBar();
  const history = useHistory();
  const Initial = {
    title: '',
    description: '',
  };
  const [needs, setNeeds] = useState(Initial);
  const [errors, setErrors] = useState({} as any);
  const [id, setId] = useState(props.match.params.id);
  const [options, setOptions] = useState([]);
  const [checkedItems, setCheckedItems] = useState<any>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);

  const handleStartDate = (date: Date | null) => {
    setStartDate(moment(date).format('MM/DD/YYYY') as any);
  };
  const [endDate, setEndDate] = useState<Date | null>(null);

  const handleEndDate = (date: Date | null) => {
    setEndDate(moment(date).format('MM/DD/YYYY') as any);
  };

  useEffect(() => {
    Api.Volunteer_opportunity_option()
      .then((response) => {
        setOptions(response.data.data);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
    const getData = async () => {
      if (id !== undefined) {
        const result = await Admin_Api.Volunteer_need_view(id)
          .then((responce: any) => {
            if (responce.data && responce.data.success) {
              const info = responce.data.data;
              setNeeds({ title: info.title, description: info.description });
              setStartDate(info.start_date);
              setEndDate(info.end_date);
              const result = {} as any;
              info.option_id?.forEach((key: any) => (result[key] = true));
              setCheckedItems(result);
            }
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              if (error.response.data.message) {
                showSnackBar(error.response.data.message, 'error');
              } else if (error.response.data.error) {
                showSnackBar(error.response.data.error, 'error');
              } else if (error.response.data.errors) {
                showSnackBar(error.response.data.errors.join('\n'), 'error');
              }
            } else {
              showSnackBar(error.message, 'error');
            }
          });
      }
    };
    getData();
  }, []);

  //state chage
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setNeeds({
      ...needs,
      [name]: value,
    });
    validate({ [name]: value }, e);
  };

  const handleCheck = (event: any) => {
    
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  //handle submit

  const handleSubmit = async () => {
    const checkedIds = [];
    for (const key in checkedItems) {
      if (checkedItems[key] === true) {
        checkedIds.push(key);
      }
    }
    if (id === undefined) {
      await Admin_Api.Volunteer_need_add(
        title,
        description,
        startDate,
        endDate,
        checkedIds
      )
        .then((response) => {
          showSnackBar(response.data.message);
          setNeeds(Initial);
          setStartDate(null);
          setEndDate(null);
          setCheckedItems([]);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.data.message) {
              showSnackBar(error.response.data.message, 'error');
            } else if (error.response.data.error) {
              showSnackBar(error.response.data.error, 'error');
            } else if (error.response.data.errors) {
              showSnackBar(error.response.data.errors.join('\n'), 'error');
            }
          } else {
            showSnackBar(error.message, 'error');
          }
        });
    } else {
      await Admin_Api.Volunteer_need_edit(
        title,
        description,
        startDate,
        endDate,
        id,
        checkedIds
      )
        .then((response) => {
          showSnackBar(response.data.message);
          history.push('/opportunity_list');
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.data.message) {
              showSnackBar(error.response.data.message, 'error');
            } else if (error.response.data.error) {
              showSnackBar(error.response.data.error, 'error');
            } else if (error.response.data.errors) {
              showSnackBar(error.response.data.errors.join('\n'), 'error');
            }
          } else {
            showSnackBar(error.message, 'error');
          }
        });
    }
  };
  //validate

  const validate: any = (
    fieldValues = {} as any,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const temp: any = { ...errors };
    const { name, type } = event.target;

    temp[name] = fieldValues[name] ? '' : 'This field is required.';

    setErrors({
      ...temp,
    });
  };

  const formIsValid = () => {
    const isValid =
      title &&
      description &&
      startDate &&
      Object.values(errors).every((x) => x === '');

    return isValid;
  };
  const { title, description } = needs;
  return (
    <>
      <Container>
        <Typography component="div" className="centers-admin-panel">
          <Typography component="div" className="centers-admin-header">
            <Typography
              component="div"
              className="centers-admin-header-top-bar"
            >
              <Typography component="h3" className="centers-admin-section-text">
                Volunteer Needs
              </Typography>
            </Typography>
          </Typography>
          <Grid container spacing={3} className="px-3 py-6">
            <Grid item xs={12}>
              <Typography
                component="p"
                className="text-3xl font-bold text-pc-1"
              >
                Below you will find your volunteer opportunities that are
                currently listed on RAINN's website.
              </Typography>
              <List className="centers-admin-section-list py-5">
                <ListItem>
                  <ListItemIcon>
                    <MdKeyboardArrowRight />
                  </ListItemIcon>
                  <ListItemText>
                    To add a new volunteer opportunity, fill out the form at the
                    bottom page. Enter a title, description and start and end
                    date for the opportunity.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <MdKeyboardArrowRight />
                  </ListItemIcon>
                  <ListItemText>
                    You can delete a volunteer opportunity by clicking <Link to="/opportunity_list" className="text-sc-1">
                  here
                </Link> to
                    an opportunity.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <MdKeyboardArrowRight />
                  </ListItemIcon>
                  <ListItemText>
                    You can edit an opportunity by clicking <Link to="/opportunity_list" className="text-sc-1">
                  here
                </Link> to the
                    opportunity you would like to make changes to.
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} className="my-5">
              <Typography component="p" className="centers-admin-section-text">
                Online Hotline Volunteer
              </Typography>
              <br />
              <Typography component="p" className="text-lg text-tc-3">
                The National Sexual Assault Online Hotline volunteers help
                provide support for survivors of sexual assault and their loved
                ones through a simple instant message platform. Volunteers are
                provided with both online and in-person trainings and serve on
                the hotline for at least 10 hrs/month. Learn more and apply
                <br />
                at:{' '}
                <a href="https://www.rainn.org/volunteer" className="text-sc-1">
                  www.rainn.org/volunteer
                </a>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} className="bg-tc-7 p-3">
                <Grid item xs={12}>
                  <Typography
                    component="h3"
                    className="centers-admin-section-text"
                  >
                    Volunteer opportunity
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className="centers-admin-input"
                    label="Title"
                    name="title"
                    value={title}
                    required
                    error={errors.title}
                    {...(errors.title && {
                      error: true,
                      helperText: errors.title,
                    })}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className="centers-admin-input"
                    label="Opportunity description"
                    name="description"
                    value={description}
                    required
                    error={errors.description}
                    {...(errors.description && {
                      error: true,
                      helperText: errors.description,
                    })}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="start-date-picker"
                      label="Start Date:"
                      minDate={new Date()}
                      value={startDate}
                      onChange={handleStartDate}
                      className="centers-admin-date-picker"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item md={6} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      format="MM/dd/yyyy"
                      margin="normal"
                      minDate={new Date()}
                      id="start-date-picker"
                      label="End Date (Leave blank for no date):"
                      value={endDate}
                      onChange={handleEndDate}
                      className="centers-admin-date-picker"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl component="fieldset">
                    {options?.map((item: any) => {
                      return (
                        <>
                          <FormControlLabel
                            className="centers-admin-checkbox"
                            control={
                              <Checkbox
                                color="primary"
                                name={item.id as any}
                                checked={checkedItems[item.id] === true}
                                onChange={handleCheck}
                              />
                            }
                            label={`${item.name}`}
                          />
                          {checkedItems[item.id] === true &&
                            item.sub_option?.map((doc: any) => {
                              return (
                                <Typography component="div" className="ml-7">
                                  <FormControlLabel
                                    className="centers-admin-checkbox"
                                    control={
                                      <Checkbox
                                        color="primary"
                                        name={doc.id as any}
                                        checked={checkedItems[doc.id] === true}
                                        onChange={handleCheck}
                                      />
                                    }
                                    label={`${doc.name}`}
                                  />
                                </Typography>
                              );
                            })}
                        </>
                      );
                    })}
                  </FormControl>
                </Grid>
                <Grid item xs={12} className="mt-3">
                  <Button
                    className="button-primary"
                    disabled={!formIsValid()}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Typography>
      </Container>
    </>
  );
}

export default VolunteerNeeds;
