import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container';
import Api from '../../API/Admin_Api';
import { useForm } from '../../hooks/useForm';
import Layout from './Layout/Layout'
import { useSnackBar } from '../../Context/Snackbar/withSnackbar';
import { useHistory } from 'react-router-dom';

const changePassword: React.FunctionComponent = () => {
    const { showSnackBar } = useSnackBar();
    const history = useHistory()
    const Initial = {
        current_Password: '',
        new_Password: '',
        verify_Password: ''
    };
    const { Values, setValues, handleChange, resetForm } = useForm(Initial)

    const handleChangeFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const { current_Password, new_Password, verify_Password } = Values;

        if (new_Password === verify_Password) {
            await Api.changePassword(current_Password, new_Password)
                .then((responce: any) => {
                    if (responce.data && responce.data.success) {
                        showSnackBar(responce.data.message);
                        history.push('/login')
                        resetForm();
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        if (error.response.data.message) {
                            showSnackBar(error.response.data.message, 'error');
                        } else if (error.response.data.error) {
                            showSnackBar(error.response.data.error, 'error');
                        } else if (error.response.data.errors) {
                            showSnackBar(error.response.data.errors.join("\n"), 'error');
                        }
                    } else {
                        showSnackBar(error.message, 'error');
                    }
                });
        } else {
            showSnackBar('New Password and Verify Password are not same', 'info');
        }

    }
    return (
        <Layout>
            <Container>
                <form onSubmit={handleChangeFormSubmit}>
                    <Typography component="div" className="centers-admin-panel">
                        <Typography component="div" className="centers-admin-header">
                            <Typography component="div" className="centers-admin-header-top-bar">
                                <Typography component="h3" className="centers-admin-section-text">Change Password</Typography>
                            </Typography>
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField type="password"
                                    className="centers-admin-input md:w-1/2 md:pr-3"
                                    label="Current Password"
                                    name="current_Password"
                                    value={Values.current_Password}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField type="password"
                                    className="centers-admin-input md:w-1/2 md:pr-3"
                                    label="New Password"
                                    name="new_Password"
                                    value={Values.new_Password}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField type="password" className="centers-admin-input md:w-1/2 md:pr-3"
                                    label="Verify Password"
                                    name="verify_Password"
                                    value={Values.verify_Password}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit"
                                    disabled={!Values.current_Password || !Values.new_Password || !Values.verify_Password}
                                    className="button-primary">Submit</Button>
                            </Grid>
                        </Grid>
                    </Typography>
                </form>
            </Container>
        </Layout>
    );
}

export default changePassword