import React from 'react';
import Layout from '../../../component/Admin/Layout/Layout';
import DeniedRequest from '../../../component/Admin/declineRequestSearchlist'


const DeniedRequestList: React.FunctionComponent = () => {

    return (
      <Layout>
        <DeniedRequest/>
      </Layout>
  );
}

export default DeniedRequestList;