import React from 'react';
import EmailList from '../../../component/Admin/emailTemplate';
import Layout from '../../../component/Admin/Layout/Layout';



const EmailTemplateList: React.FunctionComponent = () => {

    return (
      <Layout>
        <EmailList/>
      </Layout>
  );
}

export default EmailTemplateList;