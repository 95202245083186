import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Api from '../../API/Admin_Api';
import { CircularProgress } from '@material-ui/core';
import { MdVisibility } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { useSnackBar } from '../../Context/Snackbar/withSnackbar';
import moment from 'moment';
interface Data {
  name: string;
  contact: string;
  population: string;
  services: string;
  Action: any;
  requested_by: string;
  center_address: string;
  opportunity: string;
  created_at: '2021-06-21';
  id: 0;
  title: string;
  email: string;
  phone: string;
  first_name: string;
  last_name: string;
}

type Order = 'asc' | 'desc';
interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: 'requested_by',
    numeric: false,
    disablePadding: false,
    label: 'REQUESTED BY',
  },
  {
    id: 'opportunity',
    numeric: false,
    disablePadding: false,
    label: 'VOlUNTEER OPPORTUNITY',
  },
  {
    id: 'center_address',
    numeric: false,
    disablePadding: true,
    label: 'ADDRESS',
  },
  {
    id: 'created_at',
    numeric: false,
    disablePadding: false,
    label: 'REQUESTED ON',
  },
  { id: 'Action', numeric: false, disablePadding: false, label: 'ACTION' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
);

export default function EnhancedTable(props: any) {
  const { zip, state, requestedOn, volunteerOpportunityId } = props;
  const { showSnackBar } = useSnackBar();
  const history = useHistory();
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('created_at');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(true);
  const [emptyData, setemptyData] = React.useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const getdata = async () => {
      await Api.Volunteer_Request_list(
        page + 1,
        rowsPerPage,
        order,
        orderBy,
        zip,
        state,
        requestedOn,
        volunteerOpportunityId
      )
        .then((response: any) => {
          if (response.data && response.data.success) {
            setLoading(false);
            setemptyData(false);
            if (!response.data.data.records.length) {
              setemptyData(true);
            } else {
              setRows(response.data.data.records);
              setCount(response.data.data.records_total);
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.data.message) {
              showSnackBar(error.response.data.message, 'error');
            } else if (error.response.data.error) {
              showSnackBar(error.response.data.error, 'error');
            } else if (error.response.data.errors) {
              showSnackBar(error.response.data.errors.join('\n'), 'error');
            }
          } else {
            showSnackBar(error.message, 'error');
          }
        });
    };

    getdata();
  }, [
    page,
    rowsPerPage,
    order,
    orderBy,
    zip,
    state,
    requestedOn,
    volunteerOpportunityId,
  ]);
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleView = (id: number) => {
    history.push(`/volunteer_request/view/${id}`);
  };

  return (
    <>
      {emptyData ? (
        <Typography component="div" className="text-center pt-10 pb-10">
          No Data Found
        </Typography>
      ) : (
        <Typography component="div" className="centers-admin-table">
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              {loading ? (
                <CircularProgress disableShrink />
              ) : (
                <TableBody>
                  {rows?.map((row: any, index: any) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell>
                          <Typography>{row.requested_by}</Typography>
                        </TableCell>

                        <TableCell>
                          <Typography>{row.opportunity}</Typography>
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.center_address}
                        </TableCell>
                        <TableCell>{row?.created_at}</TableCell>
                        <TableCell>
                          <span className="flex items-center">
                            <MdVisibility
                              className="centers-admin-table-icon"
                              onClick={() => handleView(row.id)}
                            />
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Typography>
      )}
    </>
  );
}
