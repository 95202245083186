import React, { useContext, useState } from 'react'
export type defaultVal={
  languageInfo:string,
  handleLanguageInfo:(lang :string | null) => void
}
export const DefaultValue:any = {
  languageInfo:"en",
 handleLanguageInfo:() => null
}
 const LanguageContext = React.createContext<defaultVal | undefined>({} as defaultVal)

export const LanguageProvider = (props: { children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined }) =>{
   const [languageInfo,setLanguageInfo] = useState('en')
   const handleLanguageInfo = (lang:any) =>{
     setLanguageInfo(lang)
   }
   return (
     <LanguageContext.Provider value={{
       languageInfo,handleLanguageInfo
     }}>
       {props.children}
     </LanguageContext.Provider>
   )
}

export const useLanguageHandler = (): defaultVal => {
  const context = useContext(LanguageContext);

  if (!context) {
      throw new Error('useLanguagehandler must be used within an LanguageProvider');
  }

  return context;
};
