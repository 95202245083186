import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Link, useHistory } from 'react-router-dom';
import Layout from './Layout/Layout'
import { MdArrowBack, MdInfoOutline } from 'react-icons/md';
import {
  EditorState,
  ContentState,
  convertToRaw,
} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useEffect, useState } from 'react';
import Admin_Api from '../../API/Admin_Api';
import { useSnackBar } from '../../Context/Snackbar/withSnackbar';
import draftToHtml from 'draftjs-to-html';

function emailTemplateEdit(props: { match: { params: { id: any } } }) {
  const { showSnackBar } = useSnackBar();

  useEffect(() => {
    getdata();
  }, []);

  const [id, setId] = useState(props.match.params.id);
  const history = useHistory();
  const [subject, setSubject] = useState('');
  const [editorState, setEditorstate] = useState<any>(
    EditorState.createEmpty()
  );

  let editorStateInitial: any;

  const getdata = async () => {
    await Admin_Api.ViewEmailTemplatelist(id)
      .then((responce: any) => {
        if (responce.data && responce.data.success) {
          showSnackBar(responce.data.message);
          setSubject(responce.data.data.subject);
          const html = `${responce.data.data.content}`;
          const contentBlock = htmlToDraft(html);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
              contentBlock.contentBlocks
            );
            editorStateInitial = EditorState.createWithContent(contentState as any);
            setEditorstate(editorStateInitial);
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join("\n"), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  };
  const onEditorStateChange = (editorState: any) => {
    setEditorstate(editorState);
  };
  const handleSubmit = () => {
    const description = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    Admin_Api.EditEmailTemplatelist(id, subject, description as any)
      .then((responce: any) => {
        if (responce.data && responce.data.success) {
          showSnackBar(responce.data.message);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join("\n"), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
    history.push('/email_templates');
  };

  const handleClear = () =>{
   setEditorstate(EditorState.createEmpty())
   setSubject('')
  }
  const getFileBase64 = (file: any, callback: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
    reader.onerror = error => {
      showSnackBar('Something went wrong!', 'error');
    };
  };

  const _uploadImageCallBack = (file: any) => new Promise(
    (resolve, reject) => getFileBase64(
      file,
      (data: any) => resolve({ data: { link: data } })
    )
  );

  return (
    <>
      <Layout>
        <Container>
          <Typography component="div" className="centers-admin-panel">
            <Typography component="div" className="centers-admin-header">
              <Typography
                component="div"
                className="centers-admin-header-top-bar"
              >
                  <Typography component="h3" className="centers-admin-section-text flex items-center">
                <Link to="/email_templates"><MdArrowBack className="mr-1 text-pc-1" /></Link> 
                Edit System Email Template
                </Typography>
              </Typography>
            </Typography>
            <Typography component="div" className="m-5">
              <Typography component="div" className="mb-3">
                <label className=" block text-tc-5" htmlFor="ZIPCode">
                  Subject
                </label>
                <TextField
                  id="email"
                  className="centers-banner-input w-full"
                  placeholder="Enter Email"
                  name="subject"
                  value={subject}
                  onChange={(e: any) => setSubject(e.target.value)}
                />
              </Typography>
              <Typography component="div" className="centers-admin-label-yellow">
                <Typography
                  component="p"
                  className="flex items-center font-notosans"
                >
                  <MdInfoOutline className="mr-1 text-base" />
                  Updated request will be sent to this email address by default.
                  You can change the To adress for this email.
                </Typography>
              </Typography>
              <div className="centers-admin-wysiwyg-wrapper">
                <Editor
                  editorState={editorState}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={onEditorStateChange}
                  toolbarClassName="toolbar-class"
                  toolbar={{
                    image: {
                      uploadCallback: _uploadImageCallBack,
                      inputAccept: 'image/*',
                      previewImage: true, alt: { present: true, mandatory: false }
                    }
                  }}
                />
              </div>
              <Typography
                component="div"
                className="centers-admin-tab-panel-buttons my-5"
              >
                <Button className="button-primary mr-3" onClick={handleSubmit}>
                  Submit
                </Button>
                <Button className="button-primary mr-3" onClick={handleClear}>
                  Clear
                  </Button>
              </Typography>
            </Typography>
          </Typography>
        </Container>
      </Layout>
    </>
  );
}

export default emailTemplateEdit;
