import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import ListItem from '@material-ui/core/ListItem';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { MdInfoOutline } from 'react-icons/md';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import {
  MdLocationOn,
  MdPhone,
  MdPhoneInTalk,
  MdBusiness,
  MdPublic,
  MdGTranslate,
} from 'react-icons/md';
import fooAvatarImg from '../../assets/images/centerstone.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import CenterMap from '../CenterMap';
import api, { instance } from '../../API/Api';
import { useSnackBar } from '../../Context/Snackbar/withSnackbar';
import { MenuItem } from '@material-ui/core';
import { useLanguageHandler } from '../../Context/Admin/Language';
import Api from '../../API/Api';
import ReCAPTCHA from 'react-google-recaptcha';
import loadjs from 'loadjs';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

declare global {
  interface Window {
    grecaptcha: any;
  }
}
const VolunteerCenterData: React.FunctionComponent = (props: any) => {
  const { showSnackBar } = useSnackBar();
  const { languageInfo, handleLanguageInfo } = useLanguageHandler();
  const initial: any = {
    first_name: '',
    last_name: '',
    email: '',
    source_of_info: '',
  };
  const recaptchaRef = useRef<any>(null);

  const history = useHistory();
  const { id } = props.match.params;
  const [step2, setStep2] = useState(initial);
  const [formdata, setFormData] = useState<any>({});
  const [load, setLoad] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [errors, setErrors] = useState({} as any);
  const [subscribe_mail, setChecked] = useState(false);
  const [hearOpportunity, setHearOpportunity] = useState<any>([]);
  const [checkedItems, setCheckedItems] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [Thank, setThank] = useState(false);
  const [token, setToken] = useState('' as string | null);
  const [imageUrl, setImageUrl] = useState<any>();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const changeLanguage = () => {
      i18n.changeLanguage(languageInfo);
    };
    changeLanguage();
  }, [languageInfo]);
  useEffect(() => {
    Api.Volunteer_form_option()
      .then((item) => {
        setHearOpportunity(item.data.data);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });

    const getdata = async () => {
      await Api.Volunteer_opportunity_by_id(id)
        .then((responce: any) => {
          if (responce.data && responce.data.success) {
            const byteCharacters = atob(responce.data.data?.logo);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            const image = new Blob([byteArray], { type: 'image/jpeg' });
            setImageUrl(URL.createObjectURL(image));

            setFormData(responce.data.data);
            responce.data.data.opportunity.map((item: any) => {
              if (item.title === 'General Registration') {
                const checkedIds = [item].reduce(
                  (acc: any, cur: any) => ({ ...acc, [cur.id]: true }),
                  {}
                );
                setCheckedItems(checkedIds);
              }
            });
            setLoading(false);
            setTimeout(() => {
              setLoad(true);
            }, 4000);
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.data.message) {
              showSnackBar(error.response.data.message, 'error');
            } else if (error.response.data.error) {
              showSnackBar(error.response.data.error, 'error');
            } else if (error.response.data.errors) {
              showSnackBar(error.response.data.errors.join('\n'), 'error');
            }
          } else {
            showSnackBar(error.message, 'error');
          }
        });
    };

    if (loading === true) {
      getdata();
    }
  }, [id]);

  const checkedIds: any[] = [];

  const handleSubmit = async () => {
    const tokenVal = await recaptchaRef.current?.executeAsync();
    setToken(tokenVal as any);
    for (const key in checkedItems) {
      if (checkedItems[key] === true) {
        checkedIds.push(key);
      }
    }
    await api
      .Volunteer_search_save(id, {
        ...step2,
        opportunity_id: checkedIds,
        recaptcha_token: tokenVal,
        subscribe_mail: subscribe_mail,
      })
      .then((responce: any) => {
        if (responce.data && responce.data.success) {
          setThank(true);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  };

  const handleChangeVolunteerCenter = (e: any) => {
    const { name, value } = e.target;
    setStep2({
      ...step2,
      [name]: value,
    });
    validate({ [name]: value }, e);
  };

  const handleCheck = (event: any) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };
  const validate: any = (
    fieldValues = {} as any,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const temp: any = { ...errors };
    const { name, type } = event.target;

    temp[name] = fieldValues[name] ? '' : 'This field is required.';

    if (type === 'email') {
      temp[name] = fieldValues[name] ? '' : 'This field is required.';
      if (fieldValues[name])
        temp[name] = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues[name])
          ? ''
          : 'Email is not valid.';
    }

    setErrors({
      ...temp,
    });
  };

  const formIsValid = () => {
    const checkedIds = [];
    for (const key in checkedItems) {
      if (checkedItems[key] === true) {
        checkedIds.push(key);
      }
    }
    const isValid =
      first_name &&
      last_name &&
      email &&
      source_of_info &&
      checkedIds &&
      checkedIds.length > 0 &&
      Object.values(errors).every((x) => x === '');

    return isValid;
  };

  const { first_name, last_name, email, source_of_info } = step2;

  return (
    <div>
      <Typography component="div" className="bg-pc-1 pt-6 pb-16 px-12">
        <Container maxWidth={false}>
          <Grid container direction="row" justify="space-between">
            <Grid item>
              <Link to="/volunteer_search" className="text-tc-7 text-sm">
                Search Again
              </Link>
            </Grid>
            <Grid item>
              <Typography className="text-tc-7 text-sm">
                {languageInfo !== 'en' ? (
                  <button onClick={() => handleLanguageInfo('en')}>
                    <MdGTranslate className="inline-block mr-1 text-sm" />
                    English
                  </button>
                ) : (
                  <button onClick={() => handleLanguageInfo('es')}>
                    <MdGTranslate className="inline-block mr-1 text-sm" />
                    En Español
                  </button>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Typography
            component="h1"
            className="text-tc-7 text-4xl text-center pt-6"
          >
            RAINN - Partner Local Crisis Center
          </Typography>
        </Container>
      </Typography>
      {loading ? (
        <Typography component="div" className="centers-loader">
          <CircularProgress disableShrink />
        </Typography>
      ) : (
        <Typography component="div">
          <Container maxWidth={false}>
            <Grid
              container
              direction="row"
              className="centers-detail-page"
              spacing={3}
              justify="space-between"
              alignItems="stretch"
            >
              <Grid item sm={8}>
                <Typography component="h2">{formdata.name}</Typography>
                <Typography component="div" className="mb-8">
                  <Grid container direction="row" alignItems="flex-start">
                    <Grid item>
                      <Card className="centers-logo">
                        <CardMedia
                          className="centers-logo-img"
                          image={imageUrl}
                        >
                          {formdata.logo === null
                            ? `${formdata.name.charAt(0)}`
                            : null}
                        </CardMedia>
                      </Card>
                    </Grid>

                    <Grid item className="flex-1">
                      <Table className="centers-detail-list">
                        <TableRow>
                          <TableCell>
                            <Typography
                              component="span"
                              className="centers-tag"
                            >
                              {formdata.center_Type_Id === 1
                                ? 'National'
                                : 'Local'}
                            </Typography>
                          </TableCell>
                          <TableCell colSpan={3}>
                            <Typography component="div" className="flex">
                              {formdata.website !== null && (
                                <>
                                  <MdPublic className="mr-2 text-tc-5 text-lg" />
                                  <Typography component="span">
                                    <a href={`${formdata.website}`}>
                                      {t('detail.center-website')}
                                    </a>
                                  </Typography>
                                </>
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography component="div" className="flex">
                              <MdBusiness className="mr-2 text-tc-5 text-lg" />
                              <Typography component="span">
                                <strong>{formdata.business_phone}</strong>
                                {t('detail.business')}
                              </Typography>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography component="div" className="flex">
                              <MdPhoneInTalk className="mr-2 text-tc-5 text-lg" />
                              <Typography component="span">
                                <strong>{formdata.hotline_phone}</strong>{' '}
                                {t('detail.hotline')}
                              </Typography>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography component="div" className="flex">
                              <MdPhone className="mr-2 text-tc-5 text-lg" />
                              <Typography component="span">
                                <strong>{formdata.tty}</strong> Tty
                              </Typography>
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={1}>
                            <Typography component="div" className="flex">
                              <MdLocationOn className="mr-2 text-tc-5 text-lg" />
                              <Typography component="span">
                                {formdata.city !== null &&
                                formdata.city.length > 0 ? (
                                  <>{formdata.city},</>
                                ) : (
                                  ''
                                )}
                                &nbsp;
                                {/* {formdata.county !== null &&
                                formdata.county.length > 0 ? (
                                  <>{formdata.county},</>
                                ) : (
                                  ''
                                )}
                                &nbsp; */}
                                {formdata.state_code !== null &&
                                formdata.state_code.length > 0 ? (
                                  <>{formdata.state_code},</>
                                ) : (
                                  ''
                                )}
                                &nbsp;{formdata.zip}
                                {/*                                
                                {formdata.county},&nbsp;{formdata.state_code}
                                &nbsp;{formdata.zip} */}
                              </Typography>
                            </Typography>
                          </TableCell>
                          <TableCell colSpan={2}>
                            <Typography component="div" className="flex">
                              <Typography component="span">
                                {formdata.county !== null &&
                                formdata.county.length > 0 ? (
                                  <>
                                    <b>{t('detail.counties-served')}:</b>{' '}
                                    {formdata.county}
                                  </>
                                ) : (
                                  ''
                                )}
                              </Typography>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </Table>
                    </Grid>
                  </Grid>
                </Typography>
                {Thank && (
                  <Typography
                    component="div"
                    className="text-center w-11/12 m-auto"
                  >
                    <Typography
                      component="h4"
                      className="text-pc-1 font-bold text-3xl mb-5"
                    >
                      {`Thank you for your interest in volunteering with ${formdata.name}`}
                    </Typography>
                    <Typography
                      component="h5"
                      className="text-pc-1 text-lg mb-5"
                    >
                      {`We have sent your contact information to ${formdata.name}, and
                    they should contact you shortly.`}
                    </Typography>
                    <Typography component="h5" className="text-pc-1 text-lg">
                      You can reach your local crisis center at any time by
                      calling the National Sexual Assault Hotline at
                      1.800.6565.HOPE (4673).
                    </Typography>
                  </Typography>
                )}

                {!Thank && (
                  <>
                    <Typography component="div" className="content">
                      <Typography component="div" className="mb-4">
                        <Typography
                          component="h4"
                          className="font-bold text-pc-1"
                        >
                          STEP 1
                        </Typography>
                        <Typography component="p">
                          Check the opportunities you are interested in.
                        </Typography>
                      </Typography>

                      <Typography component="div" className="mb-4">
                        {formdata.opportunity?.map((item: any) => {
                          return (
                            <Typography
                              component="div"
                              className="flex"
                              key={item.id}
                            >
                              <FormControl component="fieldset">
                                <FormControlLabel
                                  className="centers-admin-checkbox mr-0"
                                  control={
                                    <Checkbox
                                      color="primary"
                                      name={item.id as any}
                                      checked={
                                        formdata.opportunity.length > 1
                                          ? checkedItems[item.id] === true
                                          : true
                                      }
                                      onChange={handleCheck}
                                    />
                                  }
                                  label=""
                                />
                              </FormControl>
                              <Typography
                                component="div"
                                className="m-2 flex-1"
                              >
                                <Typography
                                  component="h5"
                                  className="text-pc-1 font-bold"
                                >
                                  {item.title}
                                </Typography>
                                <Typography
                                  component="p"
                                  className="font-notosans"
                                >
                                  {item.description}
                                </Typography>
                                {item.option?.map((op: any, index: any) => (
                                  <Typography
                                    component="span"
                                    key={index}
                                    className="text-tc-5 text-sm font-notosans block"
                                  >
                                    <MdInfoOutline className="mr-1 inline" />
                                    {op}
                                  </Typography>
                                ))}
                              </Typography>
                            </Typography>
                          );
                        })}
                      </Typography>

                      <Typography component="div" className="mb-4">
                        <Typography
                          component="h4"
                          className="font-bold text-pc-1"
                        >
                          STEP 2
                        </Typography>
                        <Typography component="p">
                          Fill out the form below and submit your information.
                        </Typography>
                      </Typography>

                      <Typography component="div">
                        <Grid container spacing={2}>
                          <Grid item md={5} xs={12}>
                            <TextField
                              className="centers-admin-input"
                              id="firstname"
                              label="First Name"
                              name="first_name"
                              value={first_name}
                              required={first_name === ''}
                              error={errors.first_name}
                              {...(errors.first_name && {
                                error: true,
                                helperText: errors.first_name,
                              })}
                              onChange={handleChangeVolunteerCenter}
                            />
                          </Grid>
                          <Grid item md={7} xs={12}>
                            <TextField
                              className="centers-admin-input"
                              id="lastname"
                              label="Last Name"
                              name="last_name"
                              value={last_name}
                              required={last_name === ''}
                              error={errors.last_name}
                              {...(errors.last_name && {
                                error: true,
                                helperText: errors.last_name,
                              })}
                              onChange={handleChangeVolunteerCenter}
                            />
                          </Grid>
                          <Grid item md={5} xs={12}>
                            <TextField
                              className="centers-admin-input"
                              id="email"
                              label="Email"
                              type="email"
                              name="email"
                              value={email}
                              required={email === ''}
                              error={errors.email}
                              {...(errors.email && {
                                error: true,
                                helperText: errors.email,
                              })}
                              onChange={handleChangeVolunteerCenter}
                            />
                          </Grid>
                          <Grid item md={7} xs={12}>
                            <TextField
                              select
                              id="volopportunities"
                              label="How did you hear about volunteer opportunities at RAINN?"
                              className="centers-admin-autocomplete"
                              name="source_of_info"
                              value={source_of_info}
                              required={source_of_info === ''}
                              error={errors.source_of_info}
                              {...(errors.source_of_info && {
                                error: true,
                                helperText: errors.source_of_info,
                              })}
                              onChange={handleChangeVolunteerCenter}
                            >
                              {hearOpportunity.map((res: any) => {
                                return (
                                  <MenuItem value={res.id} key={res.id}>
                                    {res.name}
                                  </MenuItem>
                                );
                              })}
                            </TextField>
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <FormControl component="fieldset">
                              <FormControlLabel
                                className="centers-admin-checkbox text-tc-5"
                                control={
                                  <Checkbox
                                    name="subscribe_mail"
                                    color="primary"
                                    checked={subscribe_mail}
                                    onChange={(e) => {
                                      setChecked(e.target.checked);
                                    }}
                                  />
                                }
                                label="I would like to join RAINN's mailing list."
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={12} xs={12}>
                            {load && (
                              <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={
                                  process.env.React_App_Site_Key as string
                                }
                                size="invisible"
                                badge="bottomright"
                              />
                            )}
                            <Button
                              className="button-primary mt-3"
                              onClick={handleSubmit}
                              disabled={!formIsValid()}
                            >
                              Submit
                            </Button>
                          </Grid>
                        </Grid>
                      </Typography>
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item sm={4}>
                <Typography component="div" className="content-map">
                  <CenterMap
                    longitude={formdata.longitude}
                    latitude={formdata.latitude}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Typography>
      )}
    </div>
  );
};

export default VolunteerCenterData;
