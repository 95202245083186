import React, { useEffect, useState } from 'react';
import AdminNav from '../../../common/adminMenuItems.json';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import { MdArrowDropDown } from 'react-icons/md';

const AdminNavigation: React.FunctionComponent = (props: any) => {
  const [activeSubMenuId, setActiveSubMenuId] = useState('');
  const user = JSON.parse(localStorage.getItem('token') as any);
  const menuItemsData = AdminNav.data;
  const showMenu = (menuId: any, event: any) => {
    setActiveSubMenuId(menuId);

    event.target
      .closest('ul')
      ?.childNodes.forEach((li: any) =>
        li.classList.remove('menu-link-item-active')
      );

    const menuBottom = document.getElementById(menuId);
    if (menuBottom) {
      const menuBottomLi = menuBottom.closest('li');
      menuBottomLi?.classList.add('menu-link-item-active');
    }
  };

  const hideMenu = () => {
    setActiveSubMenuId('');
    const removeMenu = document.getElementById('primaryNav1');
    const removeArr = removeMenu?.childNodes;
    removeArr?.forEach((li: any) =>
      li.classList.remove('menu-link-item-active')
    );
  };
  let permission = false;

  const role = user.role.split(',');
  return (
    <List className="centers-nav-list" id="primaryNav1" onMouseLeave={hideMenu}>
      {menuItemsData.map((item: any, i: any) => {
        if (item.roles && item.roles.length > 0) {
          permission = item.roles.includes(user.role);
        }
        return (
          <>
            {item.isAdmin &&
              user?.role
                .split(',')
                .some((val: any) => item.roles.includes(val)) && (
                <ListItem
                  className={`${item.cls}`}
                  key={'nav-parent-listitem' + i}
                >
                  <ListItemText key={'nav-parent-ListItemText' + i}>
                    <Link
                      to={item.url == '' ? '/' : `${item.url}`}
                      onClick={
                        item.url == ''
                          ? (event) => event.preventDefault()
                          : undefined
                      }
                      key={'nav-parent-menu-link' + i}
                      onMouseOver={(event: any) => {
                        showMenu('nav1' + (i + 1), event);
                      }}
                    >
                      {item.name}
                      {item.children && (
                        <MdArrowDropDown className="arrow-icon" />
                      )}
                    </Link>
                    {item.children && (
                      <div
                        className="centers-sub-nav"
                        id={'nav1' + (i + 1)}
                        key={'nav-parent-div' + i}
                      >
                        {item.children.map((headerLinks: any, j: any) => {
                          return activeSubMenuId == 'nav1' + (i + 1) ? (
                            <Link
                              to={
                                headerLinks.url == ''
                                  ? '/'
                                  : `${headerLinks.url}`
                              }
                              onClick={
                                headerLinks.url == ''
                                  ? (event) => event.preventDefault()
                                  : undefined
                              }
                              key={'nav-child-menu' + i + j}
                            >
                              {headerLinks.name}
                            </Link>
                          ) : null;
                        })}
                      </div>
                    )}
                  </ListItemText>
                </ListItem>
              )}
            {!item.isAdmin &&
              user?.role
                .split(',')
                .some((val: any) => item.roles.includes(val)) && (
                <ListItem
                  className={`${item.cls}`}
                  key={'nav-parent-menu-listitem' + i}
                >
                  <ListItemText key={'nav-parent-ListItemText' + i}>
                    <Link
                      to={item.url == '' ? '/' : `${item.url}`}
                      onClick={
                        item.url == ''
                          ? (event) => event.preventDefault()
                          : undefined
                      }
                      key={'nav-parent-menu-link' + i}
                      onMouseOver={(event: any) => {
                        showMenu('nav1' + (i + 1), event);
                      }}
                    >
                      {item.name}
                      {item.children && (
                        <MdArrowDropDown className="arrow-icon" />
                      )}
                    </Link>

                    {item.children && (
                      <div
                        className="centers-sub-nav"
                        id={'nav1' + (i + 1)}
                        key={'nav-parent-menu-div' + i}
                      >
                        {item.children.map((headerLinks: any, j: any) => {
                          return activeSubMenuId == 'nav1' + (i + 1) ? (
                            <Link
                              to={
                                headerLinks.url == ''
                                  ? '/'
                                  : `${headerLinks.url}`
                              }
                              onClick={
                                headerLinks.url == ''
                                  ? (event) => event.preventDefault()
                                  : undefined
                              }
                              key={'nav-child-menu-link' + i + j}
                            >
                              {headerLinks.name}
                            </Link>
                          ) : null;
                        })}
                      </div>
                    )}
                  </ListItemText>
                </ListItem>
              )}
          </>
        );
      })}
    </List>
  );
};

export default AdminNavigation;
