import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';

import { Link, useHistory } from 'react-router-dom';

import fooAvatarImg from '../../assets/images/centerstone.png';
import { MdSearch } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

const VolunteerCentersCard: React.FunctionComponent = (props: any) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { val } = props;
  const [search, setSearch] = useState('');
  const items = val
    .filter((data: any) => {
      if (search === null || search === '') {
        return data;
      } else if (
        data.name &&
        data.name.toLowerCase().includes(search.toLowerCase())
      ) {
        if (data.name.length) return data;
        else return <h1>Data not found</h1>;
      }
    })
    .map((data: any) => {
      const byteCharacters = atob(data?.logo);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      
      const image = new Blob([byteArray], { type: 'image/jpeg' });
      const imageUrl = URL.createObjectURL(image);
      return (
        <Grid item md={4} xs={12} className="" key={data.id}>
          <Card className="centers-data-card">
            <CardContent className="flex p-6">
            <Avatar className="avatar" src={imageUrl}>
              {data?.name.charAt(0)}
              </Avatar>
              <Typography component="div" className="flex-1">
                <Typography component="h6" className="centers-name">
                  {data.name.length > 40
                    ? data.name.substring(0, 40) + '...'
                    : data.name}

                  <Avatar variant="rounded" className="avatar-badge">
                    {data.center_Type_Id === 1 ? 'National' : 'Local'}
                  </Avatar>
                </Typography>
                <Typography component="p">
                  {data.city},{data.stateCode}
                  {data.zip}
                </Typography>
                <Typography component="p">
                  <a href={`${data.website}`}>
                    {data.website?.length > 30
                      ? data.website.substring(0, 35) + '...'
                      : data.website}
                  </a>
                </Typography>
                <Typography component="p">
                  <span className="font-bold">{data.hotline_phone} </span>
                  (Hotline)
                </Typography>
                <Typography component="div" className="flex items-center my-2">
                  <span className="font-bold text-tc-3 text-sm">
                    Volunteer Opportunity
                  </span>
                  {data.has_opportunity ? (
                    <Avatar
                      variant="rounded"
                      className="avatar-badge-yes cursor-pointer"
                      onClick={() => {
                        history.push(`/volunteer_detail/${data.id}`);
                      }}
                    >
                      Yes
                    </Avatar>
                  ) : (
                    <Avatar variant="rounded" className="avatar-badge-no">
                      No
                    </Avatar>
                  )}
                </Typography>
                  <Button
                    className="button-secondary button-small"
                    onClick={() => {
                      history.push(`/volunteer_detail/${data.id}`);
                    }}
                  >
                    Register Now
                  </Button>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      );
    });

  return (
    <Typography component="div" className="centers-content bg-tc-7 py-12">
      <Container>
        <Typography
            component="h5"
            className="text-2xl font-extrabold text-pc-1 text-center"
          >
            {val.length==1 || val.length==0 ? `${val.length} ${t('search.center')}` :`${val.length} ${t('search.centers')}`}
        </Typography>
        <Box className="flex justify-between items-center mb-5">
          <TextField
            className="centers-search-input"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={`${t('search.search')}`}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MdSearch className="text-tc-5 text-2xl" />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Grid container direction="row" className="" spacing={3}>
          {items}
        </Grid>
      </Container>
    </Typography>
  );
};

export default VolunteerCentersCard;
