import React from 'react';
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import assaultServiceIcon from '../assets/images/sexual-assault-service-icon.svg';
import localhelpIcon from '../assets/images/local-help-icon.svg';
import { useTranslation } from 'react-i18next';
const StaticHomeContent:React.FunctionComponent = (props:any) => {
   const {t} = useTranslation();
    return (
        <React.Fragment>
        <Typography component="div" className="centers-content dot-bg">
        <Container>
        <Grid
             container
             direction="row"
             justify="space-between"
             alignItems="flex-start"
             spacing={0}
             >
             <Grid item md={12} className="text-center py-16" >
                <Typography component="h2">
                {t('tool.title')}
                </Typography>
                <Typography component="p" className="text-tc-3 text-xl">
                  {t('tool.text')}
                </Typography>
             </Grid>
             <Grid
             container
             spacing={0}
             className="pb-12"
             >
             <Grid item xs={12} md container>
                <div className="">
                   <img src={assaultServiceIcon} className="mb-6" alt="service provider" />
                   <Typography component="h3">
                     {t('sexual_assault.title')}
                   </Typography>
                   <Typography paragraph={true} className="">
                     {t('sexual_assault.text')}
                      <List dense>
                         <ListItem>{t('sexual_assault.list_1')}</ListItem>
                         <ListItem>{t('sexual_assault.list_2')}</ListItem>
                         <ListItem>{t('sexual_assault.list_3')}</ListItem>
                         <ListItem>{t('sexual_assault.list_4')}</ListItem>
                         <ListItem>{t('sexual_assault.list_5')}</ListItem>
                         <ListItem>{t('sexual_assault.list_6')}</ListItem>
                         <ListItem>{t('sexual_assault.list_7')}</ListItem>
                         <ListItem>{t('sexual_assault.list_8')}</ListItem>
                         <ListItem>{t('sexual_assault.list_9')}</ListItem>
                         <ListItem>{t('sexual_assault.list_10')}</ListItem>
                      </List>
                   </Typography>
                </div>
             </Grid>
             <Grid item>
                <Divider orientation="vertical" className="mx-12"/>
             </Grid>
             <Grid item xs={12} md container>
                <div className="">
                   <img src={localhelpIcon} className="mb-6" alt="local help"/>
                   <Typography component="h3">
                     {t('local_help.title')}
                   </Typography>
                   <Typography paragraph={true} className="">
                     {t('local_help.text')}
                   </Typography>
                   <Typography component="h4" className="mt-8">
                     {t('local_help.title_2')}
                   </Typography>
                   <Typography paragraph={true} className="">
                      <List dense>
                         <ListItem>- <a href="https://rainn.org/get-help/sexual-assault-and-rape-international-resources">{t('local_help.list_1')}</a></ListItem>
                         <ListItem>- <a href="https://rainn.org/get-help/local-counseling-centers/state-sexual-assault-resources">{t('local_help.list_2')}</a></ListItem>
                         <ListItem>- <a href="https://rainn.org/get-information/links">{t('local_help.list_3')}</a></ListItem>
                      </List>
                   </Typography>
              {props.match.path === '/volunteer_search' ? (
                 <>
                   <Typography component="h4" className="mt-8">
                      {/* Frequently asked question (FAQ) */}
                      {t('local_help.FAQ')}
                   </Typography>
                                         <Typography paragraph={true} className="">
                               <a href={'/VolOppsFAQ.pdf'} target="_blank">{t('local_help.here')}</a> {t('local_help.FAQ_line')}
                               </Typography>
</>                    
              ): null
            }
                </div>
             </Grid>
          </Grid>
          </Grid>
          </Container>

          </Typography>
                        
        </React.Fragment>
    )
}

export default StaticHomeContent
