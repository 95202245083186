import React, { useState } from 'react';
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { useForm } from '../../hooks/useForm';
import API from '../../API/Api'
import Layout from '../Layout/Layout'
import { useSnackBar } from '../../Context/Snackbar/withSnackbar';
import { useHistory } from 'react-router-dom';

const ResetPassword: React.FunctionComponent = (props: any) => {
  const { showSnackBar } = useSnackBar();
const history= useHistory();
  const token = new URLSearchParams(location.search)
  const accessToken = token.get('token')

  const Initial = {
    confirmPassword: '',
    password: ''
  }
  const { Values,
    setValues,
    handleChange,
    resetForm } = useForm(Initial)

  const handleReset = async (e:any) => {
    e.preventDefault();


    const { confirmPassword, password } = Values
    if (confirmPassword === password && confirmPassword !=='' && password !=='') {
      await API.ResetPassword(confirmPassword, password, accessToken as string | null)
        .then((responce: any) => {
          if (responce.data && responce.data.success) {
            showSnackBar(responce.data.message);
            history.push('/login')
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.data.message) {
              showSnackBar(error.response.data.message, 'error');
            } else if (error.response.data.error) {
              showSnackBar(error.response.data.error, 'error');
            } else if (error.response.data.errors) {
              showSnackBar(error.response.data.errors.join("\n"), 'error');
            }
          } else {
            showSnackBar(error.message, 'error');
          }
        });
    }
    else {
      showSnackBar('Check your Password and confirm Password', 'info')
    }
  }

  return (
    <Layout>
      <form onSubmit={handleReset}>
        <Typography component="div" className="centers-login-container mb-48 mt-20">
          <Typography component="h1" className="centers-login-header ">Account Login</Typography>
          <Typography component="h4" className="centers-login-text">We are glad to see you again!</Typography>
          <TextField
            className="centers-login-input rounded-b-none"
            placeholder="Password"
            type="password"
            name="password"
            value={Values.password}
            onChange={handleChange}
          />
          <TextField
            className="centers-login-input rounded-t-none border-t-0"
            placeholder="Confirm Password"
            type="password"
            name="confirmPassword"
            value={Values.confirmPassword}
            onChange={handleChange}
          />
          <Typography component="div" className="my-4">
            <Button className="button-primary" type="submit">Reset Password</Button>
          </Typography>
        </Typography>

      </form>
    </Layout>
  );
}

export default ResetPassword;