import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import { useHistory } from 'react-router-dom';

function StateMap(props) {
  const history = useHistory()
  var options = {
    backgroundColor: {
      fill: 'transparent',
      stroke: 'transparent',
      strokeWidth: 0,
    },
    colorAxis: { minValue: 0, maxValue: 1, colors: ['#ccc', '#ccc'] },
    legend: 'none',
    datalessRegionColor: '#1f83e0',
    displayMode: 'markers',
    enableRegionInteractivity: 'true',
    resolution: (props.centers === 'PR') ? '' : 'provinces',
    sizeAxis: { minValue: 1, maxValue: 1, minSize: 10, maxSize: 10 },
    region: (props.centers === 'PR') ? `${props.centers}` : `US-${props.centers}`,

    keepAspectRatio: true,
    tooltip: {
      textStyle: { color: '#444444' },
      trigger: 'focus',
      isHtml: true,
    },
  };
  let isLatAvaialble = false;
  isLatAvaialble = props.centerData.find((item) => item.latitude != null);

  const data = isLatAvaialble ? [
    [
      'Lat',
      'Lon',
      'Count',
      {
        role: 'tooltip',
        p: { html: true },
      },
    ],
    ...props.centerData.map((result) => [
      result.latitude,
      result.longitude,
      result.name?.toString(),
      result.city?.toString(),
    ]),
  ] :
    [
      ['Country'],
      [props.centers],
    ]

  const chartEvent = [
    {
      eventName: 'select',
      callback: ({ chartWrapper }) => {
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();
        if (selection.length === 1) {
          var selectedRow = selection[0].row + 1;
          var selectedRegion = data[selectedRow];
          const id = props.centerData.filter((item) => {
            if (item.name === selectedRegion[2]) {
              if (window.location.pathname === '/' || window.location.pathname === '/en/' || window.location.pathname === '/es') {
                
                history.push(`/center-detail/${item.id}`)
              }
              else {
                history.push(`/volunteer_detail/${item.id}`)
              }
            }
          })
        }
      }
    },
  ];
  return (
    <div className="centers-map md:m-auto">
      <Chart
        height={'300px'}
        chartType="GeoChart"
        data={data}
        chartEvents={chartEvent}
        options={options}
      />
    </div>
  );
}

export default StateMap;
