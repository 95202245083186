import React from 'react'
import VolunteerCenterInfo from '../component/Volunteer/VolunteerCenterData'
import Layout from '../component/Layout/Layout'

const VolunteerCenterDetail:React.FunctionComponent = (props:any) => {
  return (
    <div>
      <Layout >
      <VolunteerCenterInfo {...props}/>
      </Layout>
    </div>
  )
}

export default VolunteerCenterDetail
