import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from '../../hooks/useForm'
import Button from '@material-ui/core/Button'
import API from '../../API/Api'
import Layout from '../Layout/Layout'
import { useSnackBar } from '../../Context/Snackbar/withSnackbar'

const ApplicationForm: React.FunctionComponent = () => {
  const { showSnackBar } = useSnackBar();

  const Initial = {
    appNo: ''
  }
  const history = useHistory();
  const { Values,
    setValues,
    handleChange,
    resetForm } = useForm(Initial);

  const handleAppNo = async (e: any) => {
    e.preventDefault();
    const { appNo } = Values
    await API.Application_number(appNo)
      .then((responce: any) => {
        if (responce.data && responce.data.success) {
          showSnackBar(responce.data.message);
          history.push(`/registration/application_no/${appNo}`)
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join("\n"), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  }
  return (
    <Layout>
      <form onSubmit={handleAppNo}>
        <Typography component="div" className="centers-login-container mb-48 mt-20">
          <Typography component="h1" className="centers-login-header ">Application Number</Typography>
          <TextField
            className="centers-login-input"
            placeholder="Enter your application number"
            type="text"
            name="appNo"
            value={Values.appNo}
            onChange={handleChange}
          />
          <Typography component="div" className="my-4">
            <Button className="button-primary mr-3" type="submit" disabled={!Values.appNo}>Submit</Button>
            <Button className="button-secondary" type="submit" onClick={() => { history.push('/login') }}>Back</Button>
          </Typography>
        </Typography>
      </form>
    </Layout>
  )
}

export default ApplicationForm
