import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import { MdFileDownload } from 'react-icons/md';
import Admin_Api from '../../API/Admin_Api';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import { useSnackBar } from '../../Context/Snackbar/withSnackbar';

interface Data {
  id: any;
  city: string;
  name: string;
  rejected_at: any;
  reject_reason: string;
  website: string;
}

type Order = 'asc' | 'desc';

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'name', numeric: false, disablePadding: true, label: 'CENTER NAME' },
  { id: 'city', numeric: false, disablePadding: false, label: 'LOCATION' },
  { id: 'website', numeric: false, disablePadding: false, label: 'WEBSITE' },
  { id: 'reject_reason', numeric: false, disablePadding: false, label: 'REJECT REASON' },
  { id: 'rejected_at', numeric: false, disablePadding: false, label: 'REJECT DATE' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'left'}
            padding={headCell.disablePadding ? 'default' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

export default function EnhancedTable(props: any) {
  const { centerName, state, zip, categoryIds, endDate, startDate } = props;
  const { showSnackBar } = useSnackBar();
  const initial: any = [
    {
      id: '',
      name: '',
      address1: '',
      address2: '',
      city: '',
      zip: '',
      stateCode: '',
      requestTypeId: '',
      requestType: "",
      createdDate: ""
    },
  ];
  const classes = useStyles();
  const [rows, setRows] = useState(initial);
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('rejected_at');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = React.useState(true);
  const [emptyData, setemptyData] = React.useState(false);
  const [exportLoading,setExportLoading]= useState(false)
  useEffect(() => {
    const getdata = async () => {
      await Admin_Api.denied_center_list(
        page + 1,
        rowsPerPage,
        order,
        orderBy,
        centerName,
        categoryIds,
        zip,
        state,
        startDate,
        endDate
      )
        .then((response: any) => {
          if (response.data && response.data.success) {
            setLoading(false);
            setemptyData(false);
            if (!response.data.data.records.length) {
              setemptyData(true);
            } else {
              setRows(response.data.data.records);
              setCount(response.data.data.records_total);
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.data.message) {
              showSnackBar(error.response.data.message, 'error');
            } else if (error.response.data.error) {
              showSnackBar(error.response.data.error, 'error');
            } else if (error.response.data.errors) {
              showSnackBar(error.response.data.errors.join("\n"), 'error');
            }
          } else {
            showSnackBar(error.message, 'error');
          }
        });
    };

    getdata();
  }, [page, rowsPerPage, order,orderBy, centerName, state, zip, categoryIds, endDate, startDate]);

  const handleExport = async () => {
    setExportLoading(true)
    await Admin_Api.Export_denied_center_list(
      page + 1,
      count,
      order,
      centerName,
      categoryIds,
      zip,
      state,
      startDate,
      endDate,
    )
      .then((response: any) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'denied_centerlist.xls');
          document.body.appendChild(link);
          link.click();
          setExportLoading(false)
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join("\n"), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  };


  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Typography component="div" className="rainn-lg-container centers-admin-panel">
      <Typography component="div" className="flex justify-end">
        {exportLoading ? (
                <CircularProgress disableShrink />
              ) : 
        emptyData ? <></> :
          <MdFileDownload title="export"
            className="mx-2 my-1 text-tc-5 text-lg cursor-pointer hover:text-tc-3"
            onClick={handleExport}
          />
        
      }
      </Typography>
      {emptyData ? <Typography component="div" className="text-center pt-10 pb-10">No Data Found</Typography> :
        <Typography component="div" className="centers-admin-table">
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              {loading ? (
                <Typography component="div" className="centers-loader">
                  <CircularProgress disableShrink />
                </Typography>
              ) : (
                <TableBody>
                  {rows?.map((row: any, index: any) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow>
                        <TableCell component="th" id={labelId} scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell>{row.city},{row.state_code}
                          {row.zip}</TableCell>
                        <TableCell>{row.website}</TableCell>
                        <TableCell>{row.reject_reason}</TableCell>
                        <TableCell>{row.rejected_at}</TableCell>

                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10,25,50]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Typography>
      }
    </Typography>
  );
}