import React from 'react'
import Layout from '../../component/Layout/Layout'
import LogIn_component from '../../component/Admin/LogIn_component'
const LogIn:React.FunctionComponent = () => {
  return (
    <Layout>
      <LogIn_component/>
    </Layout>
  )
}

export default LogIn
