import React from 'react';
import NationalList from '../../../component/Admin/NationalSearchList';
import Layout from '../../../component/Admin/Layout/Layout';



const centerListPage: React.FunctionComponent = () => {

    return (
      <Layout>
        <NationalList/>
      </Layout>
  );
}

export default centerListPage;