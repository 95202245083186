import React, { useEffect, useState } from 'react'
import Layout from './Layout/Layout'
import Typography from "@material-ui/core/Typography"
import Admin_Api from '../../API/Admin_Api';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useSnackBar } from '../../Context/Snackbar/withSnackbar';

function Feedback(props: { match: { params: { id: any; }; }; }) {
  const { showSnackBar } = useSnackBar();
  const [id, setId] = useState(props.match.params.id);
  const [message, setMessage] = useState('');

  useEffect(() => {
    Admin_Api.View_request(id)
      .then((responce: any) => {
        if (responce.data && responce.data.success) {
          showSnackBar(responce.data.message);
          setMessage(responce.data.data.message);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join("\n"), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  }, []);

  return (
    <div>
      <Layout>
        <Container>
        <Typography component="div" className="centers-admin-panel">
          <Typography component="div" className="centers-admin-header">
            <Typography component="div" className="centers-admin-header-top-bar">
              <Typography
                component="h3"
                className="centers-admin-section-text flex items-center"
              >
                Message
              </Typography>
            </Typography>
          </Typography>
          <Typography>
            {message}
          </Typography>

          <Button
            type="submit"
            className="button-primary mr-3 mt-3"
          >
            <Link to="/approve_request_list">
              OK
            </Link>
          </Button>
        </Typography>
        </Container>
      </Layout>
    </div>
  )
}

export default Feedback
