import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import ListItem from '@material-ui/core/ListItem';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ReCAPTCHA from 'react-google-recaptcha';
import React, { useContext, useEffect, useState } from 'react';
import {
  MdLocationOn,
  MdPhone,
  MdPhoneInTalk,
  MdBusiness,
  MdPublic,
  MdGTranslate,
} from 'react-icons/md';
import fooAvatarImg from '../assets/images/centerstone.png';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import CenterMap from './CenterMap';
import api, { instance } from '../API/Api';
import { useSnackBar } from '../Context/Snackbar/withSnackbar';
import { useLanguageHandler } from '../Context/Admin/Language';
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const CenterInfo: React.FunctionComponent = (props: any) => {
  const { showSnackBar } = useSnackBar();
  const { languageInfo, handleLanguageInfo } = useLanguageHandler();

  const initial: any = {
    data: {
      address1: '',
      address2: '',
      business_phone: '',
      categories: [
        {
          id: '',
          name: { en: '', es: '' },
          options: [
            {
              id: '',
              name: {
                en: '',
                es: '',
              },
            },
          ],
        },
      ],
      center_type_id: '',
      city: '',
      county: '',
      hotline_phone: '',
      id: 4,
      latitude: '',
      longitude: '',
      name: '',
      state_code: '',
      tty: '',
      website: '',
      zip: '',
    },
  };
  const recaptchaRef = React.createRef<ReCAPTCHA>();
  const { t, i18n } = useTranslation();
  const { id } = props.match.params;
  const [load, setLoad] = useState(false);
  const [data, setData] = useState(initial);
  const [message, setMessage] = useState('' as string);
  const [loading, setLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState<any>();
  const [token, setToken] = useState('' as string | null);
  useEffect(() => {
    const changeLanguage = () => {
      i18n.changeLanguage(languageInfo);
    };
    changeLanguage();
  }, [languageInfo]);
  useEffect(() => {
    const getdata = () => {
      instance
        .get(`/search/view/center/${id}`)
        .then((responce: any) => {
          if (responce.data && responce.data.success) {
            const byteCharacters = atob(responce.data.data?.logo);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            const image = new Blob([byteArray], { type: 'image/jpeg' });
            setImageUrl(URL.createObjectURL(image));
            setData(responce.data);
            setLoading(false);
            setTimeout(() => {
              setLoad(true);
            }, 4000);
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.data.message) {
              showSnackBar(error.response.data.message, 'error');
            } else if (error.response.data.error) {
              showSnackBar(error.response.data.error, 'error');
            } else if (error.response.data.errors) {
              showSnackBar(error.response.data.errors.join('\n'), 'error');
            }
          } else {
            showSnackBar(error.message, 'error');
          }
        });
    };

    if (loading === true) {
      getdata();
    }
  }, [id]);

  return (
    <>
      <div>
        <Typography component="div" className="bg-pc-1 pt-6 pb-16 px-12">
          <Container maxWidth={false}>
            <Grid container direction="row" justify="space-between">
              <Grid item>
                <Link to="/" className="text-tc-7 text-sm">
                  Search Again
                </Link>
              </Grid>
              <Grid item>
                {languageInfo === 'en' && (
                  <a href={process.env.React_App_Spanish_site}>
                    <Typography className="text-tc-7 text-sm">
                      {' '}
                      <button>
                        <MdGTranslate className="inline-block mr-1 text-sm" />
                        En Español
                      </button>
                    </Typography>
                  </a>
                )}
                {languageInfo === 'es' && (
                  <a href={process.env.React_App_English_site}>
                    <Typography className="text-tc-7 text-sm">
                      {' '}
                      <button>
                        <MdGTranslate className="inline-block mr-1 text-sm" />
                        English
                      </button>
                    </Typography>
                  </a>
                )}
              </Grid>
            </Grid>
            <Typography
              component="h1"
              className="text-tc-7 text-4xl text-center pt-6"
            >
              RAINN - Partner {data.data.center_type_id === '1' ? '' : 'Local'}{' '}
              Crisis Center
            </Typography>
          </Container>
        </Typography>
        <Typography component="div">
          <Container maxWidth={false}>
            {loading ? (
              <Typography component="div" className="centers-loader">
                <CircularProgress disableShrink />
              </Typography>
            ) : (
              <Grid
                container
                direction="row"
                className="centers-detail-page"
                spacing={3}
                justify="space-between"
                alignItems="stretch"
              >
                <Grid item sm={8}>
                  <Typography component="h2">{data.data.name}</Typography>
                  <Typography component="div" className="mb-8">
                    <Grid container direction="row" alignItems="flex-start">
                      <Grid item>
                        <Card className="centers-logo">
                          <CardMedia
                            className="centers-logo-img"
                            image={imageUrl}
                          >
                            {data.data.logo === null
                              ? `${data.data.name.charAt(0)}`
                              : null}
                          </CardMedia>
                        </Card>
                      </Grid>
                      <Grid item className="flex-1">
                        <Table className="centers-detail-list">
                          <TableRow>
                            <TableCell>
                              <Typography
                                component="span"
                                className="centers-tag"
                              >
                                {data.data.center_type_id === '1'
                                  ? 'National'
                                  : 'Local'}
                              </Typography>
                            </TableCell>
                            <TableCell colSpan={3}>
                              <Typography component="div" className="flex">
                                {data.data.website !== null && (
                                  <>
                                    <MdPublic className="mr-2 text-tc-5 text-lg" />
                                    <Typography component="span">
                                      <a href={`${data.data.website}`}>
                                        {t('detail.center-website')}
                                      </a>
                                    </Typography>
                                  </>
                                )}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography component="div" className="flex">
                                <MdBusiness className="mr-2 text-tc-5 text-lg" />
                                <Typography component="span">
                                  <strong>{data.data.business_phone}</strong>{' '}
                                  {t('detail.business')}
                                </Typography>
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography component="div" className="flex">
                                <MdPhoneInTalk className="mr-2 text-tc-5 text-lg" />
                                <Typography component="span">
                                  <strong>{data.data.hotline_phone}</strong>{' '}
                                  {t('detail.hotline')}
                                </Typography>
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography component="div" className="flex">
                                <MdPhone className="mr-2 text-tc-5 text-lg" />
                                <Typography component="span">
                                  <strong>{data.data.tty}</strong> Tty
                                </Typography>
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={1}>
                              <Typography component="div" className="flex">
                                <MdLocationOn className="mr-2 text-tc-5 text-lg" />
                                <Typography component="span">
                                  {data.data.city !== null &&
                                  data.data.city.length > 0 ? (
                                    <>{data.data.city},</>
                                  ) : (
                                    ''
                                  )}
                                  {/* &nbsp;
                                  {data.data.county !== null &&
                                  data.data.county.length > 0 ? (
                                    <>{data.data.county},</>
                                  ) : (
                                    ''
                                  )} */}
                                  &nbsp;
                                  {data.data.state_code !== null &&
                                  data.data.state_code.length > 0 ? (
                                    <>{data.data.state_code},</>
                                  ) : (
                                    ''
                                  )}
                                  &nbsp;{data.data.zip}
                                </Typography>
                              </Typography>
                            </TableCell>
                            <TableCell colSpan={2}>
                              <Typography component="div" className="flex">
                                <Typography component="span">
                                  {data.data.county !== null &&
                                  data.data.county.length > 0 ? (
                                    <>
                                      <b>{t('detail.counties-served')}:</b> {data.data.county}
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </Typography>
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </Table>
                      </Grid>
                    </Grid>
                  </Typography>
                  <Typography component="div" className="content">
                    {data.data.categories?.map((info: any) => {
                      return (
                        <div key={info.id}>
                          <Typography component="div" className="mb-8">
                            {info.options.length > 0 && (
                              <Typography className="content-lead">
                                {languageInfo === 'en'
                                  ? info.name.en
                                  : info.name.es}
                              </Typography>
                            )}
                            {info.options?.map((option: any) => (
                              <Typography component="p">
                                {' '}
                                {languageInfo === 'en'
                                  ? option.name.en
                                  : option.name.es}
                              </Typography>
                            ))}
                          </Typography>
                        </div>
                      );
                    })}
                    <Typography component="div" className="mb-8">
                      <Typography className="content-lead">
                        {t('detail.rainn-affiliate')}
                      </Typography>
                      <Typography component="p">
                        {data.data.center_type_id === '2' ? (
                          <>{t('detail.yes')}</>
                        ) : (
                          'No'
                        )}
                      </Typography>
                    </Typography>
                    <Typography component="div" className="mb-8">
                      <Typography className="content-lead">
                        {t('detail.volunteer-opportunity')}
                      </Typography>
                      <Typography component="p">
                        {data.data.has_volunteer_opportunity === false ? (
                          'No'
                        ) : (
                          <Link to={`/volunteer_detail/${id}`}>
                            {t('detail.yes')}
                          </Link>
                        )}
                      </Typography>
                    </Typography>
                  </Typography>
                  {load && (
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={process.env.React_App_Site_Key as string}
                      onExpired={() => setToken('')}
                      size="invisible"
                      badge="bottomright"
                    />
                  )}
                </Grid>
                <Grid item sm={4}>
                  <Typography component="div" className="content-map">
                    <CenterMap
                      longitude={data.data.longitude}
                      latitude={data.data.latitude}
                    />
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Container>
        </Typography>
      </div>
    </>
  );
};

export default CenterInfo;
