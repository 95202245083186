import React from 'react';
import './assets/css/global.scss';
import CssBaseline from '@material-ui/core/CssBaseline';
import Routes from './common/route/routeConfig';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './assets/themes/theme';
import { StylesProvider } from '@material-ui/core/styles';
import { LanguageProvider } from './Context/Admin/Language';
import { SnackBarProvider } from './Context/Snackbar/withSnackbar';
import axios from 'axios';

const App: React.FunctionComponent = () => {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SnackBarProvider>
          <LanguageProvider>
            <CssBaseline />
            <Routes />
          </LanguageProvider>
        </SnackBarProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default App;
