import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Api from '../../API/Admin_Api';
import { CircularProgress } from '@material-ui/core';
import {
  MdVisibility,
  MdModeEdit,
  MdVisibilityOff,
  MdLockOutline,
  MdLockOpen,
} from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useSnackBar } from '../../Context/Snackbar/withSnackbar';
interface Data {
  name: string;
  contact: string;
  population: string;
  services: string;
  Action: any;
  id: 0;
  2: any;
  1: any;
  title: string;
  email: string;
  phone: string;
  first_name: string;
  last_name: string;
}

type Order = 'asc' | 'desc';
interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'NAME OF RESOURCE',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'CONTACT INFORMATION',
  },
  { id: 2, numeric: false, disablePadding: false, label: 'POPULATION SERVED' },
  { id: 1, numeric: false, disablePadding: false, label: 'SERVICES PROVIDED' },
  { id: 'Action', numeric: false, disablePadding: false, label: 'ACTION' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
);

export default function EnhancedTable(props: any) {
  const { centerName, categoryIds, Clear, click } = props;
  const { showSnackBar } = useSnackBar();
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('name');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(true);
  const [emptyData, setemptyData] = React.useState(false);
  const [count, setCount] = useState(0);
  const [Status, setStatus] = useState(false);

  useEffect(() => {
    const getdata = async () => {
      await Api.getNationalCenterList(
        page + 1,
        rowsPerPage,
        order,
        centerName,
        categoryIds,
        orderBy
      )
        .then((response: any) => {
          if (response.data && response.data.success) {
            setLoading(false);
            setemptyData(false);
            if (!response.data.data.records.length) {
              setemptyData(true);
            } else {
              setRows(response.data.data.records);
              setCount(response.data.data.records_total);
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.data.message) {
              showSnackBar(error.response.data.message, 'error');
            } else if (error.response.data.error) {
              showSnackBar(error.response.data.error, 'error');
            } else if (error.response.data.errors) {
              showSnackBar(error.response.data.errors.join('\n'), 'error');
            }
          } else {
            showSnackBar(error.message, 'error');
          }
        });
    };
    if (Clear) {
      setPage(0);
      setLoading(true);
      setTimeout(() => {
        getdata();
      }, 700);
    } else {
      getdata();
    }
  }, [page, rowsPerPage, order, orderBy, centerName, categoryIds, Status]);
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    click();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleActivate = (id: number) => {
    Api.center_Activate(id)
      .then((response: any) => {
        if (response.data && response.data.success) {
          showSnackBar(response.data.message);
          setStatus(!Status);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  };

  const handleDeactivate = (id: number) => {
    Api.center_Deactivate(id)
      .then((response: any) => {
        if (response.data && response.data.success) {
          showSnackBar(response.data.message);
          setStatus(!Status);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  };

  return (
    <>
      {emptyData ? (
        <Typography component="div" className="text-center pt-10 pb-10">
          No Data Found
        </Typography>
      ) : (
        <Typography component="div" className="centers-admin-table">
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              {loading ? (
                <CircularProgress disableShrink />
              ) : (
                <TableBody>
                  {rows?.map((row: any, index: any) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell>
                          <Typography>{row.id}</Typography>
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.name}
                        </TableCell>
                        <TableCell>
                          <Typography>{row.hotline_phone}</Typography>
                          <Typography>{row.website}</Typography>
                        </TableCell>
                        <TableCell>
                          {row?.categories.map((data: any) => {
                            const datainfo =
                              data.id === 2 &&
                              data.options.map((res: any) => {
                                return (
                                  <>
                                    <Typography> {res.name?.en}</Typography>
                                  </>
                                );
                              });
                            return datainfo;
                          })}
                        </TableCell>
                        <TableCell>
                          {row?.categories.map((data: any) => {
                            const list =
                              data.id === 1 &&
                              data.options.map((res: any) => {
                                return (
                                  <>
                                    <Typography> {res.name?.en}</Typography>
                                  </>
                                );
                              });
                            return list;
                          })}
                        </TableCell>
                        <TableCell>
                          <span className="flex items-center">
                            <Link to={`national_center_edit/${row.id}`}>
                              <MdModeEdit
                                title="edit"
                                className="centers-admin-table-icon"
                              />
                            </Link>
                            {row.active ? (
                              <MdLockOpen
                                title="active"
                                className="centers-admin-table-icon"
                                onClick={() => handleDeactivate(row.id)}
                              />
                            ) : (
                              <MdLockOutline
                                title="deactive"
                                className="centers-admin-table-icon"
                                onClick={() => handleActivate(row.id)}
                              />
                            )}
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Typography>
      )}
    </>
  );
}
