import React from 'react';
import Layout from '../../../component/Admin/Layout/Layout';
import ApproveRequestlist from '../../../component/Admin/approveRequestlist'


const ApproveRequestList: React.FunctionComponent = () => {

    return (
      <Layout>
        <ApproveRequestlist/>
      </Layout>
  );
}

export default ApproveRequestList;