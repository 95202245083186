import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Slider from '@material-ui/core/Slider';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useContext, useEffect, useState } from 'react';
import Map from './map';
import StateMap from './StateMap';
import CentersCard from './CentersCard';
import api from '../API/Api';
import { MdArrowDownward, MdGTranslate } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { useSnackBar } from '../Context/Snackbar/withSnackbar';
import { CircularProgress } from '@material-ui/core';
import { useLanguageHandler } from '../Context/Admin/Language';
import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from 'react-router';

const Form: React.FunctionComponent = (props: any) => {
  const { showSnackBar } = useSnackBar();
  const { languageInfo, handleLanguageInfo } = useLanguageHandler();

  const initial = {
    ZIPCode: '' as any,
  };
  const entryInput = {
    data: {
      categories: [
        { name: { en: '', es: '' }, options: [] },
        { name: { en: '', es: '' }, options: [] },
        {
          name: { en: '', es: '' },
          options: [
            {
              id: '',
              name: {
                en: '',
                es: '',
              },
            },
          ],
        },
      ],
      states: [],
      center_count: [],
    },
  };
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [formFieldInfo, setformFieldInfo] = useState(entryInput);
  const [States, setStates] = useState(null);
  const [StatesCode, setStatesCode] = useState('');
  const [population, setPopulation] = useState([]);
  const [Provider, setProvider] = useState([]);
  const recaptchaRef = React.createRef<ReCAPTCHA>();
  const [distance, setDistance] = useState(5);
  const [getState,setGetState] = useState('')
  const [Values, setValues] = useState(initial);
  const [populationCategoryId, setpopulationCategoryId] = useState([]);
  const [buttonClick, setButtonClick] = useState(false);
  const [providerCategoryId, setproviderCategoryId] = useState([]);
  const [centerData, setCenterData] = useState([]);
  const [error, setError] = useState({ message: '' });
  const [clear, setClear] = useState(false);
  const [loading, setLoading] = useState(true);
  const [load,setLoad] = useState(false)
  const CategoryId = [...providerCategoryId, ...populationCategoryId];
  const marks: any = [
    {
      value: 5,
      label: '5',
    },
    {
      value: 25,
      label: '25',
    },
    {
      value: 50,
      label: '50',
    },
  ];
  useEffect(() => {
    api
      .getFormData()
      .then((responce: any) => {
        if (responce.data && responce.data.success) {
          setformFieldInfo(responce.data);
          setLoading(false);
          setTimeout(()=>{
            setLoad(true)
          },4000)
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  }, []);

  const handleChange = (e: {
    target: { name: string; value: string | number };
  }) => {
    const { name, value } = e.target;
    setValues({
      ...Values,
      [name]: value,
    });
  };
  const handleClear = () => {
    setClear(true);
    setValues(initial);
    setPopulation([]);
    setStates(null);
    setStatesCode('')
    setProvider([]);
    setDistance(5);
    setpopulationCategoryId([]);
    setproviderCategoryId([]);
    setButtonClick(false);
    setError({ message: '   ' });
  };
  const handleCenters = async (e: any) => {
    const { ZIPCode } = Values;
    e.preventDefault();

    try{
    await api
      .searchCenters(StatesCode, ZIPCode | 0, distance | 0, CategoryId)
      .then((responce: any) => {
        if (responce.data && responce.data.success) {
          setCenterData(responce.data.data.records);
          setGetState(responce.data.data.state_code)
          setError({ message: '' });
            setButtonClick(true);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  } catch (err:any) {
    setError(err);
  }
  };

  const getCentersFromMap = async (centers: React.SetStateAction<string>) => {
    setStatesCode(centers);
    await api
      .searchCenters(centers, 0, 0, CategoryId)
      .then((responce: any) => {
        if (responce.data && responce.data.success) {
          setCenterData(responce.data.data.records);
          setError({ message: '' });
          setButtonClick(true);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  };
  useEffect(() => {
    const changeLanguage = () => {
      i18n.changeLanguage(languageInfo);
    };
    changeLanguage();
  }, [languageInfo]);
  const formIsValid = () => {
    const isValid = Values.ZIPCode || States;
    return isValid;
  };

  return (
    <>
      <div className="centers-banner">
        <Container>
          {loading ? (
            <Typography component="div" className="centers-loader">
              <CircularProgress disableShrink />
            </Typography>
          ) : (
            <Grid container spacing={2} className="bg-pc-1">
              <Grid item sm={4}>
                <Box className="form-box mt-3 mb-4">
                  <form>
                    <Typography component="h3" className="form-box-title">
                      {t('search.title')}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <label className="form-box-label" htmlFor="states">
                          {t('search.state')}
                        </label>
                        <Autocomplete
                          className="centers-banner-autocomplete"
                          id="states"
                          disabled={Values.ZIPCode !== ''}
                          disableClearable
                          clearOnEscape
                          options={formFieldInfo?.data.states}
                          getOptionLabel={(option: any) => {
                            return option.name;
                          }}
                          openOnFocus
                          value={States}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setStatesCode(newValue.code);
                            }
                            setStates(newValue);
                            setButtonClick(false);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component="div" className="centers-divider">
                          <Typography component="div" className="divider-text">
                            <span>{t('search.or')}</span>
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <label className="form-box-label" htmlFor="ZIPCode">
                          {t('search.zip')}
                        </label>
                        <TextField
                          id="ZIPCode"
                          disabled={States !== null}
                          type="number"
                          className="centers-banner-input"
                          placeholder={t('search.zipPlace')}
                          name="ZIPCode"
                          value={Values.ZIPCode}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <label className="form-box-label">
                          {t('search.dist')}
                        </label>
                        <Slider
                          className="centers-banner-slider"
                          disabled={States !== null}
                          defaultValue={5}
                          valueLabelFormat={(value) => {
                            return `${value}`;
                          }}
                          getAriaValueText={(value) => {
                            return (
                              marks?.findIndex((mark: { value: number }) =>
                                Number(mark.value === value)
                              ) + 1
                            );
                          }}
                          step={null}
                          valueLabelDisplay="on"
                          marks={marks}
                          value={distance}
                          onChange={(e, newValue) => {
                            setDistance(newValue as any);
                          }}
                          min={5}
                          max={50}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <label className="form-box-label" htmlFor="population">
                          {languageInfo === 'en'
                            ? formFieldInfo.data.categories[1].name.en
                            : formFieldInfo.data.categories[1].name.es}
                        </label>
                        <Autocomplete
                          multiple
                          className="centers-banner-autocomplete"
                          limitTags={2}
                          id="population"
                          options={formFieldInfo.data.categories[1].options.sort((a, b) =>languageInfo === 'en'? a.name.en.localeCompare(b.name.en) : a.name.es.localeCompare(b.name.es))}
                          getOptionLabel={(option) => {
                            if (languageInfo === 'en') {
                              return option.name.en;
                            } else {
                              return option.name.es;
                            }
                          }}
                          value={population}
                          onChange={(event, newInput) => {
                            if (newInput !== null) {
                              setpopulationCategoryId(
                                JSON.parse(JSON.stringify(newInput)).map(
                                  (doc: { id: string }) => doc.id
                                )
                              );
                              setPopulation(newInput as any);
                            }
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <label className="form-box-label" htmlFor="provider">
                          {languageInfo === 'en'
                            ? formFieldInfo.data.categories[0].name.en
                            : formFieldInfo.data.categories[0].name.es}
                        </label>
                        <Autocomplete
                          multiple
                          className="centers-banner-autocomplete"
                          limitTags={2}
                          id="provider"
                          options={formFieldInfo.data.categories[0].options.sort((a, b) =>languageInfo === 'en'? a.name.en.localeCompare(b.name.en) : a.name.es.localeCompare(b.name.es))}
                          getOptionLabel={(option) => {
                            if (languageInfo === 'en') {
                              return option.name.en as string;
                            } else {
                              return option.name.es as string;
                            }
                          }}
                          value={Provider}
                          onChange={(event, newInput) => {
                            if (newInput !== null) {
                              setproviderCategoryId(
                                JSON.parse(JSON.stringify(newInput)).map(
                                  (doc: { id: string }) => doc.id
                                )
                              );
                              setProvider(newInput as any);
                            }
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          className="button-primary mr-3"
                          onClick={handleCenters}
                          disabled={!formIsValid()}
                        >
                          {t('search.buttonFind')}
                        </Button>
                        <Button
                          className="button-secondary"
                          onClick={handleClear}
                        >
                          {t('search.buttonClear')}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </Grid>
              <Grid item sm={8} className="w-full">
                <div className="flex flex-col justify-between h-full">
                  <div className="text-right my-2">
                    {languageInfo === 'en' && (
                      <a href={process.env.React_App_Spanish_site}>
                      <button
                        className="float-right text-xs text-tc-1"
                      >
                        <MdGTranslate className="inline-block mr-1 text-sm" />
                        En Español
                      </button>
                      </a>
                    )}
                    {languageInfo === 'es' && (
                      <a href={process.env.React_App_English_site}>
                      <button
                        className="float-right text-xs text-tc-1"
                      >
                        <MdGTranslate className="inline-block mr-1 text-sm" />
                        English
                      </button>
                      </a>
                    )}
                  </div>
                  {buttonClick === false && (
                    <Map
                      centers={formFieldInfo.data.center_count}
                      select={buttonClick}
                      getCentersFromMap={getCentersFromMap}
                    />
                  )}

                  {buttonClick && (
                    <StateMap centers={StatesCode || getState} centerData={centerData} {...props}/>
                  )}
                </div>
              </Grid>
            </Grid>
          )}
        </Container>
      </div>
      {error.message.length === 0 ? (
        centerData.length > 0 && <CentersCard {...props} val={centerData} />
      ) : clear === false ? (
        <Typography component="div" className="p-10 bg-tc-7">
          <Container>
            <Typography
              component="h5"
              className="text-2xl font-extrabold text-pc-1 text-right"
            >
              0 Center found
            </Typography>
            <Typography
              component="div"
              className="centers-content text-center font-bold"
            >
              {error.message}
            </Typography>
          </Container>
        </Typography>
      ) : null}
      {load &&
      <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={process.env.React_App_Site_Key as string}
                      size="invisible"
                      badge="bottomright"
                    />
      }
    </>
  );
};

export default Form;
