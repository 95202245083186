import React, { useEffect, useState } from 'react';
import menuItems from '../common/menuItems.json';
import AdminNav from '../common/adminMenuItems.json';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import Hidden from '@material-ui/core/Hidden';

const PrimaryNavigation: React.FunctionComponent = (props: any) => {
  const [activeSubMenuId, setActiveSubMenuId] = useState('');
  let menuItemsData;
  if (props.user === 'Private') {
    menuItemsData = AdminNav.data;
  } else {
    menuItemsData = menuItems.data;
  }
  const showMenu = (menuId: any, event: any) => {
    setActiveSubMenuId(menuId);

    event.target
      .closest('ul')
      ?.childNodes.forEach((li: any) =>
        li.classList.remove('menu-link-item-active')
      );

    const menuBottom = document.getElementById(menuId);
    if (menuBottom) {
      const menuBottomLi = menuBottom.closest('li');
      menuBottomLi?.classList.add('menu-link-item-active');
    }
  };

  const hideMenu = () => {
    setActiveSubMenuId('');
    const removeMenu = document.getElementById('primaryNav1');
    const removeArr = removeMenu?.childNodes;
    removeArr?.forEach((li: any) =>
      li.classList.remove('menu-link-item-active')
    );
  };

  return (
    <List className="centers-nav-list" id="primaryNav1" onMouseLeave={hideMenu}>
      {menuItemsData.map((item: any, i: any) => {
        return (
          <>
            {item.isMobile ? (
              <Hidden lgUp>
                <ListItem className={`${item.cls}`}>
                  <ListItemText>
                    <Link
                      href={`${item.url}`}
                      onMouseOver={(event: any) => {
                        showMenu('nav1' + (i + 1), event);
                      }}
                    >
                      {item.name}
                    </Link>
                    {item.children && (
                      <div className="centers-sub-nav" id={'nav1' + (i + 1)}>
                        {item.children.map((headerLinks: any, j: any) => {
                          return activeSubMenuId == 'nav1' + (i + 1) ? (
                            <Link href={`${headerLinks.url}`}>
                              {headerLinks.name}
                            </Link>
                          ) : null;
                        })}
                      </div>
                    )}
                  </ListItemText>
                </ListItem>
              </Hidden>
            ) : item.isAlign ? (
                <ListItem className={`${item.cls}`}>
                  <ListItemText>
                    <Link
                    className="center-nav-link"
                      href={`${item.url}`}
                      onMouseOver={(event: any) => {
                        showMenu('nav1' + (i + 1), event);
                      }}
                    >
                      {item.name}
                    </Link>
                    {item.children && (
                      <div className="centers-sub-nav split-columns" id={'nav1' + (i + 1)}>
                        {item.children.map((headerLinks: any, j: any) => {
                          return activeSubMenuId == 'nav1' + (i + 1) ? (
                            <Link href={`${headerLinks.url}`}>
                              {headerLinks.name}
                            </Link>
                          ) : null;
                        })}
                      </div>
                    )}
                  </ListItemText>
                </ListItem>
              )  : item.isEn_Español ? (
                <ListItem className={`${item.cls}`}>
                  <ListItemText>
                    <Link
                    className="center-nav-link"
                      href={`${item.url}`}
                      onMouseOver={(event: any) => {
                        showMenu('nav1' + (i + 1), event);
                      }}
                    >
                      {item.name}
                    </Link>
                    {item.children && (
                      <div className="centers-sub-nav last_nav" id={'nav1' + (i + 1)}>
                        {item.children.map((headerLinks: any, j: any) => {
                          return activeSubMenuId == 'nav1' + (i + 1) ? (
                            <Link href={`${headerLinks.url}`}>
                              {headerLinks.name}
                            </Link>
                          ) : null;
                        })}
                      </div>
                    )}
                  </ListItemText>
                </ListItem>
              ) : (
              <ListItem className={`${item.cls}`}>
                <ListItemText>
                  <Link
                    className="center-nav-link"
                    href={`${item.url}`}
                    onMouseOver={(event: any) => {
                      showMenu('nav1' + (i + 1), event);
                    }}
                  >
                    {item.name}
                  </Link>
                  {item.children && (
                    <div className="centers-sub-nav" id={'nav1' + (i + 1)}>
                      {item.children.map((headerLinks: any, j: any) => {
                        return activeSubMenuId == 'nav1' + (i + 1) ? (
                          <Link href={`${headerLinks.url}`}>
                            {headerLinks.name}
                          </Link>
                        ) : null;
                      })}
                    </div>
                  )}
                </ListItemText>
              </ListItem>
            )}
          </>
        );
      })}
    </List>
  );
};

export default PrimaryNavigation;
