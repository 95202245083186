import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import { useForm } from '../../hooks/useForm'
import Button from '@material-ui/core/Button'
import API from '../../API/Api'
import Layout from '../Layout/Layout'
import { useSnackBar } from '../../Context/Snackbar/withSnackbar'

const ForgotPassword: React.FunctionComponent = () => {
  const { showSnackBar } = useSnackBar();

  const Initial = {
    email: ''
  }
  const { Values,
    setValues,
    handleChange,
    resetForm } = useForm(Initial)

  const handleForgot = async (e: any) => {
    e.preventDefault();
    const { email } = Values
    await API.ForgotPassword(email)
      .then((responce: any) => {
        if (responce.data && responce.data.success) {
          showSnackBar(responce.data.message);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join("\n"), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  }
  return (
    <Layout>
      <form onSubmit={handleForgot}>
        <Typography component="div" className="centers-login-container mb-48 mt-20">
          <Typography component="h1" className="centers-login-header ">Account Login</Typography>
          <Typography component="h4" className="centers-login-text">We are glad to see you again!</Typography>
          <TextField
            className="centers-login-input"
            placeholder="Username/Email"
            type="email"
            name="email"
            value={Values.email}
            onChange={handleChange}
          />
          <Typography component="div" className="my-4">
            <Button className="button-primary" type="submit" disabled={!Values.email}>Reset Password</Button>
          </Typography>
        </Typography>
      </form>
    </Layout>
  )
}

export default ForgotPassword
