import React, { useState } from "react";

export function useForm(initialFValues:any) {
  const [Values, setValues] = useState(initialFValues);
  const [errors, setErrors] = useState({});

  const handleChange = (e:any) => {
    const { name, value } = e.target;
    setValues({
      ...Values,
      [name]: value,
    });
  };

  const resetForm = () => {
    setValues(initialFValues);
    setErrors({});
  };

  return {
    Values,
    setValues,
    errors,
    setErrors,
    handleChange,
    resetForm,
  };
}
