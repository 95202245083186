import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import {
  MdAdd,
  MdRemove,
  MdModeEdit,
} from 'react-icons/md';
import Admin_Api from '../../API/Admin_Api';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { useSnackBar } from '../../Context/Snackbar/withSnackbar';

function Row() {
  const { showSnackBar } = useSnackBar();
  const [EmailData, setEmailData] = useState<any>({
    center: [{ id: '', name: '', subject: '', content: '' }],
    request_call_data: [{ id: '', name: '', subject: '', content: '' }],
  });
  const [open, setOpen] = React.useState(false);
  const [toggle, setToggle] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [emptyData, setemptyData] = React.useState(false);

  useEffect(() => {
    Admin_Api.EmailTemplatelist()
      .then((response: any) => {
        if (response.data && response.data.success) {
          setLoading(false);
          if (!response.data.data.email_template?.center.length && !response.data.data.email_template?.request_call_data.length) {
            setemptyData(true);
          } else {
            setEmailData(response.data.data.email_template);
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join("\n"), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  }, []);
  
  return (
    <React.Fragment>
      {loading ? (<Typography component="div" className="centers-loader">
                <CircularProgress disableShrink />
              </Typography>) :
        (
          emptyData ? <Typography component="div" className="text-center pt-10 pb-10">No Data Found</Typography> :
            <>
              <TableRow>
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                    className="centers-admin-table-button mr-1"
                  >
                    {open ? <MdRemove /> : <MdAdd />}
                  </IconButton>
                  Center
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Table>
                      <TableBody>
                        {EmailData?.center?.map((template: any) => (
                          <TableRow key={template.id}>
                            <TableCell component="th" scope="row">
                              {template.name}
                            </TableCell>
                            <TableCell align="right">
                              <span className="flex items-center justify-end">
                                <Link to={`/email_templates/${template.id}`}> <MdModeEdit title="edit" className="centers-admin-table-icon" /></Link>
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Collapse>
                </TableCell>
              </TableRow>
            </>

        )
      }
      
    </React.Fragment >
  );
}

export default function CollapsibleTable() {
  return (
    <>
      <Container>
        <Typography component="div" className="centers-admin-panel">
          <Typography component="div" className="centers-admin-header">
            <Typography component="div" className="centers-admin-header-top-bar">
              <Typography
                component="h3"
                className="centers-admin-section-text flex items-center"
              >
                Email Templates
              </Typography>
            </Typography>
          </Typography>
          <Typography component="div" className="centers-admin-table">
            <TableContainer>
              <Table aria-label="collapsible table">
                <TableHead className="centers-admin-table-title">
                  <Row />
                </TableHead>
              </Table>
            </TableContainer>
          </Typography>
        </Typography>
      </Container>
    </>
  );
}
