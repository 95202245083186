import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useState } from 'react';
import Layout from './Layout/Layout'
import Admin_Api from '../../API/Admin_Api';
import { useSnackBar } from '../../Context/Snackbar/withSnackbar';
import draftToHtml from 'draftjs-to-html';

function contactUs() {
  const { showSnackBar } = useSnackBar();
  const [editorState, setEditorstate] = useState(EditorState.createEmpty())

  const onEditorStateChange = (editorState: any) => {
    setEditorstate(editorState as any)
  }

  const handleSubmit = () => {
    const convertedContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    Admin_Api.Message_to_rainn(convertedContent)
      .then((responce: any) => {
        if (responce.data && responce.data.success) {
          showSnackBar(responce.data.message);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join("\n"), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  }

  const handleClear = () => {
    setEditorstate(EditorState.createEmpty());
  }


  const getFileBase64 = (file: any, callback: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
    reader.onerror = error => {
      showSnackBar('Something went wrong!', 'error');
    };
  };

  const _uploadImageCallBack = (file: any) => new Promise(
    (resolve, reject) => getFileBase64(
      file,
      (data: any) => resolve({ data: { link: data } })
    )
  );

  return (
    <>
      <Layout>
        <Container>
          <Typography component="div" className="centers-admin-panel">
            <Typography component="div" className="centers-admin-header">
              <Typography component="div" className="centers-admin-header-top-bar">
                <Typography component="h3" className="centers-admin-section-text">Contact RAINN</Typography>
              </Typography>
            </Typography>
            <Typography component="div">
              <Typography component="h3" className="centers-admin-section-text pb-5">Have any questions or concerns? Let us know</Typography>
              <div className="centers-admin-wysiwyg-wrapper">
                <Editor
                  editorState={editorState}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={onEditorStateChange}
                  toolbarClassName="toolbar-class"
                  toolbar={{
                    image: {
                      uploadCallback: _uploadImageCallBack,
                      inputAccept: 'image/*',
                      previewImage: true, alt: { present: true, mandatory: false }
                    }
                  }}
                />
              </div>

              <Typography component="div" className="centers-admin-tab-panel-buttons my-5">
                <Button className="button-primary mr-3" onClick={handleSubmit}>Submit</Button>
                <Button className="button-secondary" onClick={handleClear}>Cancel</Button>
              </Typography>
            </Typography>
          </Typography>
        </Container>
      </Layout>
    </>
  );
}

export default contactUs