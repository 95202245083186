import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import Map from '../map';
import StateMap from '../StateMap';
import api from '../../API/Api';
import { MdArrowDownward, MdGTranslate } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Slider } from '@material-ui/core';
import VolunteerCentersCard from './VolunteerCentersCard';
import { useLanguageHandler } from '../../Context/Admin/Language';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSnackBar } from '../../Context/Snackbar/withSnackbar';

const VolunteerForm: React.FunctionComponent = (props: any) => {
  const { showSnackBar } = useSnackBar();
  const { languageInfo, handleLanguageInfo } = useLanguageHandler();
  const marks: any = [
    {
      value: 5,
      label: '5',
    },
    {
      value: 25,
      label: '25',
    },
    {
      value: 50,
      label: '50',
    },
  ];

  const initial = {
    ZIPCode: '' as any,
  };
  const entryInput = {
    data: {
      states: [],
      center_count: [],
    },
  };
  const recaptchaRef = React.createRef<ReCAPTCHA>();
  const { t, i18n } = useTranslation();
  const [formFieldInfo, setformFieldInfo] = useState(entryInput);
  const [distance, setDistance] = useState(5);
  const [States, setStates] = useState(null);
  const [StatesCode, setStatesCode] = useState('');
  const [getState,setGetState] = useState('')
  const [loading, setLoading] = useState(true);
  const [Values, setValues] = useState(initial);
  const [buttonClick, setButtonClick] = useState(false);
  const [centerData, setCenterData] = useState([]);
  const [error, setError] = useState({ message: '' });
  const [clear, setClear] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    api
      .getFormData()
      .then((info: any) => {
        setformFieldInfo(info.data);
        setLoading(false);

        setTimeout(() => {
          setLoad(true);
        }, 4000);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const handleChange = (e: {
    target: { name: string; value: string | number };
  }) => {
    const { name, value } = e.target;
    setValues({
      ...Values,
      [name]: value,
    });
  };
  const handleClear = () => {
    setClear(true);
    setValues(initial);
    setStatesCode('');
    setDistance(5);
    setStates(null);
    setButtonClick(false);
    setError({ message: '   ' });
  };
  const handleCenters = async (e: any) => {
    const { ZIPCode } = Values;
    e.preventDefault();
    try {
      await api
        .Volunteer_search(StatesCode, ZIPCode | (0 as any), distance | 0)
        .then((doc: any) => {
          setCenterData(doc.data.data.records);
          // setStatesCode(doc.data.data.state_code);
          setGetState(doc.data.data.state_code)
          setError({ message: '' });
          setButtonClick(true);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.data.message) {
              showSnackBar(error.response.data.message, 'error');
            } else if (error.response.data.error) {
              showSnackBar(error.response.data.error, 'error');
            } else if (error.response.data.errors) {
              showSnackBar(error.response.data.errors.join('\n'), 'error');
            }
          } else {
            showSnackBar(error.message, 'error');
          }
        });
    } catch (err: any) {
      setError(err);
    }
  };
  const getCentersFromMap = async (centers: React.SetStateAction<string>) => {
    setStatesCode(centers);
    await api
      .Volunteer_search(centers as any, 0, 0)
      .then((doc) => {
        setCenterData(doc.data.data.records);
        setError({ message: '' });
        setButtonClick(true);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  };
  useEffect(() => {
    const changeLanguage = () => {
      i18n.changeLanguage(languageInfo);
    };
    changeLanguage();
  }, [languageInfo]);

  const formIsValid = () => {
    const isValid = Values.ZIPCode || States;
    return isValid;
  };

  return (
    <>
      <div className="centers-banner">
        <Container>
          {loading ? (
            <Typography component="div" className="centers-loader">
              <CircularProgress disableShrink />
            </Typography>
          ) : (
            <Grid container spacing={2} className="bg-pc-1">
              <Grid item sm={4}>
                <Typography
                  component="div"
                  className="flex h-full items-center"
                >
                  <Box className="form-box mt-3 mb-4">
                    <form>
                      <Typography component="h3" className="form-box-title">
                        {t('search.volunteer_title')}
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <label className="form-box-label" htmlFor="states">
                            {t('search.state')}
                          </label>
                          <Autocomplete
                            className="centers-banner-autocomplete"
                            id="states"
                            disabled={Values.ZIPCode !== ''}
                            disableClearable
                            clearOnEscape
                            options={formFieldInfo?.data.states}
                            getOptionLabel={(option: any) => {
                              return option.name;
                            }}
                            openOnFocus
                            value={States}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setStatesCode(newValue.code);
                              }
                              setStates(newValue);
                              setButtonClick(false);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography
                            component="div"
                            className="centers-divider"
                          >
                            <Typography
                              component="div"
                              className="divider-text"
                            >
                              <span>{t('search.or')}</span>
                            </Typography>
                          </Typography>
                          </Grid>

                          <Grid item xs={6}>
                            <label className="form-box-label" htmlFor="ZIPCode">
                              {t('search.zip')}
                            </label>
                            <TextField
                              id="ZIPCode"
                              type="number"
                              disabled={States !== null}
                              className="centers-banner-input"
                              placeholder={t('search.zipPlace')}
                              name="ZIPCode"
                              value={Values.ZIPCode}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <label className="form-box-label">
                              {t('search.dist')}
                            </label>
                            <Slider
                              className="centers-banner-slider"
                              disabled={States !== null}
                              defaultValue={5}
                              valueLabelFormat={(value) => {
                                return `${value}`;
                              }}
                              getAriaValueText={(value) => {
                                return (
                                  marks?.findIndex((mark: { value: number }) =>
                                    Number(mark.value === value)
                                  ) + 1
                                );
                              }}
                              step={null}
                              valueLabelDisplay="on"
                              marks={marks}
                              value={distance}
                              onChange={(e, newValue) => {
                                setDistance(newValue as any);
                              }}
                              min={5}
                              max={50}
                            />
                          </Grid>
                       
                        <Grid item xs={12}>
                          <Button
                            className="button-primary mr-3"
                            disabled={!formIsValid()}
                            onClick={handleCenters}
                          >
                            {t('search.buttonOpportunity')}
                            {/* Find Opportunities */}
                          </Button>
                          <Button
                            className="button-secondary"
                            onClick={handleClear}
                          >
                            {t('search.buttonClear')}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Box>
                </Typography>
              </Grid>
              <Grid item sm={8}>
                <div className="flex flex-col justify-between h-full">
                  <div className="text-right my-2">
                    {languageInfo !== 'en' ? (
                      <button
                        onClick={() => handleLanguageInfo('en')}
                        className="float-right text-xs text-tc-1"
                      >
                        <MdGTranslate className="inline-block mr-1 text-sm" />
                        English
                      </button>
                    ) : (
                      <button
                        onClick={() => handleLanguageInfo('es')}
                        className="float-right text-xs text-tc-1"
                      >
                        <MdGTranslate className="inline-block mr-1 text-sm" />
                        En Español
                      </button>
                    )}
                  </div>
                  {buttonClick === false && (
                    <Map
                      centers={formFieldInfo.data.center_count}
                      select={buttonClick}
                      getCentersFromMap={getCentersFromMap}
                    />
                  )}

                  {buttonClick && (
                    <StateMap centers={StatesCode  || getState} centerData={centerData} />
                  )}
                </div>
              </Grid>
            </Grid>
          )}
        </Container>
      </div>
      {error.message.length === 0 ? (
        centerData.length > 0 && (
          <VolunteerCentersCard {...props} val={centerData} />
        )
      ) : clear === false ? (
        <Typography component="div" className="p-10 bg-tc-7">
          <Container>
            <Typography
              component="h5"
              className="text-2xl font-extrabold text-pc-1 text-right"
            >
              0 Center found
            </Typography>
            <Typography
              component="div"
              className="centers-content text-center font-bold"
            >
              {error.message}
            </Typography>
          </Container>
        </Typography>
      ) : null}
      {load && (
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.React_App_Site_Key as string}
          size="invisible"
          badge="bottomright"
        />
      )}
    </>
  );
};

export default VolunteerForm;
