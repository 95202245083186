import Snackbar from '@material-ui/core/Snackbar';
import { Alert, Color } from '@material-ui/lab';
import React, { createContext, useContext } from 'react';

type SnackBarContextActions = {
    showSnackBar: (text: string, typeColor?: Color) => void;
};

const SnackBarContext = createContext({} as SnackBarContextActions);

interface SnackBarContextProviderProps {
    children: React.ReactNode;
}

const SnackBarProvider: React.FC<SnackBarContextProviderProps> = ({ children, }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState<string>('');
    const [typeColor, setTypeColor] = React.useState<Color>('success'); /* success | error | warning | info */

    const showSnackBar = (text: string, color: Color = 'success') => {
        setMessage(text);
        setTypeColor(color);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <SnackBarContext.Provider value={{ showSnackBar }}>
            <Snackbar
                open={open}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={handleClose}>
                <Alert variant="filled" onClose={handleClose} severity={typeColor} style={{whiteSpace: 'pre-wrap'}}>
                    {message}
                </Alert>
            </Snackbar>
            {children}
        </SnackBarContext.Provider>
    );
};

const useSnackBar = (): SnackBarContextActions => {
    const context = useContext(SnackBarContext);

    if (!context) {
        throw new Error('useSnackBar must be used within an SnackBarProvider');
    }

    return context;
};

export { SnackBarProvider, useSnackBar };
