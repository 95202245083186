import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container';
import {Autocomplete} from '@material-ui/lab';
import { MenuItem } from '@material-ui/core';
import Admin_Api from '../../API/Admin_Api';
import { useSnackBar } from '../../Context/Snackbar/withSnackbar';

function requestCallData(){
  const { showSnackBar } = useSnackBar();
const [months,setMonths]= useState('6')
const handleMonths=()=>{
    Admin_Api.Request_call_data(months)
    .then(response=>{
        showSnackBar(response.data.message);
    })
    .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
    
}
    return(
        <>
        <Container>
        <Typography component="div" className="rainn-lg-container centers-admin-panel">
            <Typography component="div" className="centers-admin-header">
                <Typography component="div" className="centers-admin-header-top-bar">
                    <Typography component="h3" className="centers-admin-section-text">Download Call Data</Typography>
                </Typography>
            </Typography>
            <Typography component="div" className="pt-6 pb-12">
                <Typography component="p" className="text-pc-1 text-2xl">Click the button below to download data on the number of National Sexual Assault Hotline calls routed to your organization.</Typography>
            </Typography>
            <Grid container spacing={2} className="bg-tc-7 p-3">
                <Grid item xs={12}>
                    <Typography component="h3" className="centers-admin-section-text">Download Call Data</Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                <TextField
                          name="months"
                          select
                          className="centers-admin-autocomplete"
                          value={months}
                          required
                          onChange={(e)=>setMonths(e.target.value)}
                        >
                              <MenuItem value="6">6 months</MenuItem>
                              <MenuItem value="12">12 months</MenuItem>
                              <MenuItem value="18">18 months</MenuItem>

                        </TextField>
                </Grid>
                <Grid item xs={12}>
                    <Button className="button-primary" disabled={months === ''} onClick={handleMonths}>Request Data</Button>
                </Grid>
            </Grid>
        </Typography>
        </Container>
        </>
    );
}

export default requestCallData