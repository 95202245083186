import React from 'react';
import VolunteerListData from '../../../component/Admin/volunteerReqSearch';
import Layout from '../../../component/Admin/Layout/Layout';



const VolunteerList: React.FunctionComponent = () => {

    return (
      <Layout>
        <VolunteerListData/>
      </Layout>
  );
}

export default VolunteerList;