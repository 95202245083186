import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {
  MdAdd,
  MdDelete,
  MdModeEdit,
  MdRemove,
  MdSearch,
} from 'react-icons/md';
import Api from '../../API/Admin_Api';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { useSnackBar } from '../../Context/Snackbar/withSnackbar';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      // borderBottom: 'unset',
    },
  },
});
interface Category {
  id: number;
  name: Name;
  options?: (OptionsCategory | null)[] | null;
}
interface Name {
  en: string;
  es: string;
}
interface OptionsCategory {
  id: number;
  name: Name;
}

const CategoryRow = (props: {
  row: Category;
  deleteCategoryHandler: (id: number) => void;
  deleteSubCategoryHandler: (id: number) => void;
}) => {
  const { row, deleteCategoryHandler, deleteSubCategoryHandler } = props;
  const [open, setOpen] = React.useState(false);

  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell className="centers-admin-table-title">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <MdRemove /> : <MdAdd />}
          </IconButton>
          {row.name.en}
        </TableCell>
        <TableCell className="w-32">
          <span className="flex items-center">
            <Link to={`category_edit/${row.id}`}>
              <MdModeEdit title="edit" className="centers-admin-table-icon" />
            </Link>
            <MdDelete
              title="delete"
              className="centers-admin-table-icon"
              onClick={() => deleteCategoryHandler(row.id)}
            />
          </span>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table>
              <TableBody>
                {row.options &&
                  row.options.map((optionsRow: any) => (
                    <TableRow key={optionsRow.id}>
                      <TableCell>{optionsRow.name.en}</TableCell>
                      <TableCell className="t-w-118">
                        <span className="flex items-center">
                          <Link to={`subcategory_edit/${optionsRow.id}`}>
                            <MdModeEdit className="centers-admin-table-icon" />
                          </Link>
                          <MdDelete
                            className="centers-admin-table-icon"
                            onClick={() =>
                              deleteSubCategoryHandler(optionsRow.id)
                            }
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const categoryListComponent = () => {
  const { showSnackBar } = useSnackBar();
  const [categoryListData, setCategoryListData] = React.useState<Category[]>(
    []
  );
  const [loading, setLoading] = React.useState(true);
  const [emptyData, setemptyData] = React.useState(false);

  const getCategoryListAPI = () => {
    Api.getCategoryList()
      .then((response: any) => {
        if (response.data && response.data.success) {
          setLoading(false);
          if (!response.data.data.length) {
            setemptyData(true);
          } else {
            setCategoryListData(response.data.data);
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  };

  const deleteCategoryClicked = (id: number) => {
    Api.deleteCategoryList(id)
      .then((response: any) => {
        if (response.data && response.data.success) {
          showSnackBar(response.data.message);
          getCategoryListAPI();
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  };

  const deleteSubCategoryClicked = (id: number) => {
    Api.deleteSubCategoryList(id)
      .then((response: any) => {
        if (response.data && response.data.success) {
          showSnackBar(response.data.message);
          getCategoryListAPI();
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  };

  useEffect(() => {
    getCategoryListAPI();
  }, []);

  return (
    <Container>
      <Typography
        component="div"
        className="rainn-lg-container centers-admin-panel"
      >
        <Typography
          component="div"
          className="flex justify-between items-center py-5 border-b border-tc-6"
        >
          <Typography component="h1" className="centers-admin-section-text">
            Category List
          </Typography>
          <Link to="/category_add">
            <Button className="button-primary">Add</Button>
          </Link>
        </Typography>
        {emptyData ? (
          <Typography component="div" className="text-center pt-10 pb-10">
            No Data Found
          </Typography>
        ) : (
          <Typography component="div" className="centers-admin-table">
            <TableContainer>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell>Category Name</TableCell>
                    <TableCell className="w-32">Action</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <CircularProgress disableShrink />
                ) : (
                  <TableBody>
                    {categoryListData.map((row) => (
                      <CategoryRow
                        key={row.id}
                        row={row}
                        deleteCategoryHandler={deleteCategoryClicked}
                        deleteSubCategoryHandler={deleteSubCategoryClicked}
                      />
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Typography>
        )}
      </Typography>
    </Container>
  );
};

export default categoryListComponent;
