import React, { useEffect, useRef, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Link, useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { Autocomplete } from '@material-ui/lab';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MdInfoOutline,
  MdNavigateNext,
  MdFileUpload,
  MdDelete,
} from 'react-icons/md';
import Api from '../../API/Api';
import { Avatar, CircularProgress, MenuItem, Tooltip } from '@material-ui/core';
import Admin_Api from '../../API/Admin_Api';
import Layout from './Layout/Layout';
import { useSnackBar } from '../../Context/Snackbar/withSnackbar';

const steps = [
  'Organization’s Contact Information',
  'Hotline Information',
  'Information on Services',
  'Staff Training',
  'Collaboration with the State Coalition',
  'RAINN Confidentiality Policy',
  'RAINN Non-Discrimination Policy',
  'Affiliate Terms of Service',
];
interface StepProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function StepContent(props: StepProps) {
  const { children, value, index, ...other } = props;
  return (
    <Typography component="div" role="step" hidden={value !== index} {...other}>
      {value == index && <>{children}</>}
    </Typography>
  );
}

function NewCenter(props: any) {
  const { showSnackBar } = useSnackBar();
  const divWrapper = useRef<HTMLInputElement>(null);

  const entryInput = {
    data: {
      categories: [
        {
          name: { en: '', es: '' },
          options: [
            {
              id: '',
              name: {
                en: '',
                es: '',
              },
            },
          ],
        },
      ],
      states: [],
      center_count: [],
    },
  };
  const history = useHistory();
  const [formFieldInfo, setformFieldInfo] = useState(entryInput);
  const [classState, classToggle] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [id, setId] = useState(props.match.params.id);
  const [image, setImageUrl] = useState<any>('');
  const scrollTop = () => {
    if (divWrapper.current) {
      divWrapper?.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    }
  };

  const nextStep = () => {
    if (activeStep < 7) setActiveStep(activeStep + 1);
    scrollTop();
  };
  const backStep = () => {
    if (activeStep < 8) setActiveStep(activeStep - 1);
    scrollTop();
  };

  const classToggler = () => {
    classToggle(!classState);
  };

  const [startDate, setStartDate] = useState<Date | null>(null);

  const handleStartDate = (date: Date | null) => {
    setStartDate(date);
  };

  useEffect(() => {
    const getData = async () => {
      await Api.getFormData()
        .then((responce: any) => {
          if (responce.data && responce.data.success) {
            setformFieldInfo(responce.data);
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.data.message) {
              showSnackBar(error.response.data.message, 'error');
            } else if (error.response.data.error) {
              showSnackBar(error.response.data.error, 'error');
            } else if (error.response.data.errors) {
              showSnackBar(error.response.data.errors.join('\n'), 'error');
            }
          } else {
            showSnackBar(error.message, 'error');
          }
        });
    };

    getData();
    const getDataByID = async () => {
      if (id !== undefined) {
        const result = await Admin_Api.View_request(id)
          .then((responce: any) => {
            if (responce.data && responce.data.success) {
              showSnackBar(responce.data.message);
              const info = responce.data.data;
              setCenterInfo(info.center);
              setPrimaryContact(info.user);
              setInformationService(info.information_on_service);
              setHotlineInfo(info.hotline_info);
              setStaffTraining(info.staff_training);
              setCollaboration(info.collaboration_with_the_state_coalition);
              setConfidentiality(info.confidentiality_policy);
              setAffiliate(info.affiliate_terms_of_service);
              setDiscrimination(info.non_discrimination_policy);
              setwhyRainn(
                info.collaboration_with_the_state_coalition.why_rainn
              );
              const populations = info.collaboration_with_the_state_coalition.categories[0]?.options.map(
                (res: any) => res
              );
              setPopulation((populations as any | []) as any);
              const provider = info.collaboration_with_the_state_coalition.categories[1]?.options.map(
                (res: any) => res
              );
              setProvider((provider as any | []) as any);
              const violences = info.collaboration_with_the_state_coalition.categories[2]?.options.map(
                (res: any) => res
              );
              setViolence((violences as any | []) as any);

              const byteCharacters = atob(info?.logo);
              const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);

              const image = new Blob([byteArray], { type: 'image/jpeg' });
              setImageUrl(URL.createObjectURL(image));
              setLoading(false);
            }
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              if (error.response.data.message) {
                showSnackBar(error.response.data.message, 'error');
              } else if (error.response.data.error) {
                showSnackBar(error.response.data.error, 'error');
              } else if (error.response.data.errors) {
                showSnackBar(error.response.data.errors.join('\n'), 'error');
              }
            } else {
              showSnackBar(error.message, 'error');
            }
          });
      }
    };
    getDataByID();
  }, []);

  //initial state value
  const center = {
    address1: '',
    address2: '',
    business_phone: '',
    center_type_id: 0,
    city: '',
    county: '',
    fax: '',
    name: '',
    state_code: '',
    tty: '',
    website: '',
    zip: '',
    director_email: '',
    director_name: '',
  };
  const primary_contact = {
    email: '',
    first_name: '',
    last_name: '',
    title: '',
    phone: '',
    is_ar_manager: false,
    is_primary: false,
    is_volunteer: false,
  };
  const secondary_contact = {
    secondary_email: '',
    secondary_first_name: '',
    secondary_last_name: '',
    secondary_title: '',
    secondary_phone: '',
  };

  const hotline_info = {
    background_check: false,
    call_percentage: '',
    chat_service: false,
    chat_service_reason: '',
    hotline: '',
    hotline24x7: false,
    hotline24x7_reason: '',
    hotline_phone: '',
    mandatory_reporting_laws: false,
    operation_after_hours: '',
  };

  const information_on_service = {
    accommodations: '',
    counties_served: '',
    eligibility_requirements: '',
    male_survivors_service: '',
    services_for_deaf: '',
    services_in_other_language: '',
    services_in_spanish: '',
    specific_populations: '',
    transalator_detail: '',
  };

  const staff_training = {
    advocate_needs: false,
    advocate_needs_reason: '',
    diverse_needs: '',
    hours_provided: '',
    refresher_trainings: '',
    training_design: '',
    training_lgbq: '',
    training_mh: '',
    training_tn: '',
  };
  const collaboration_with_the_state_coalition = {
    compliance: '',
    other_org_affiliate: '',
    state_coalition_member: true,
    state_coalition_member_reason: '',
    state_coalition_member_since: '',
  };
  //add state
  const [errors, setErrors] = useState({} as any);
  const [population, setPopulation] = useState(([] as any | null) as any);
  const [Provider, setProvider] = useState(([] as any | null) as any);
  const [violence, setViolence] = useState(([] as any | null) as any);
  const [logo, setLogo] = useState(null);
  const [imgData, setImgData] = useState(null);
  const [centerInfo, setCenterInfo] = useState(center);
  const [primaryContact, setPrimaryContact] = useState([primary_contact]);
  const [secondaryContact, setSecondaryContact] = useState(secondary_contact);
  const [hotlineInfo, setHotlineInfo] = useState(hotline_info);
  const [remark, setRemark] = useState('');
  const [confidentiality_policy, setConfidentiality] = useState(false);
  const [non_discrimination_policy, setDiscrimination] = useState(false);
  const [affiliate_terms_of_service, setAffiliate] = useState(false);
  const [informationService, setInformationService] = useState(
    information_on_service
  );
  const [centerTypeId, setCenterTypeId] = useState('');
  const [staffTraining, setStaffTraining] = useState(staff_training);
  const [collaboration, setCollaboration] = useState(
    collaboration_with_the_state_coalition
  );
  const [why_rainn, setwhyRainn] = useState('');
  const [loading, setLoading] = useState(true);
  // handle state

  const handlelogo = (e: any) => {
    setLogo(e.target.files[0]);
    const reader: any = new FileReader();
    reader.addEventListener('load', () => {
      setImgData(reader.result);
    });
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleCenterInfo = (e: any) => {
    const { name, value } = e.target;
    setCenterInfo({
      ...centerInfo,
      [name]: value,
    });
  };

  const handlePrimaryInfo = (e: any, i: any) => {
    const { name, value, checked } = e.target;
    const list: any = [...primaryContact];
    if (
      name == 'is_primary' ||
      name == 'is_ar_manager' ||
      name == 'is_volunteer'
    ) {
      list[i][name] = checked as any;
    } else {
      list[i][name] = value as any;
    }
    setPrimaryContact(list);
  };

  const handleSecondaryInfo = (e: any) => {
    const { name, value } = e.target;
    setSecondaryContact({
      ...secondaryContact,
      [name]: value,
    });
  };

  const handleHotlineInfo = (e: any) => {
    const { name, value } = e.target;
    if (e.target.type === 'radio') {
      setHotlineInfo({
        ...hotlineInfo,
        [name]: value === 'true',
      });
    } else {
      setHotlineInfo({
        ...hotlineInfo,
        [name]: value,
      });
    }
  };

  const handleInformationState = (e: any) => {
    const { name, value } = e.target;
    setInformationService({
      ...informationService,
      [name]: value,
    });
  };

  const handleStaffTrainingState = (e: any) => {
    const { name, value } = e.target;
    if (e.target.type === 'radio') {
      const tempStaffTraining: any = { ...staffTraining };
      if (name === 'advocate_needs' && value === 'false') {
        tempStaffTraining.advocate_needs_reason = '';
        errors.advocate_needs_reason = '';
      }
      setStaffTraining({
        ...tempStaffTraining,
        [name]: value === 'true',
      });
    } else {
      setStaffTraining({
        ...staffTraining,
        [name]: value,
      });
    }
  };
  const handleCollaborationState = (e: any) => {
    const { name, value } = e.target;
    if (e.target.type === 'radio') {
      const tempCollaboration: any = { ...collaboration };
      if (name === 'state_coalition_member' && value === 'true') {
        tempCollaboration.state_coalition_member_reason = '';
        errors.state_coalition_member_reason = '';
      }
      setCollaboration({
        ...tempCollaboration,
        [name]: value === 'true',
      });
    } else {
      setCollaboration({
        ...collaboration,
        [name]: value,
      });
    }
  };

  const IsValid = () => {
    const isValid =
      centerTypeId && Object.values(errors).every((x) => x === '');

    return isValid;
  };

  //Approve and ddecline request handle

  function handleApprove(e: any) {
    e.preventDefault();
    Admin_Api.Approve_new_center_request(remark, id, centerTypeId)
      .then((responce: any) => {
        if (responce.data && responce.data.success) {
          showSnackBar(responce.data.message);
          history.push('/approve_request_list');
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  }

  function handleDecline(e: any) {
    e.preventDefault();
    Admin_Api.Decline_new_center_request(remark, id, centerTypeId)
      .then((responce: any) => {
        if (responce.data && responce.data.success) {
          showSnackBar(responce.data.message);
          history.push('/approve_request_list');
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  }

  const {
    name,
    zip,
    center_type_id,
    state_code,
    business_phone,
    address1,
    address2,
    city,
    county,
    website,
    director_email,
    director_name,
  } = centerInfo;
  const {
    secondary_email,
    secondary_first_name,
    secondary_last_name,
    secondary_title,
    secondary_phone,
  } = secondaryContact;
  const {
    background_check,
    call_percentage,
    chat_service,
    hotline,
    hotline24x7,
    hotline_phone,
    hotline24x7_reason,
    mandatory_reporting_laws,
    chat_service_reason,
    operation_after_hours,
  } = hotlineInfo;
  const {
    accommodations,
    counties_served,
    eligibility_requirements,
    male_survivors_service,
    services_for_deaf,
    services_in_other_language,
    services_in_spanish,
    specific_populations,
    transalator_detail,
  } = informationService;
  const {
    advocate_needs,
    advocate_needs_reason,
    diverse_needs,
    hours_provided,
    refresher_trainings,
    training_design,
    training_lgbq,
    training_mh,
    training_tn,
  } = staffTraining;
  const {
    compliance,
    other_org_affiliate,
    state_coalition_member,
    state_coalition_member_since,
    state_coalition_member_reason,
  } = collaboration;

  return (
    <div ref={divWrapper}>
      <Layout>
        <Container>
          <Typography
            component="div"
            className="rainn-lg-container centers-admin-panel mb-10"
          >
            <Typography component="div" className="centers-admin-header">
              <Typography
                component="div"
                className="centers-admin-header-top-bar"
              >
                <Typography
                  component="h3"
                  className="centers-admin-section-text"
                >
                  Affiliate Application
                </Typography>
              </Typography>
              <Typography
                component="p"
                className="centers-admin-section-text fs-16 mt-5"
              >
                Please fill out the entire application and verify each section
                before submitting.
              </Typography>
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                className="my-5 centers-admin-stepper w-full"
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Typography>
            {loading ? (
              <Typography component="div" className="centers-loader">
                <CircularProgress disableShrink />
              </Typography>
            ) : (
              <>
                <StepContent value={activeStep} index={0}>
                  <Typography
                    component="div"
                    className="centers-admin-input-wrapper"
                  >
                    <Typography
                      component="div"
                      className="centers-admin-header"
                    >
                      <Typography
                        component="div"
                        className="centers-admin-header-top-bar px-6"
                      >
                        <Typography
                          component="h3"
                          className="centers-admin-section-text"
                        >
                          Organization’s Contact Information
                        </Typography>
                      </Typography>
                    </Typography>
                    <Grid container spacing={3} className="px-6 py-6 pt-3">
                      <Grid item xs={12}>
                        <TextField
                          className="centers-admin-input"
                          label="Organization Name"
                          name="name"
                          disabled
                          required
                          error={errors.name}
                          {...(errors.name && {
                            error: true,
                            helperText: errors.name,
                          })}
                          value={name}
                          onChange={handleCenterInfo}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          className="centers-admin-input"
                          label="Address"
                          name="address1"
                          disabled
                          required
                          error={errors.address1}
                          {...(errors.address1 && {
                            error: true,
                            helperText: errors.address1,
                          })}
                          value={address1}
                          onChange={handleCenterInfo}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          className="centers-admin-input"
                          label="Enter Address #2 here"
                          name="address2"
                          disabled
                          required
                          error={errors.address2}
                          {...(errors.address2 && {
                            error: true,
                            helperText: errors.address2,
                          })}
                          value={address2}
                          onChange={handleCenterInfo}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          className="centers-admin-input"
                          label="City"
                          name="city"
                          disabled
                          required
                          error={errors.city}
                          {...(errors.city && {
                            error: true,
                            helperText: errors.city,
                          })}
                          value={city}
                          onChange={handleCenterInfo}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          name="state_code"
                          select
                          label="Select state"
                          id="demo-simple-select"
                          className="centers-admin-autocomplete"
                          disabled
                          value={state_code}
                          onChange={handleCenterInfo}
                        >
                          {formFieldInfo?.data.states.map((res: any) => {
                            return (
                              <MenuItem value={res.code}>{res.name}</MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          className="centers-admin-input"
                          label="Enter zip code here"
                          type="number"
                          name="zip"
                          disabled
                          required
                          error={errors.zip}
                          {...(errors.zip && {
                            error: true,
                            helperText: errors.zip,
                          })}
                          value={zip}
                          onChange={handleCenterInfo}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <label
                          htmlFor="centers-admin-upload-button"
                          className="w-full label-input mb-1"
                        >
                          Click to Upload
                        </label>
                        <Avatar
                          src={image}
                          className="admin-avtar-sml"
                        ></Avatar>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          className="centers-admin-input"
                          label="Counties the organization serves"
                          name="county"
                          disabled
                          required
                          error={errors.county}
                          {...(errors.county && {
                            error: true,
                            helperText: errors.county,
                          })}
                          value={county}
                          onChange={handleCenterInfo}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          className="centers-admin-input"
                          label="Enter Business Phone Number"
                          type="number"
                          name="business_phone"
                          disabled
                          required
                          error={errors.business_phone}
                          {...(errors.business_phone && {
                            error: true,
                            helperText: errors.business_phone,
                          })}
                          value={business_phone}
                          onChange={handleCenterInfo}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          className="centers-admin-input"
                          label="Enter Organization Website"
                          name="website"
                          value={website}
                          disabled
                          required
                          error={errors.website}
                          {...(errors.website && {
                            error: true,
                            helperText: errors.website,
                          })}
                          onChange={handleCenterInfo}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          className="centers-admin-input"
                          label="Enter CEO/Executive Director’s Name"
                          name="director_name"
                          required
                          disabled
                          error={errors.director_name}
                          {...(errors.director_name && {
                            error: true,
                            helperText: errors.director_name,
                          })}
                          value={director_name}
                          onChange={handleCenterInfo}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          className="centers-admin-input"
                          label="Enter CEO/Executive Director’s Email Address"
                          type="email"
                          disabled
                          required
                          error={errors.director_email}
                          {...(errors.director_email && {
                            error: true,
                            helperText: errors.director_email,
                          })}
                          name="director_email"
                          value={director_email}
                          onChange={handleCenterInfo}
                        />
                      </Grid>
                    </Grid>
                    <Typography
                      component="h3"
                      className="centers-admin-section-text border-b border-tc-6 p-6"
                    >
                      Contact Information
                    </Typography>
                    {primaryContact?.map((x, i) => {
                      return (
                        <>
                          <Grid container spacing={3} className="px-6 py-6">
                            <Grid item md={6} xs={12}>
                              <TextField
                                className="centers-admin-input"
                                label="Enter first name here"
                                name="first_name"
                                disabled
                                required
                                error={errors.first_name}
                                {...(errors.first_name && {
                                  error: true,
                                  helperText: errors.first_name,
                                })}
                                value={x.first_name}
                                onChange={(e: any) => {
                                  handlePrimaryInfo(e, i);
                                }}
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField
                                className="centers-admin-input"
                                label="Enter last name here"
                                name="last_name"
                                disabled
                                required
                                error={errors.last_name}
                                {...(errors.last_name && {
                                  error: true,
                                  helperText: errors.last_name,
                                })}
                                value={x.last_name}
                                onChange={(e: any) => {
                                  handlePrimaryInfo(e, i);
                                }}
                              />
                            </Grid>

                            <Grid item md={6} xs={12}>
                              <TextField
                                className="centers-admin-input"
                                label="Enter Title here"
                                required
                                disabled
                                error={errors.title}
                                {...(errors.title && {
                                  error: true,
                                  helperText: errors.title,
                                })}
                                name="title"
                                value={x.title}
                                onChange={(e: any) => {
                                  handlePrimaryInfo(e, i);
                                }}
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField
                                type="number"
                                className="centers-admin-input"
                                label="Enter Contact Phone Number here"
                                name="phone"
                                disabled
                                value={x.phone}
                                required
                                error={errors.phone}
                                {...(errors.phone && {
                                  error: true,
                                  helperText: errors.phone,
                                })}
                                onChange={(e: any) => {
                                  handlePrimaryInfo(e, i);
                                }}
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField
                                className="centers-admin-input"
                                label="Enter Email Address here"
                                type="email"
                                name="email"
                                value={x.email}
                                disabled
                                required
                                error={errors.email}
                                {...(errors.email && {
                                  error: true,
                                  helperText: errors.email,
                                })}
                                onChange={(e: any) => {
                                  handlePrimaryInfo(e, i);
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={3} className="px-6">
                            <Grid item md={10} xs={12}>
                              <FormControlLabel
                                className="pr-5"
                                control={
                                  <Checkbox
                                    className="centers-admin-checkbox"
                                    name="is_primary"
                                    color="primary"
                                    disabled
                                    checked={x.is_primary}
                                    onChange={(e) => handlePrimaryInfo(e, i)}
                                  />
                                }
                                label="Primary Contact"
                              />
                              <FormControlLabel
                                className="pr-5"
                                control={
                                  <Checkbox
                                    className="centers-admin-checkbox"
                                    color="primary"
                                    name="is_ar_manager"
                                    disabled
                                    checked={x.is_ar_manager}
                                    onChange={(e) => handlePrimaryInfo(e, i)}
                                  />
                                }
                                label="Receive AR Manager Communication"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    className="centers-admin-checkbox"
                                    color="primary"
                                    name="is_volunteer"
                                    disabled
                                    checked={x.is_volunteer}
                                    onChange={(e) => handlePrimaryInfo(e, i)}
                                  />
                                }
                                label="Receive Volunteer Requests"
                              />
                            </Grid>
                          </Grid>
                          <div className="border-t border-tc-6 mt-6"></div>
                        </>
                      );
                    })}
                  </Typography>
                  <Typography component="div" className="my-8">
                    <Button
                      className="button-primary mr-4"
                      onClick={() => {
                        nextStep();
                      }}
                    >
                      Next
                    </Button>
                  </Typography>
                </StepContent>
                <StepContent value={activeStep} index={1}>
                  <Typography
                    component="div"
                    className="centers-admin-input-wrapper"
                  >
                    <Typography
                      component="div"
                      className="centers-admin-header"
                    >
                      <Typography
                        component="div"
                        className="centers-admin-header-top-bar px-6"
                      >
                        <Typography
                          component="h3"
                          className="centers-admin-section-text"
                        >
                          Hotline Information
                        </Typography>
                      </Typography>
                    </Typography>
                    <Grid container spacing={3} className="px-6 py-6">
                      <Grid item md={6} xs={12}>
                        <Typography component="p" className="label-input">
                          *Hotline Phone Number{' '}
                          <i className="text-tc-3">
                            (Please note: you must list a direct-dial number,not
                            a toll-free number. This is the number the NSAH will
                            be routed to.)
                          </i>
                        </Typography>
                        <TextField
                          className="centers-admin-input"
                          placeholder="Enter Hotline Phone Number"
                          type="number"
                          name="hotline_phone"
                          required
                          disabled
                          error={errors.hotline_phone}
                          {...(errors.hotline_phone && {
                            error: true,
                            helperText: errors.hotline_phone,
                          })}
                          value={hotline_phone}
                          onChange={handleHotlineInfo}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography component="p" className="label-input">
                          Toll-Free Hotline Number{' '}
                          <i className="text-tc-3">
                            (Please note: this number will be displayed on
                            theRAINN website, but we cannot route the NSAH
                            tothis number.)
                          </i>
                        </Typography>
                        <TextField
                          disabled
                          className="centers-admin-input"
                          type="number"
                          placeholder="Enter Toll-Free Hotline Number"
                          name="hotline"
                          required
                          error={errors.hotline}
                          {...(errors.hotline && {
                            error: true,
                            helperText: errors.hotline,
                          })}
                          value={hotline}
                          onChange={handleHotlineInfo}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography component="p" className="label-input">
                          What percentage of the hotline calls you recieve are
                          related to sexual assault?
                        </Typography>
                        <TextField
                          disabled
                          className="centers-admin-input"
                          type="number"
                          placeholder="Enter text here"
                          name="call_percentage"
                          required
                          error={errors.call_percentage}
                          {...(errors.call_percentage && {
                            error: true,
                            helperText: errors.call_percentage,
                          })}
                          value={call_percentage}
                          onChange={handleHotlineInfo}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography component="p" className="label-input">
                          Does the hotline operate 24 hours a day, 7 days aweek?
                        </Typography>
                        <Typography
                          component="div"
                          className="flex items-center mt-6"
                        >
                          <FormControl>
                            <RadioGroup
                              className="centers-admin-radio-group mr-7 flex-nowrap"
                              name="hotline24x7"
                              disabled
                              value={String(hotline24x7)}
                              onChange={handleHotlineInfo}
                              required
                              error={errors.hotline24x7}
                              {...(errors.hotline24x7 && {
                                error: true,
                                helperText: errors.hotline24x7,
                              })}
                            >
                              <FormControlLabel
                                value="true"
                                disabled
                                control={<Radio color="primary" />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="false"
                                disabled
                                control={<Radio color="primary" />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                          {hotline24x7 ? (
                            <TextField
                              disabled
                              className="centers-admin-input"
                              placeholder="Please describe here"
                              name="hotline24x7_reason"
                              value={hotline24x7_reason}
                              onChange={handleHotlineInfo}
                            />
                          ) : (
                            <></>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography component="p" className="label-input">
                          Does the organization provide chat or
                          web-basedservices?
                        </Typography>
                        <Typography
                          component="div"
                          className="flex items-center mt-6"
                        >
                          <FormControl>
                            <RadioGroup
                              className="centers-admin-radio-group mr-7 flex-nowrap"
                              name="chat_service"
                              value={String(chat_service)}
                              required
                              error={errors.chat_service}
                              {...(errors.chat_service && {
                                error: true,
                                helperText: errors.chat_service,
                              })}
                              onChange={handleHotlineInfo}
                            >
                              <FormControlLabel
                                disabled
                                value="true"
                                control={<Radio color="primary" />}
                                label="Yes"
                              />
                              <FormControlLabel
                                disabled
                                value="false"
                                control={<Radio color="primary" />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                          {chat_service ? (
                            <TextField
                              className="centers-admin-input"
                              placeholder="Please describe here"
                              disabled
                              name="chat_service_reason"
                              value={chat_service_reason}
                              onChange={handleHotlineInfo}
                            />
                          ) : (
                            <></>
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={3}
                        xs={12}
                        className="flex flex-col justify-between"
                      >
                        <Typography component="p" className="label-input">
                          Are criminal background checks conducted on all staff
                          and volunteers?
                        </Typography>
                        <Typography component="div">
                          <FormControl>
                            <RadioGroup
                              className="centers-admin-radio-group"
                              name="background_check"
                              required
                              error={errors.background_check}
                              {...(errors.background_check && {
                                error: true,
                                helperText: errors.background_check,
                              })}
                              value={String(background_check)}
                              onChange={handleHotlineInfo}
                            >
                              <FormControlLabel
                                disabled
                                value="true"
                                control={<Radio color="primary" />}
                                label="Yes"
                              />
                              <FormControlLabel
                                disabled
                                value="false"
                                control={<Radio color="primary" />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={3}
                        xs={12}
                        className="flex flex-col justify-between"
                      >
                        <Typography component="p" className="label-input">
                          Does the organization have a written policy stating
                          compliance with states’ mandatory reporting laws?
                        </Typography>
                        <Typography component="div">
                          <FormControl>
                            <RadioGroup
                              className="centers-admin-radio-group"
                              name="mandatory_reporting_laws"
                              required
                              error={errors.mandatory_reporting_laws}
                              {...(errors.mandatory_reporting_laws && {
                                error: true,
                                helperText: errors.mandatory_reporting_laws,
                              })}
                              value={String(mandatory_reporting_laws)}
                              onChange={handleHotlineInfo}
                            >
                              <FormControlLabel
                                disabled
                                value="true"
                                control={<Radio color="primary" />}
                                label="Yes"
                              />
                              <FormControlLabel
                                disabled
                                value="false"
                                control={<Radio color="primary" />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                  <Typography component="div" className="my-8">
                    <Button className="button-primary mr-4" onClick={backStep}>
                      Back
                    </Button>
                    <Button
                      className="button-primary mr-4"
                      onClick={() => {
                        nextStep();
                      }}
                    >
                      Next
                    </Button>
                  </Typography>
                </StepContent>
                <StepContent value={activeStep} index={2}>
                  <Typography
                    component="div"
                    className="centers-admin-input-wrapper"
                  >
                    <Typography
                      component="div"
                      className="centers-admin-header"
                    >
                      <Typography
                        component="div"
                        className="centers-admin-header-top-bar px-6"
                      >
                        <Typography
                          component="h3"
                          className="centers-admin-section-text"
                        >
                          Information on Services
                        </Typography>
                      </Typography>
                    </Typography>
                    <Grid container spacing={3} className="px-6 py-6">
                      <Grid item xs={12}>
                        <Typography component="p" className="label-input">
                          Are there any eligibility requirements for that
                          someone calling your hotline must meet to use your
                          services. Please describe in 1-2 sentences below.
                        </Typography>
                        <TextField
                          disabled
                          className="centers-admin-input"
                          placeholder="Enter text here"
                          name="eligibility_requirements"
                          required
                          error={errors.eligibility_requirements}
                          {...(errors.eligibility_requirements && {
                            error: true,
                            helperText: errors.eligibility_requirements,
                          })}
                          value={eligibility_requirements}
                          onChange={handleInformationState}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component="p" className="label-input">
                          Does your organization provide services to male
                          survivors? Please describe.
                        </Typography>
                        <TextField
                          disabled
                          className="centers-admin-input"
                          placeholder="Enter text here"
                          name="male_survivors_service"
                          required
                          error={errors.male_survivors_service}
                          {...(errors.male_survivors_service && {
                            error: true,
                            helperText: errors.male_survivors_service,
                          })}
                          value={male_survivors_service}
                          onChange={handleInformationState}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component="p" className="label-input">
                          Are there any specific populations that your advocates
                          have expertise in assisting?
                        </Typography>
                        <TextField
                          disabled
                          className="centers-admin-input"
                          placeholder="Enter text here"
                          name="specific_populations"
                          required
                          error={errors.specific_populations}
                          {...(errors.specific_populations && {
                            error: true,
                            helperText: errors.specific_populations,
                          })}
                          value={specific_populations}
                          onChange={handleInformationState}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component="p" className="label-input">
                          What services do you offer in Spanish?
                        </Typography>
                        <TextField
                          disabled
                          className="centers-admin-input"
                          placeholder="Enter text here"
                          name="services_in_spanish"
                          required
                          error={errors.services_in_spanish}
                          {...(errors.services_in_spanish && {
                            error: true,
                            helperText: errors.services_in_spanish,
                          })}
                          value={services_in_spanish}
                          onChange={handleInformationState}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component="p" className="label-input">
                          Do you offer services in any other language? If yes,
                          please describe.
                        </Typography>
                        <TextField
                          disabled
                          className="centers-admin-input"
                          placeholder="Enter text here"
                          name="services_in_other_language"
                          required
                          error={errors.services_in_other_language}
                          {...(errors.services_in_other_language && {
                            error: true,
                            helperText: errors.services_in_other_language,
                          })}
                          value={services_in_other_language}
                          onChange={handleInformationState}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component="p" className="label-input">
                          Are translators available over the phone and/or in
                          person? Please describe
                        </Typography>
                        <TextField
                          disabled
                          className="centers-admin-input"
                          placeholder="Enter text here"
                          name="transalator_detail"
                          required
                          error={errors.transalator_detail}
                          {...(errors.transalator_detail && {
                            error: true,
                            helperText: errors.transalator_detail,
                          })}
                          value={transalator_detail}
                          onChange={handleInformationState}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component="p" className="label-input">
                          What kinds of accommodations is your center able to
                          make for survivors with disabilities? Please describe.
                        </Typography>
                        <TextField
                          disabled
                          className="centers-admin-input"
                          placeholder="Enter text here"
                          name="accommodations"
                          required
                          error={errors.accommodations}
                          {...(errors.accommodations && {
                            error: true,
                            helperText: errors.accommodations,
                          })}
                          value={accommodations}
                          onChange={handleInformationState}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component="p" className="label-input">
                          What services are available for deaf and hard of
                          hearing survivors?
                        </Typography>
                        <TextField
                          disabled
                          className="centers-admin-input"
                          placeholder="Enter text here"
                          name="services_for_deaf"
                          required
                          error={errors.services_for_deaf}
                          {...(errors.services_for_deaf && {
                            error: true,
                            helperText: errors.services_for_deaf,
                          })}
                          value={services_for_deaf}
                          onChange={handleInformationState}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component="p" className="label-input">
                          Please list all of the counties you serve
                        </Typography>
                        <TextField
                          disabled
                          className="centers-admin-input"
                          placeholder="Enter text here"
                          name="counties_served"
                          required
                          error={errors.counties_served}
                          {...(errors.counties_served && {
                            error: true,
                            helperText: errors.counties_served,
                          })}
                          value={counties_served}
                          onChange={handleInformationState}
                        />
                      </Grid>
                    </Grid>
                  </Typography>
                  <Typography component="div" className="my-8">
                    <Button className="button-primary mr-4" onClick={backStep}>
                      Back
                    </Button>
                    <Button
                      className="button-primary mr-4"
                      onClick={() => {
                        nextStep();
                      }}
                    >
                      Next
                    </Button>
                  </Typography>
                </StepContent>
                <StepContent value={activeStep} index={3}>
                  <Typography
                    component="div"
                    className="centers-admin-input-wrapper"
                  >
                    <Typography
                      component="div"
                      className="centers-admin-header"
                    >
                      <Typography
                        component="div"
                        className="centers-admin-header-top-bar px-6"
                      >
                        <Typography
                          component="h3"
                          className="centers-admin-section-text"
                        >
                          Staff Training
                        </Typography>
                      </Typography>
                    </Typography>
                    <Grid container spacing={3} className="px-6 py-6">
                      <Grid item md={9} xs={12}>
                        <Typography component="p" className="label-input">
                          How does the organization train their staff and
                          volunteer counselors/advocates to meet the diverse
                          needs of sexual assault survivors?
                        </Typography>
                        <TextField
                          disabled
                          className="centers-admin-input"
                          placeholder="Enter text here"
                          name="diverse_needs"
                          required
                          error={errors.diverse_needs}
                          {...(errors.diverse_needs && {
                            error: true,
                            helperText: errors.diverse_needs,
                          })}
                          value={diverse_needs}
                          onChange={handleStaffTrainingState}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Typography component="p" className="label-input">
                          *How many hours are required/provided?
                        </Typography>
                        <TextField
                          disabled
                          className="centers-admin-input"
                          placeholder="Enter text here"
                          name="hours_provided"
                          value={hours_provided}
                          required
                          error={errors.hours_provided}
                          {...(errors.hours_provided && {
                            error: true,
                            helperText: errors.hours_provided,
                          })}
                          onChange={handleStaffTrainingState}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component="p" className="label-input">
                          Did a mental health professional design the training?
                        </Typography>
                        <TextField
                          disabled
                          className="centers-admin-input"
                          placeholder="Enter text here"
                          name="training_design"
                          value={training_design}
                          required
                          error={errors.training_design}
                          {...(errors.training_design && {
                            error: true,
                            helperText: errors.training_design,
                          })}
                          onChange={handleStaffTrainingState}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component="p" className="label-input">
                          Does your center require advocates to have specific
                          training in supporting survivors with disabilities?If
                          yes, please describe:
                        </Typography>
                        <Typography
                          component="div"
                          className="flex items-center"
                        >
                          <FormControl>
                            <RadioGroup
                              className="centers-admin-radio-group mr-2 flex-nowrap"
                              name="advocate_needs"
                              value={String(advocate_needs)}
                              onChange={handleStaffTrainingState}
                            >
                              <FormControlLabel
                                disabled
                                value="true"
                                control={<Radio color="primary" />}
                                label="Yes"
                              />
                              <FormControlLabel
                                disabled
                                value="false"
                                control={<Radio color="primary" />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                          {advocate_needs ? (
                            <TextField
                              disabled
                              className="centers-admin-input"
                              placeholder="Please describe here"
                              name="advocate_needs_reason"
                              value={advocate_needs_reason}
                              required={advocate_needs_reason === ''}
                              onChange={handleStaffTrainingState}
                            />
                          ) : (
                            <></>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component="p" className="label-input">
                          Please describe any staff/volunteer training that
                          addresses the needs of lesbian, gay, bisexual, and
                          queer sexual assault survivors
                        </Typography>
                        <TextField
                          disabled
                          className="centers-admin-input"
                          placeholder="Enter text here"
                          name="training_lgbq"
                          required
                          error={errors.training_lgbq}
                          {...(errors.training_lgbq && {
                            error: true,
                            helperText: errors.training_lgbq,
                          })}
                          value={training_lgbq}
                          onChange={handleStaffTrainingState}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component="p" className="label-input">
                          Please describe any staff/volunteer training that
                          addresses the needs of transgender and non-binary
                          sexual assault survivors
                        </Typography>
                        <TextField
                          disabled
                          className="centers-admin-input"
                          placeholder="Enter text here"
                          name="training_tn"
                          required
                          error={errors.training_tn}
                          {...(errors.training_tn && {
                            error: true,
                            helperText: errors.training_tn,
                          })}
                          value={training_tn}
                          onChange={handleStaffTrainingState}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component="p" className="label-input">
                          Please describe any staff/volunteer training that
                          addresses the needs of sexual assault survivors with
                          mental health conditions
                        </Typography>
                        <TextField
                          disabled
                          className="centers-admin-input"
                          placeholder="Enter text here"
                          name="training_mh"
                          value={training_mh}
                          required
                          error={errors.training_mh}
                          {...(errors.training_mh && {
                            error: true,
                            helperText: errors.training_mh,
                          })}
                          onChange={handleStaffTrainingState}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component="p" className="label-input">
                          How often do you require staff to complete continuing
                          education or refresher trainings? Please describe.
                        </Typography>
                        <TextField
                          disabled
                          className="centers-admin-input"
                          placeholder="Enter text here"
                          name="refresher_trainings"
                          required
                          error={errors.refresher_trainings}
                          {...(errors.refresher_trainings && {
                            error: true,
                            helperText: errors.refresher_trainings,
                          })}
                          value={refresher_trainings}
                          onChange={handleStaffTrainingState}
                        />
                      </Grid>
                    </Grid>
                  </Typography>
                  <Typography component="div" className="my-8">
                    <Button className="button-primary mr-4" onClick={backStep}>
                      Back
                    </Button>
                    <Button
                      className="button-primary mr-4"
                      onClick={() => {
                        nextStep();
                      }}
                    >
                      Next
                    </Button>
                  </Typography>
                </StepContent>
                <StepContent value={activeStep} index={4}>
                  <Typography
                    component="div"
                    className="centers-admin-input-wrapper"
                  >
                    <Typography
                      component="div"
                      className="centers-admin-header"
                    >
                      <Typography
                        component="div"
                        className="centers-admin-header-top-bar px-6"
                      >
                        <Typography
                          component="h3"
                          className="centers-admin-section-text"
                        >
                          Collaboration with the State Coalition
                        </Typography>
                      </Typography>
                    </Typography>
                    <Grid container spacing={3} className="px-6 py-6">
                      <Grid item xs={12}>
                        <Typography component="p" className="label-input">
                          Is the organization a member of the state coalition?
                          If no, please explain.
                        </Typography>
                        <Typography
                          component="div"
                          className="flex items-center"
                        >
                          <FormControl>
                            <RadioGroup
                              className="centers-admin-radio-group mr-2 flex-nowrap"
                              name="state_coalition_member"
                              required
                              error={errors.state_coalition_member}
                              {...(errors.state_coalition_member && {
                                error: true,
                                helperText: errors.state_coalition_member,
                              })}
                              value={String(state_coalition_member)}
                              onChange={handleCollaborationState}
                            >
                              <FormControlLabel
                                disabled
                                value="true"
                                control={<Radio color="primary" />}
                                label="Yes"
                              />
                              <FormControlLabel
                                disabled
                                value="false"
                                control={<Radio color="primary" />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                          {!state_coalition_member ? (
                            <TextField
                              disabled
                              className="centers-admin-input"
                              placeholder="Please describe here"
                              name="state_coalition_member_reason"
                              value={state_coalition_member_reason}
                              onChange={handleCollaborationState}
                            />
                          ) : (
                            <></>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component="p" className="label-input">
                          How long has the organization been a member of the
                          state coalition?
                        </Typography>
                        <TextField
                          disabled
                          className="centers-admin-input"
                          placeholder="Enter text here"
                          name="state_coalition_member_since"
                          required
                          error={errors.state_coalition_member_since}
                          {...(errors.state_coalition_member_since && {
                            error: true,
                            helperText: errors.state_coalition_member_since,
                          })}
                          value={state_coalition_member_since}
                          onChange={handleCollaborationState}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component="p" className="label-input">
                          Is the organization in compliance with state, county,
                          and local statutes and regulations governing your
                          operations?
                        </Typography>
                        <TextField
                          disabled
                          className="centers-admin-input"
                          placeholder="Enter text here"
                          name="compliance"
                          value={compliance}
                          required
                          error={errors.compliance}
                          {...(errors.compliance && {
                            error: true,
                            helperText: errors.compliance,
                          })}
                          onChange={handleCollaborationState}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component="p" className="label-input">
                          Is the organization affiliated or certified by any
                          other organizations or accrediting bodies, such as the
                          National Children’s Alliance or Tribal Coalitions?
                        </Typography>
                        <TextField
                          disabled
                          className="centers-admin-input"
                          placeholder="Enter text here"
                          name="other_org_affiliate"
                          value={other_org_affiliate}
                          required
                          error={errors.other_org_affiliate}
                          {...(errors.other_org_affiliate && {
                            error: true,
                            helperText: errors.other_org_affiliate,
                          })}
                          onChange={handleCollaborationState}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography component="p" className="label-input">
                          Are the organizations staff/volunteers trained to
                          provide services to any of the following populations?
                        </Typography>
                        <Autocomplete
                          multiple
                          disableClearable
                          disabled
                          clearOnEscape
                          options={formFieldInfo.data.categories[0]?.options}
                          getOptionLabel={(option: any) => {
                            return option?.name?.en;
                          }}
                          value={population}
                          onChange={(event, newInput) => {
                            if (newInput !== null) {
                              setPopulation(newInput as any);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              disabled
                              className="centers-admin-autocomplete"
                              placeholder="Select"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Autocomplete
                          multiple
                          limitTags={2}
                          id="violence"
                          disabled
                          options={formFieldInfo.data.categories[2]?.options}
                          getOptionLabel={(option) => option.name?.en}
                          value={violence}
                          onChange={(event, newInput) => {
                            if (newInput !== null) {
                              setViolence(newInput as any);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="centers-admin-autocomplete md:w-1/2"
                              disabled
                              required
                              label={`${formFieldInfo.data.categories[2].name.en}`}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography component="p" className="label-input">
                          Please indicate below what services are offered by
                          your organization
                        </Typography>
                        <Autocomplete
                          multiple
                          disableClearable
                          disabled
                          clearOnEscape
                          options={formFieldInfo.data.categories[1]?.options}
                          getOptionLabel={(option: any) => {
                            return option?.name?.en;
                          }}
                          value={Provider}
                          onChange={(event, newInput) => {
                            if (newInput !== null) {
                              setProvider(newInput as any);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              disabled
                              className="centers-admin-autocomplete"
                              placeholder="Select"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component="p" className="label-input">
                          We are excited about your interest in affiliating with
                          RAINN. Below, please share with us why you are
                          interested in partnering with RAINN and how RAINN can
                          assist the organization's efforts to support survivors
                          of sexual violence
                        </Typography>
                        <TextField
                          className="centers-admin-input"
                          placeholder="Enter text here"
                          disabled
                          name="why_rainn"
                          value={why_rainn}
                          onChange={(e) => {
                            setwhyRainn(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Typography>
                  <Typography component="div" className="my-8">
                    <Button className="button-primary mr-4" onClick={backStep}>
                      Back
                    </Button>
                    <Button
                      className="button-primary mr-4"
                      onClick={() => {
                        nextStep();
                      }}
                    >
                      Next
                    </Button>
                  </Typography>
                </StepContent>
                <StepContent value={activeStep} index={5}>
                  <Typography
                    component="div"
                    className="centers-admin-input-wrapper"
                  >
                    <Typography
                      component="div"
                      className="centers-admin-header"
                    >
                      <Typography
                        component="div"
                        className="centers-admin-header-top-bar px-6"
                      >
                        <Typography
                          component="h3"
                          className="centers-admin-section-text"
                        >
                          RAINN Confidentiality Policy
                        </Typography>
                      </Typography>
                    </Typography>
                    <Grid container spacing={3} className="px-6 py-6">
                      <Grid item xs={12}>
                        <Typography
                          component="p"
                          className="text-pc-1 text-2xl mb-2"
                        >
                          Obligations of RAINN
                        </Typography>
                        <Typography component="p" className="text-lg text-tc-3">
                          RAINN commits to making every effort to ensure the
                          anonymity of every caller to its hotline.When a call
                          is placed to RAINN’s hotline, only the area code and
                          exchange of the caller will becaptured. The caller’s
                          phone number will not be captured.
                          <br />
                          <br />
                          It is RAINN’s expectation that, because we do not
                          retain the phone numbers of callers, ourrecords will
                          be of little interest to court proceedings.
                          Nevertheless, should our call records besubpoenaed,
                          RAINN commits to using legal means at our disposal to
                          fight such a subpoena.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          component="p"
                          className="text-pc-1 text-2xl mb-2"
                        >
                          Obligations of participating rape crisis centers
                        </Typography>
                        <Typography component="p" className="text-lg text-tc-3">
                          RAINN expects that participating centers will respect
                          callers’ rights to confidentiality and will adhere to
                          a confidentiality policy that includes the following
                          items.
                        </Typography>
                        <List className="centers-admin-section-list">
                          <ListItem>
                            <ListItemIcon>
                              <MdNavigateNext />
                            </ListItemIcon>
                            <ListItemText>
                              A caller will never be pressured into revealing
                              their identity.
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <MdNavigateNext />
                            </ListItemIcon>
                            <ListItemText>
                              Victim records will not be released without the
                              consent of the victim, except when a center is
                              obligated by law.
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <MdNavigateNext />
                            </ListItemIcon>
                            <ListItemText>
                              Reports of the assault will be made to police or
                              other agencies only with the client’s consent,
                              except when a center is obligated by law to report
                              the attack.
                            </ListItemText>
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          component="div"
                          className="bg-tc-7 p-5 rounded"
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Typography
                                component="h3"
                                className="text-pc-1 font-bold fs-22"
                              >
                                <Checkbox
                                  name="confidentiality_policy"
                                  value={confidentiality_policy}
                                  checked={confidentiality_policy}
                                  onChange={(e) => {
                                    setConfidentiality(e.target.checked);
                                  }}
                                  disabled
                                  color="primary"
                                  inputProps={{
                                    'aria-label': 'primary checkbox',
                                  }}
                                />
                                I affirm that, as an affiliate organization, my
                                organization will adhere to the confidentiality
                                policy above.
                              </Typography>
                            </Grid>
                            <Grid item md={8} xs={12}>
                              <TextField
                                className="centers-admin-input"
                                label="Executive Director/CEO’s Initials"
                                name="director_name"
                                value={director_name}
                                disabled
                                placeholder="Enter text here"
                              />
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  format="MM/dd/yyyy"
                                  margin="normal"
                                  id="start-date-picker"
                                  label="Select Date"
                                  disabled={true}
                                  value={new Date()}
                                  onChange={handleStartDate}
                                  className="centers-admin-date-picker"
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                  <Typography component="div" className="my-8">
                    <Button className="button-primary mr-4" onClick={backStep}>
                      Back
                    </Button>
                    <Button className="button-primary mr-4" onClick={nextStep}>
                      Next
                    </Button>
                  </Typography>
                </StepContent>
                <StepContent value={activeStep} index={6}>
                  <Typography
                    component="div"
                    className="centers-admin-input-wrapper"
                  >
                    <Typography
                      component="div"
                      className="centers-admin-header"
                    >
                      <Typography
                        component="div"
                        className="centers-admin-header-top-bar px-6"
                      >
                        <Typography
                          component="h3"
                          className="centers-admin-section-text"
                        >
                          RAINN Non-Discrimination Policy
                        </Typography>
                      </Typography>
                    </Typography>
                    <Grid container spacing={3} className="px-6 py-6">
                      <Grid item xs={12}>
                        <Typography
                          component="p"
                          className="text-lg text-tc-3 "
                        >
                          RAINN and its affiliated centers shall not
                          discriminate against any individual for reasons of
                          race,color, creed, religion, sexual orientation,
                          gender identity, national origin, sex, age,
                          language,disability or political party identification.
                          Accordingly, equal access to employment
                          opportunitiesand services is extended to all persons.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          component="div"
                          className="bg-tc-7 p-5 rounded"
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Typography
                                component="h3"
                                className=" text-pc-1 font-bold fs-22"
                              >
                                <Checkbox
                                  name="non_discrimination_policy"
                                  value={non_discrimination_policy}
                                  checked={non_discrimination_policy}
                                  onChange={(e) => {
                                    setDiscrimination(e.target.checked);
                                  }}
                                  disabled
                                  color="primary"
                                  inputProps={{
                                    'aria-label': 'primary checkbox',
                                  }}
                                />
                                I affirm that, as an affiliate organization, my
                                organization will adhere to the
                                non-discrimination policy above.
                              </Typography>
                            </Grid>
                            <Grid item md={8} xs={12}>
                              <TextField
                                className="centers-admin-input"
                                label="Executive Director/CEO’s Initials"
                                placeholder="Enter text here"
                                name="director_name"
                                value={director_name}
                                disabled
                              />
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  format="MM/dd/yyyy"
                                  margin="normal"
                                  id="start-date-picker"
                                  label="Select Date"
                                  disabled={true}
                                  value={new Date()}
                                  onChange={handleStartDate}
                                  className="centers-admin-date-picker"
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                  <Typography component="div" className="my-8">
                    <Button className="button-primary mr-4" onClick={backStep}>
                      Back
                    </Button>
                    <Button className="button-primary mr-4" onClick={nextStep}>
                      Next
                    </Button>
                  </Typography>
                </StepContent>
                <StepContent value={activeStep} index={7}>
                  <Typography
                    component="div"
                    className="centers-admin-input-wrapper"
                  >
                    <Typography
                      component="div"
                      className="centers-admin-header"
                    >
                      <Typography
                        component="div"
                        className="centers-admin-header-top-bar px-6"
                      >
                        <Typography
                          component="h3"
                          className="centers-admin-section-text"
                        >
                          Affiliate Terms of Service
                        </Typography>
                      </Typography>
                    </Typography>
                    <Grid container spacing={3} className="px-6 py-6">
                      <Grid item xs={12}>
                        <Typography component="p" className="text-lg text-tc-3">
                          Below is an outline of what services RAINN offers
                          affiliate organizations and what RAINN requires their
                          affiliates provide to survivors of sexual violence.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          component="p"
                          className="text-pc-1 text-2xl"
                        >
                          RAINN will Provide to All Affiliates
                        </Typography>
                        <List className="centers-admin-section-list">
                          <ListItem>
                            <ListItemIcon>
                              <MdNavigateNext />
                            </ListItemIcon>
                            <ListItemText>
                              Promotion of the affiliate’s services: RAINN
                              offers the opportunity for affiliates to list
                              theirservices for victims and survivors and
                              volunteer opportunities on the RAINN website
                              (rainn.org).In addition RAINN partners with media
                              outlets to promote the National Sexual Assault
                              Hotline(NSAH).
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <MdNavigateNext />
                            </ListItemIcon>
                            <ListItemText>
                              Access to Promotional Materials: RAINN offers web
                              based promotional materials as needed.Affiliates
                              can also promote the NSAH in their local
                              promotion. Promoting this number ensuresan
                              affiliates ability to target survivors in their
                              region.
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <MdNavigateNext />
                            </ListItemIcon>
                            <ListItemText>
                              Hotline Reports: RAINN provides affiliates with
                              bi-annual reports detailing the number of call
                              srouted to the organization from the NSAH.
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <MdNavigateNext />
                            </ListItemIcon>
                            <ListItemText>
                              Access to support: The NSAH Affiliate Coordinator
                              is available to assist affiliates withconcerns and
                              questions related to local and national issues.
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <MdNavigateNext />
                            </ListItemIcon>
                            <ListItemText>
                              Access to online trainings: RAINN provides access
                              to online trainings for staff and volunteers.
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <MdNavigateNext />
                            </ListItemIcon>
                            <ListItemText>
                              Quality assurance of the NSAH: RAINN staff will
                              follow-up with all concerns expressed bycallers
                              and centers in a timely manner.
                            </ListItemText>
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          component="p"
                          className="text-pc-1 text-2xl"
                        >
                          Affiliates will
                        </Typography>
                        <List className="centers-admin-section-list">
                          <ListItem>
                            <ListItemIcon>
                              <MdNavigateNext />
                            </ListItemIcon>
                            <ListItemText>
                              Provide services via a 24-hour hotline: Affiliates
                              will ensure their hotline number is
                              answered24-hours a day, 7-days a week, 365 days a
                              year by trained paid or non-paid staff. Affiliates
                              agree to provide free, confidential hotline
                              services to all callers.
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <MdNavigateNext />
                            </ListItemIcon>
                            <ListItemText>
                              Provide services to survivors without
                              discrimination: Affiliates agree to adhere to
                              RAINN’sdiscrimination and confidentiality
                              policies.
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <MdNavigateNext />
                            </ListItemIcon>
                            <ListItemText>
                              Notify RAINN of changes to services: Affiliates
                              agree to provide RAINN with a 30-day notice ifthey
                              anticipate any change to their hotline services.
                            </ListItemText>
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          component="div"
                          className="bg-tc-7 p-5 rounded"
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Typography
                                component="h3"
                                className=" text-pc-1 font-bold fs-22"
                              >
                                By click on checkbox below and submitting this
                                application affirm that all answers state in
                                this application are true; that your
                                organization provides free, or low cost,
                                services to all survivors of sexual assault
                                without discrimination on any basis; and that
                                you will abide by the confidentialiy policy.
                              </Typography>
                            </Grid>
                            <Grid item md={8} xs={12}>
                              <TextField
                                className="centers-admin-input"
                                label="Executive Director/CEO’s Initials"
                                placeholder="Enter text here"
                                name="director_name"
                                value={director_name}
                                disabled
                              />
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  format="MM/dd/yyyy"
                                  margin="normal"
                                  id="start-date-picker"
                                  label="Select Date"
                                  disabled={true}
                                  value={new Date()}
                                  onChange={handleStartDate}
                                  className="centers-admin-date-picker"
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl>
                                <FormControlLabel
                                  disabled
                                  className="centers-admin-accept-terms"
                                  control={
                                    <Checkbox
                                      name="affiliate_terms_of_service"
                                      value={affiliate_terms_of_service}
                                      checked={affiliate_terms_of_service}
                                      onChange={(e) => {
                                        setAffiliate(e.target.checked);
                                      }}
                                      disabled
                                      color="primary"
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                    />
                                  }
                                  label="By checking the box you have hereby declare that the information provided is true and correct."
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} spacing={2}>
                        <FormControl>
                          <RadioGroup
                            name="centerTypeId"
                            className="centers-admin-radio-group"
                            value={centerTypeId}
                            required
                            error={errors.centerTypeId}
                            {...(errors.centerTypeId && {
                              error: true,
                              helperText: errors.centerTypeId,
                            })}
                            onChange={(e) => {
                              setCenterTypeId(e.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="2"
                              control={<Radio color="primary" />}
                              label="Affiliate Center"
                            />
                            <FormControlLabel
                              value="3"
                              control={<Radio color="primary" />}
                              label="Referral Center"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Remarks"
                          className="centers-admin-input"
                          name="remark"
                          value={remark}
                          onChange={(e) => setRemark(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </Typography>
                  <Typography component="div" className="my-8">
                    <Button className="button-primary mr-4" onClick={backStep}>
                      Back
                    </Button>
                    <Button
                      type="submit"
                      disabled={centerTypeId === '' ? true : false}
                      className="button-primary mr-3"
                      onClick={(e) => handleApprove(e)}
                    >
                      Approve
                    </Button>
                    <Button
                      type="submit"
                      className="button-primary mr-3"
                      onClick={(e) => handleDecline(e)}
                    >
                      Decline
                    </Button>
                  </Typography>
                </StepContent>
              </>
            )}
          </Typography>
        </Container>
      </Layout>
    </div>
  );
}

export default NewCenter;
