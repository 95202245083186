import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Api from '../../API/Admin_Api';
import { CircularProgress } from '@material-ui/core';
import {
  MdVisibility,
  MdModeEdit,
  MdUpdate,
  MdVisibilityOff,
  MdFileDownload,
  MdLockOutline,
  MdLockOpen,
} from 'react-icons/md';
import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import { useSnackBar } from '../../Context/Snackbar/withSnackbar';
import moment from 'moment';

interface Data {
  name: string;
  address: string;
  hotline: string;
  website: string;
  modified_at: string;
  modified_by: string;
  active: any;
  address1: 'string';
  address2: 'string';
  city: 'string';
  county: 'string';
  hotline_phone: 'string';
  id: 0;
  state_code: 'string';
  updatedAt: {
    date: 0;
    day: 0;
    hours: 0;
    minutes: 0;
    month: 0;
    nanos: 0;
    seconds: 0;
    time: 0;
    timezoneOffset: 0;
    year: 0;
  };
  updatedBy: 'string';
  center_type_id:'number';
  zip: 0;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';
interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'id', numeric: true, disablePadding: true, label: "ID" },
  { id: 'name', numeric: false, disablePadding: true, label: 'CENTER NAME' },
  { id: 'city', numeric: false, disablePadding: false, label: 'ADDRESS' },
  { id: 'hotline', numeric: false, disablePadding: false, label: 'HOTLINE' },
  { id: 'website', numeric: false, disablePadding: false, label: 'WEBSITE' },
  { id: 'center_type_id', numeric: false, disablePadding: false, label: 'CENTER TYPE' },
  {
    id: 'modified_at',
    numeric: false,
    disablePadding: false,
    label: 'UPDATED DATE',
  },
  {
    id: 'modified_by',
    numeric: false,
    disablePadding: false,
    label: 'UPDATED BY',
  },
  { id: 'active', numeric: false, disablePadding: false, label: 'ACTION' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            // className={headCell.id ? 'w-20' : ''}
            key={headCell.id}
            className={headCell.numeric ? 'w-20' : ''}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
);

export default function EnhancedTable(props: any) {
  const {
    centerName,
    state,
    zip,
    categoryIds,
    endDate,
    startDate,
    Clear,
    click,
    exclude,
  } = props;
  const { showSnackBar } = useSnackBar();
  const initial: any = [
    {
      id: '',
      name: '',
      address1: '',
      address2: '',
      city: '',
      county: '',
      zip: '',
      website: '',
      updatedAt: null,
      updatedBy: null,
      state_code: '',
      hotline_phone: '',
    },
  ];
  const classes = useStyles();
  const [rows, setRows] = useState(initial);
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('modified_at');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(true);
  const [emptyData, setemptyData] = React.useState(false);
  const [count, setCount] = useState(0);
  const [Status, setStatus] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  useEffect(() => {
    const getdata = async () => {
      await Api.center_list(
        page + 1,
        rowsPerPage,
        order,
        orderBy,
        centerName,
        categoryIds,
        zip,
        state,
        startDate,
        endDate,
        exclude
      )
        .then((response: any) => {
          if (response.data && response.data.success) {
            setLoading(false);
            setemptyData(false);
            if (!response.data.data.records.length) {
              setemptyData(true);
            } else {
              setRows(response.data.data.records);
              setCount(response.data.data.records_total);
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.data.message) {
              showSnackBar(error.response.data.message, 'error');
            } else if (error.response.data.error) {
              showSnackBar(error.response.data.error, 'error');
            } else if (error.response.data.errors) {
              showSnackBar(error.response.data.errors.join('\n'), 'error');
            }
          } else {
            showSnackBar(error.message, 'error');
          }
        });
    };
    if (Clear) {
      setPage(0);
      setLoading(true);
      setTimeout(() => {
        getdata();
      }, 700);
    } else {
      getdata();
    }
  }, [
    page,
    rowsPerPage,
    order,
    orderBy,
    centerName,
    Status,
    state,
    zip,
    categoryIds,
    endDate,
    startDate,
    exclude,
  ]);

  const handleExport = async () => {
    setExportLoading(true);
    await Api.export_center_list(
      page + 1,
      count,
      order,
      centerName,
      categoryIds,
      zip,
      state,
      startDate,
      endDate,
      exclude
    )
      .then((response: any) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/vnd.ms-excel' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'center_list_record.xls');
        document.body.appendChild(link);
        link.click();
        setExportLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const newSelecteds = rows?.map((n: { id: any }) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: any) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    click();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleActivate = (id: number) => {
    Api.center_Activate(id)
      .then((response: any) => {
        if (response.data && response.data.success) {
          showSnackBar(response.data.message);
          setStatus(!Status);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  };

  const handleDeactivate = (id: number) => {
    Api.center_Deactivate(id)
      .then((response: any) => {
        if (response.data && response.data.success) {
          showSnackBar(response.data.message);
          setStatus(!Status);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  };
  const handleReminder = async () => {
    setLoading(true);
    await Api.center_list_update_reminder(selected)
      .then((response: any) => {
        if (response.data && response.data.success) {
          setLoading(false);
          showSnackBar(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  };

  const handleOneUpdate = (id: any) => {
    Api.center_list_update_reminder([id])
      .then((response: any) => {
        if (response.data && response.data.success) {
          showSnackBar(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  };

  const isSelected = (id: any) => selected.indexOf(id) !== -1;
  return (
    <>
      <Typography component="div" className="flex justify-end">
        {emptyData ? (
          <></>
        ) : (
          <>
            <MdUpdate
              title="reminder"
              className="mx-2 my-1 text-tc-5 text-lg cursor-pointer hover:text-tc-3"
              onClick={handleReminder}
            />
            <Divider orientation="vertical" flexItem />
            {exportLoading ? (
              <CircularProgress disableShrink />
            ) : (
              <MdFileDownload
                title="export"
                className="mx-2 my-1 text-tc-5 text-lg cursor-pointer hover:text-tc-3"
                onClick={handleExport}
              />
            )}
          </>
        )}
      </Typography>
      {emptyData ? (
        <Typography component="div" className="text-center pt-10 pb-10">
          No Data Found
        </Typography>
      ) : (
        <Typography component="div" className="centers-admin-table">
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              {loading ? (
                <CircularProgress disableShrink />
              ) : (
                <TableBody>
                  {rows?.map((row: any, index: any) => {
                    const isItemSelected = isSelected(row.id as any);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id as any)}
                        className={row.active === false ? 'row-deactive' : ''}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                        <TableCell>{row.id}</TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.name}
                        </TableCell>
                        <TableCell>
                          {row.city} ,{row.state_code}
                          {row.zip}
                        </TableCell>
                        <TableCell>{row.hotline_phone}</TableCell>
                        <TableCell>{row.website}</TableCell>
                        <TableCell>{row.center_type_id === 2 ? 'Affiliate' :row.center_type_id === 3 ? 'Referral' : 'National'}</TableCell>
                        <TableCell>{row.modified_at}</TableCell>
                        <TableCell>{row.modified_by}</TableCell>
                        <TableCell>
                          <span className="flex items-center">
                            <MdUpdate
                              title="reminder"
                              className="centers-admin-table-icon"
                              onClick={() => handleOneUpdate(row.id)}
                            />
                            {row.active ? (
                              <MdLockOpen
                                title="active"
                                className="centers-admin-table-icon"
                                onClick={() => handleDeactivate(row.id)}
                              />
                            ) : (
                              <MdLockOutline
                                title="deactive"
                                className="centers-admin-table-icon"
                                onClick={() => handleActivate(row.id)}
                              />
                            )}
                            <Link to={`center_list_edit/${row.id}`}>
                              <MdModeEdit
                                title="edit"
                                className="centers-admin-table-icon"
                              />
                            </Link>
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Typography>
      )}
    </>
  );
}
