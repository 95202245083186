import React from 'react';
import UploadCallDataInfo from '../../../component/Admin/uploadCallData';
import Layout from '../../../component/Admin/Layout/Layout';



const UploadCallData: React.FunctionComponent = () => {

    return (
      <Layout>
        <UploadCallDataInfo/>
      </Layout>
  );
}

export default UploadCallData;