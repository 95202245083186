import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Container from '@material-ui/core/Container';
import { Link, useHistory } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import { MdDelete, MdKeyboardArrowRight } from 'react-icons/md';
import Admin_Api, { instance } from '../../API/Admin_Api';
import Api from '../../API/Api';
import Layout from './Layout/Layout';
import { useSnackBar } from '../../Context/Snackbar/withSnackbar';
import { MdArrowBack } from 'react-icons/md';
import axios from 'axios';
import { Tooltip } from '@material-ui/core';

function centerListAdd(props: any) {
  const { showSnackBar } = useSnackBar();

  const center = {
    hotline: '',
    address1: '',
    address2: '',
    business_phone: '',
    center_type_id: '2',
    city: '',
    county: '',
    fax: '',
    hotline_phone: '',
    name: '',
    state_code: '',
    tty: '',
    website: '',
    zip: '',
  };
  const contact = {
    email: '',
    first_name: '',
    last_name: '',
    title: '',
    phone: '',
    is_ar_manager: false,
    is_primary: false,
    is_volunteer: false,
  };
  const volunteer_contact = {
    volunteer_email: '',
    volunteer_first_name: '',
    volunteer_last_name: '',
    volunteer_title: '',
  };
  const entryInput = {
    data: {
      categories: [
        {
          name: { en: '', es: '' },
          options: [
            {
              id: '',
              name: {
                en: '',
                es: '',
              },
            },
          ],
        },
      ],
      states: [],
      center_count: [],
    },
  };
  const [formFieldInfo, setformFieldInfo] = useState(entryInput);
  const [errors, setErrors] = useState({} as any);
  const [centerInfo, setCenterInfo] = useState(center);
  const [Contact, setContact] = useState([contact]);
  const [volunteer, setvolunteer] = useState(volunteer_contact);
  const [States, setStates] = useState(null);
  const [StatesCode, setStatesCode] = useState('');
  const [remark, setRemark] = useState('');
  const [step1check, setStep1check] = useState(false);
  const [defaultOpen, setDefaultOpen] = useState(false);
  const [logocheck, setLogocheck] = useState(false);
  const [appno, setAppno] = useState('');
  const [logo, setLogo] = useState('');

  const [id, setId] = useState(props.match.params.id);

  useEffect(() => {
    const getData = async () => {
      await Api.getFormData()
        .then((responce: any) => {
          if (responce.data && responce.data.success) {
            setformFieldInfo(responce.data);
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.data.message) {
              showSnackBar(error.response.data.message, 'error');
            } else if (error.response.data.error) {
              showSnackBar(error.response.data.error, 'error');
            } else if (error.response.data.errors) {
              showSnackBar(error.response.data.errors.join('\n'), 'error');
            }
          } else {
            showSnackBar(error.message, 'error');
          }
        });

      if (id === undefined) {
        setCenterInfo(center);
        setContact([contact]);
        setvolunteer(volunteer_contact);
      } else {
        const result = await Admin_Api.center_list_view(id)
          .then((responce: any) => {
            if (responce.data && responce.data.success) {
              const info = responce.data.data;
              setCenterInfo({...info.center,"hotline_phone":info.hotline_info?.hotline_phone,"hotline":info.hotline_info?.hotline});
              setContact(info.user);
              const checkedIds1 =
                info.collaboration_with_the_state_coalition.categories &&
                info.collaboration_with_the_state_coalition.categories[0]?.options?.reduce(
                  (acc: any, cur: any) => ({ ...acc, [cur.id]: true }),
                  {}
                );
              const checkedIds2 =
                info.collaboration_with_the_state_coalition.categories &&
                info.collaboration_with_the_state_coalition.categories[1]?.options?.reduce(
                  (acc: any, cur: any) => ({ ...acc, [cur.id]: true }),
                  {}
                );
              const checkedIds3 =
                info.collaboration_with_the_state_coalition.categories &&
                info.collaboration_with_the_state_coalition.categories[2]?.options?.reduce(
                  (acc: any, cur: any) => ({ ...acc, [cur.id]: true }),
                  {}
                );

              setCheckedItems1(checkedIds1 || []);
              setCheckedItems2(checkedIds2 || []);
              setCheckedItems3(checkedIds3 || []);
              setRemark(info.remark);
              const checkedComments1 =
                info.collaboration_with_the_state_coalition.categories &&
                info.collaboration_with_the_state_coalition.categories[0]?.options?.reduce(
                  (acc: any, cur: any) => ({ ...acc, [cur.id]: cur.comment }),
                  {}
                );
              const checkedComments2 =
                info.collaboration_with_the_state_coalition.categories &&
                info.collaboration_with_the_state_coalition.categories[1]?.options?.reduce(
                  (acc: any, cur: any) => ({ ...acc, [cur.id]: cur.comment }),
                  {}
                );

              const checkedComments3 =
                info.collaboration_with_the_state_coalition.categories &&
                info.collaboration_with_the_state_coalition.categories[2]?.options?.reduce(
                  (acc: any, cur: any) => ({ ...acc, [cur.id]: cur.comment }),
                  {}
                );
              setCheckedId({
                ...checkedComments1,
                ...checkedComments2,
                ...checkedComments3,
              });
            }
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              if (error.response.data.message) {
                showSnackBar(error.response.data.message, 'error');
              } else if (error.response.data.error) {
                showSnackBar(error.response.data.error, 'error');
              } else if (error.response.data.errors) {
                showSnackBar(error.response.data.errors.join('\n'), 'error');
              }
            } else {
              showSnackBar(error.message, 'error');
            }
          });
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const getImage = async () => {
      return new Promise((resolve, reject) => {
        const fd: any = new FormData();
        fd.append('file', logo);
        instance
          .post(`/auth/upload-logo/${appno}`, fd)
          .then((responce: any) => {
            if (responce.data && responce.data.success) {
              return;
            }
          })
          .catch(
            (error: {
              response: {
                data: { message: string; error: string; errors: any[] };
              };
              message: string;
            }) => {
              if (error.response && error.response.data) {
                if (error.response.data.message) {
                  showSnackBar(error.response.data.message, 'error');
                } else if (error.response.data.error) {
                  showSnackBar(error.response.data.error, 'error');
                } else if (error.response.data.errors) {
                  showSnackBar(error.response.data.errors.join('\n'), 'error');
                }
              } else {
                showSnackBar(error.message, 'error');
              }
            }
          );
      });
    };
    if (appno && logo) {
      getImage();
    }
  }, [logocheck]);

  const history = useHistory();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [img, setImgData] = useState<any>(null);
  const [checkedItems1, setCheckedItems1] = useState<any>([]);
  const [checkedItems2, setCheckedItems2] = useState<any>([]);
  const [checkedItems3, setCheckedItems3] = useState<any>([]);
  const [checkedId, setCheckedId] = useState<any>([]);

  const handleAddClick = () => {
    setContact([...Contact, contact]);
  };

  const handleRemoveClick = (index: number) => {
    const list = [...Contact];
    list.splice(index, 1);
    setContact(list);
  };

  const handleCheckId = (event: any) => {
    setCheckedId({
      ...checkedId,
      [event.target.name]: event.target.value,
    });
  };
  const handleCheck1 = (event: any) => {
    if (event.target.checked) {
      setCheckedId({
        ...checkedId,
        [event.target.name]: '',
      });
    } else {
      delete checkedId[event.target.name];
    }
    setCheckedItems1({
      ...checkedItems1,
      [event.target.name]: event.target.checked,
    });
  };
  const handleCheck2 = (event: any) => {
    if (event.target.checked) {
      setCheckedId({
        ...checkedId,
        [event.target.name]: '',
      });
    } else {
      delete checkedId[event.target.name];
    }
    setCheckedItems2({
      ...checkedItems2,
      [event.target.name]: event.target.checked,
    });
  };
  const handleCheck3 = (event: any) => {
    if (event.target.checked) {
      setCheckedId({
        ...checkedId,
        [event.target.name]: '',
      });
    } else {
      delete checkedId[event.target.name];
    }
    setCheckedItems3({
      ...checkedItems3,
      [event.target.name]: event.target.checked,
    });
  };

  const handleExpand = (panel: string) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handlelogo = (e: any) => {
    setLogo(e.target.files[0]);
  };
  const handleCenterInfo = (e: any) => {
    const { name, value } = e.target;
    setCenterInfo({
      ...centerInfo,
      [name]: value,
    });
    validate({ [name]: value });
  };
  const handleChange = (e: any, i: any) => {
    const { name, value, checked } = e.target;
    const list: any = [...Contact];

    if (
      // name == 'is_primary' ||
      name == 'is_ar_manager' ||
      name == 'is_volunteer'
    ) {
      list[i][name] = checked as any;
    } else if(name == 'is_primary'){
      list[i][name] = checked as any;
      list.map((val:any,index:any) => {
        if(i !== index){
          list[index].is_primary = false
        }
      });
    } else {
      list[i][name] = value as any;
    }
    setContact(list);
    if (i == 0) {
      validate({ [name]: value }, i);
    }
  };

  const formIsValid = () => {
    const isValid =
      centerInfo.name &&
      centerInfo.address1 &&
      centerInfo.city &&
      centerInfo.county &&
      centerInfo.state_code &&
      centerInfo.zip &&
      centerInfo.business_phone &&
      centerInfo.hotline_phone &&
      centerInfo.center_type_id &&
      Contact[0].first_name &&
      Contact[0].last_name &&
      Contact[0].title &&
      Contact[0].email &&
      Contact[0].phone &&
      volunteer.volunteer_first_name &&
      volunteer.volunteer_last_name &&
      volunteer.volunteer_title &&
      volunteer.volunteer_email &&
      centerInfo.website &&
      Object.values(checkedItems1).filter((item) => item).length >= 1 &&
      Object.values(checkedItems2).filter((item) => item).length >= 1 &&
      Object.values(checkedItems3).filter((item) => item).length >= 1 &&
      Object.values(errors).every((x) => x === '');

    return isValid;
  };
  const validate: any = (
    fieldValues = { ...centerInfo, ...Contact[0] },
    i: number
  ) => {
    const temp: any = { ...errors };
    if ('name' in fieldValues) {
      temp.name = fieldValues.name ? '' : 'This field is required.';
    }
    if ('address1' in fieldValues) {
      temp.address1 = fieldValues.address1 ? '' : 'This field is required.';
    }
    if ('city' in fieldValues) {
      temp.city = fieldValues.city ? '' : 'This field is required.';
    }
    if ('county' in fieldValues) {
      temp.county = fieldValues.county ? '' : 'This field is required.';
    }
    if ('state_code' in fieldValues) {
      temp.state_code = fieldValues.state_code ? '' : 'This field is required.';
    }
    if ('zip' in fieldValues) {
      temp.zip = fieldValues.zip ? '' : 'This field is required.';
    }
    if ('business_phone' in fieldValues) {
      temp.business_phone = fieldValues.business_phone
        ? ''
        : 'This field is required.';
    }
    if ('hotline_phone' in fieldValues) {
      temp.hotline_phone = fieldValues.hotline_phone
        ? ''
        : 'This field is required.';
    }
    if ('center_type_id' in fieldValues) {
      temp.center_type_id = fieldValues.center_type_id
        ? ''
        : 'Please select center type.';
    }
    if ('website' in fieldValues) {
      temp.website = fieldValues.website ? '' : 'This field is required.';
      if (fieldValues.website)
        temp.website = /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/.test(
          fieldValues.website
        )
          ? ''
          : 'Website format is not valid.';
    }
    if ('first_name' in fieldValues) {
      temp.first_name = fieldValues.first_name ? '' : 'This field is required.';
    }
    if ('last_name' in fieldValues) {
      temp.last_name = fieldValues.last_name ? '' : 'This field is required.';
    }
    if ('title' in fieldValues) {
      temp.title = fieldValues.title ? '' : 'This field is required.';
    }
    if ('phone' in fieldValues) {
      temp.phone = fieldValues.phone ? '' : 'This field is required.';
    }
    if ('email' in fieldValues) {
      temp.email = fieldValues.email ? '' : 'This Email is required.';
      if (fieldValues.email)
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? ''
          : 'Email is not valid.';
    }
    setErrors({
      ...temp,
    });
    const isValid =
      Object.values(checkedItems1).filter((item) => item).length >= 1 &&
      Object.values(checkedItems2).filter((item) => item).length >= 1 &&
      Object.values(checkedItems3).filter((item) => item).length >= 1 &&
      Object.values(temp).every((x) => x === '');

    return isValid;
  };
  const handleClear = () => {
    setCenterInfo(center);
  };
  const [language, setLanguage] = useState('en');

  const [disabilityPicker, pickerToggle] = useState(true);

  const pickerToggler = () => {
    pickerToggle(!disabilityPicker);
  };

  const [classState, classToggle] = useState(false);

  const classToggler = () => {
    classToggle(!classState);
  };

  const {
    name,
    zip,
    hotline_phone,
    center_type_id,
    state_code,
    business_phone,
    fax,
    tty,
    hotline,
    address1,
    address2,
    city,
    county,
    website,
  } = centerInfo;
  const {
    volunteer_email,
    volunteer_last_name,
    volunteer_first_name,
    volunteer_title,
  } = volunteer;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const fd: any = new FormData();
    const data = JSON.stringify({
      collaboration_with_the_state_coalition: { categories_value: checkedId },
      center: centerInfo,
      hotline_info: {
        "hotline_phone": centerInfo.hotline_phone,
        "hotline": centerInfo.hotline
      },
      user: Contact,
    });

    fd.append('file', logo);
    fd.append('data', data);
    if (id === undefined) {
      if (validate()) {
        setLogocheck(true);

        Admin_Api.addCenter(fd)
          .then((responce) => {
            if (responce.data && responce.data.success) {
              showSnackBar(responce.data.message);
              setAppno(responce.data.data.id);
              history.push('/center_list');
            }
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              if (error.response.data.message) {
                showSnackBar(error.response.data.message, 'error');
              } else if (error.response.data.error) {
                showSnackBar(error.response.data.error, 'error');
              } else if (error.response.data.errors) {
                showSnackBar(error.response.data.errors.join('\n'), 'error');
              }
            } else {
              showSnackBar(error.message, 'error');
            }
          });
      } else {
        setDefaultOpen(true);
        showSnackBar('Please fill mandatory fields', 'warning');
      }
    } else {
      if (validate()) {
        setLogocheck(true);
        Admin_Api.EditCenter(fd, id)
          .then((responce) => {
            if (responce.data && responce.data.success) {
              showSnackBar(responce.data.message);

              history.push('/center_list');
            }
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              if (error.response.data.message) {
                showSnackBar(error.response.data.message, 'error');
              } else if (error.response.data.error) {
                showSnackBar(error.response.data.error, 'error');
              } else if (error.response.data.errors) {
                showSnackBar(error.response.data.errors.join('\n'), 'error');
              }
            } else {
              showSnackBar(error.message, 'error');
            }
          });
      } else {
        setDefaultOpen(true);
        showSnackBar('Please fill mandatory fields', 'warning');
      }
    }
  };
  return (
    <>
      <Layout>
        <Container>
          <Typography
            component="div"
            className="rainn-lg-container centers-admin-panel"
          >
            <Typography component="div" className="centers-admin-header">
              <Typography
                component="div"
                className="centers-admin-header-top-bar"
              >
                <Typography
                  component="h1"
                  className="centers-admin-section-text flex items-center"
                >
                  <Link to="/center_list">
                    <MdArrowBack className="mr-1 text-pc-1" />
                  </Link>{' '}
                  Center
                </Typography>
              </Typography>
            </Typography>
            <form>
              <Typography
                component="div"
                className="centers-admin-info-accordion-wrap"
              >
                <Accordion
                  className="centers-admin-info-accordion"
                  expanded={defaultOpen ? true : expanded === 'panel1'}
                  onChange={handleExpand('panel1')}
                >
                  <AccordionSummary
                    expandIcon={<MdKeyboardArrowRight />}
                    className="centers-admin-info-accordion-summary"
                  >
                    <Typography component="h2">Center Info</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="centers-admin-info-accordion-details">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          className="centers-admin-input"
                          name="name"
                          value={name}
                          required={name === ''}
                          error={errors.name}
                          {...(errors.name && {
                            error: true,
                            helperText: errors.name,
                          })}
                          onChange={handleCenterInfo}
                          label="Organization Name"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          className="centers-admin-input"
                          name="address1"
                          value={address1}
                          required={address1 === ''}
                          error={errors.address1}
                          {...(errors.address1 && {
                            error: true,
                            helperText: errors.address1,
                          })}
                          onChange={handleCenterInfo}
                          label="Address"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          className="centers-admin-input"
                          name="address2"
                          value={address2}
                          onChange={handleCenterInfo}
                          label="Enter Address #2 here"
                        />
                      </Grid>
                      <Grid item md xs={12}>
                        <TextField
                          className="centers-admin-input"
                          name="city"
                          value={city}
                          required={city === ''}
                          error={errors.city}
                          {...(errors.city && {
                            error: true,
                            helperText: errors.city,
                          })}
                          onChange={handleCenterInfo}
                          label="City"
                        />
                      </Grid>
                      <Grid item md xs={12}>
                        <TextField
                          className="centers-admin-input"
                          name="county"
                          value={county}
                          required={county === ''}
                          error={errors.county}
                          {...(errors.county && {
                            error: true,
                            helperText: errors.county,
                          })}
                          onChange={handleCenterInfo}
                          label="Enter County here"
                        />
                      </Grid>
                      <Grid item md xs={12}>
                        <TextField
                          name="state_code"
                          select
                          label="Select state"
                          id="demo-simple-select"
                          className="centers-admin-autocomplete"
                          value={state_code}
                          required={state_code === ''}
                          error={errors.state_code}
                          {...(errors.state_code && {
                            error: true,
                            helperText: errors.state_code,
                          })}
                          onChange={handleCenterInfo}
                        >
                          {formFieldInfo?.data.states.map((res: any) => {
                            return (
                              <MenuItem value={res.code}>{res.name}</MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>

                      <Grid item md xs={12}>
                        <TextField
                          type="number"
                          className="centers-admin-input"
                          label="Enter zip code here"
                          name="zip"
                          value={zip}
                          required={zip === ''}
                          error={errors.zip}
                          {...(errors.zip && {
                            error: true,
                            helperText: errors.zip,
                          })}
                          onChange={handleCenterInfo}
                        />
                      </Grid>
                      <Grid item md xs={12}>
                        <label
                          htmlFor="centers-admin-upload-button"
                          className="w-full label-input mb-1"
                        >
                          Upload Center's Logo
                        </label>
                        <input
                          className="centers-admin-upload-button"
                          accept="image/*"
                          id="centers-admin-button-image"
                          multiple
                          type="file"
                          onChange={handlelogo}
                        />
                      </Grid>
                      <Grid item md xs={12}>
                        <TextField
                          className="centers-admin-input"
                          name="business_phone"
                          value={business_phone}
                          required={business_phone === ''}
                          error={errors.business_phone}
                          {...(errors.business_phone && {
                            error: true,
                            helperText: errors.business_phone,
                          })}
                          onChange={handleCenterInfo}
                          label="Business Phone #"
                        />
                      </Grid>
                      <Grid item md xs={12}>
                        <TextField
                          className="centers-admin-input"
                          name="hotline_phone"
                          value={hotline_phone}
                          required={hotline_phone === ''}
                          error={errors.hotline_phone}
                          {...(errors.hotline_phone && {
                            error: true,
                            helperText: errors.hotline_phone,
                          })}
                          onChange={handleCenterInfo}
                          label="Hotline Phone #"
                        />
                      </Grid>
                      <Grid item md xs={12}>
                        <TextField
                          className="centers-admin-input"
                          name="tty"
                          value={tty}
                          onChange={handleCenterInfo}
                          label="Enter Tty # here"
                        />
                      </Grid>
                      <Grid item md xs={12}>
                        <TextField
                          className="centers-admin-input"
                          name="hotline"
                          value={hotline}
                          onChange={handleCenterInfo}
                          label="Toll-free Hotline #"
                        />
                      </Grid>
                      <Grid item md xs={12}>
                        <TextField
                          className="centers-admin-input"
                          name="fax"
                          value={fax}
                          onChange={handleCenterInfo}
                          label="Enter Fax # here"
                        />
                      </Grid>
                      <Grid item xs={12} spacing={2}>
                        <FormControl>
                          <RadioGroup
                            name="center_type_id"
                            className="centers-admin-radio-group"
                            value={center_type_id.toString()}
                            required
                            error={errors.center_type_id}
                            {...(errors.center_type_id && {
                              error: true,
                              helperText: errors.center_type_id,
                            })}
                            onChange={handleCenterInfo}
                          >
                            <FormControlLabel
                              value="2"
                              control={<Radio color="primary" />}
                              label="Affiliate Center"
                            />
                            <FormControlLabel
                              value="3"
                              control={<Radio color="primary" />}
                              label="Referral Center"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className="centers-admin-info-accordion"
                  expanded={defaultOpen ? true : expanded === 'panel2'}
                  onChange={handleExpand('panel2')}
                >
                  <AccordionSummary
                    expandIcon={<MdKeyboardArrowRight />}
                    className="centers-admin-info-accordion-summary"
                  >
                    <Typography component="h2">
                      What populations do you serve?{' '}
                      <span className="fn">(English, Spanish)</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="centers-admin-info-accordion-details">
                    <Grid container>
                      <Grid item xs={12} spacing={2}>
                        <FormControl>
                          <RadioGroup
                            name="language"
                            className="centers-admin-radio-group"
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}
                          >
                            <FormControlLabel
                              value="en"
                              control={<Radio color="primary" />}
                              label="English"
                            />
                            <FormControlLabel
                              value="es"
                              control={<Radio color="primary" />}
                              label="Spanish"
                            />
                          </RadioGroup>
                        </FormControl>
                        {Object.values(checkedItems1).filter((item) => item)
                          .length >= 1 ? (
                          <></>
                        ) : (
                          <Typography className="text-sc-4">
                            Please select populations.
                          </Typography>
                        )}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormControl component="fieldset">
                          {formFieldInfo.data?.categories[0]?.options?.map(
                            (doc: any) => {
                              return (
                                <>
                                  <FormControlLabel
                                    className="centers-admin-checkbox"
                                    control={
                                      <Checkbox
                                        color="primary"
                                        name={doc.id}
                                        checked={checkedItems1[doc.id] === true}
                                        onChange={handleCheck1}
                                      />
                                    }
                                    label={
                                      language === 'en'
                                        ? `${doc.name.en}`
                                        : `${doc.name.es}`
                                    }
                                  />
                                  {checkedItems1[doc.id] === true && (
                                    <TextField
                                      name={doc.id}
                                      value={checkedId[doc.id]}
                                      onChange={handleCheckId}
                                    ></TextField>
                                  )}
                                </>
                              );
                            }
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className="centers-admin-info-accordion"
                  expanded={defaultOpen ? true : expanded === 'panel3'}
                  onChange={handleExpand('panel3')}
                >
                  <AccordionSummary
                    expandIcon={<MdKeyboardArrowRight />}
                    className="centers-admin-info-accordion-summary"
                  >
                    <Typography component="h2">
                      What services do you provide?{' '}
                      <span className="fn">(English, Spanish)</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="centers-admin-info-accordion-details">
                    <Grid container>
                      <Grid item xs={12} spacing={2}>
                        <FormControl>
                          <RadioGroup
                            name="language"
                            className="centers-admin-radio-group"
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}
                          >
                            <FormControlLabel
                              value="en"
                              control={<Radio color="primary" />}
                              label="English"
                            />
                            <FormControlLabel
                              value="es"
                              control={<Radio color="primary" />}
                              label="Spanish"
                            />
                          </RadioGroup>
                        </FormControl>
                        {Object.values(checkedItems2).filter((item) => item)
                          .length >= 1 ? (
                          <Typography></Typography>
                        ) : (
                          <Typography className="text-sc-4">
                            Please select services.
                          </Typography>
                        )}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormControl component="fieldset">
                          {formFieldInfo.data?.categories[1]?.options?.map(
                            (doc: any) => {
                              return (
                                <>
                                  <FormControlLabel
                                    className="centers-admin-checkbox"
                                    control={
                                      <Checkbox
                                        color="primary"
                                        name={doc.id}
                                        checked={checkedItems2[doc.id] === true}
                                        onChange={handleCheck2}
                                      />
                                    }
                                    label={
                                      language === 'en'
                                        ? `${doc.name.en}`
                                        : `${doc.name.es}`
                                    }
                                  />
                                  {checkedItems2[doc.id] === true && (
                                    <TextField
                                      name={doc.id}
                                      value={checkedId[doc.id]}
                                      onChange={handleCheckId}
                                    ></TextField>
                                  )}
                                </>
                              );
                            }
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className="centers-admin-info-accordion"
                  expanded={defaultOpen ? true : expanded === 'panel7'}
                  onChange={handleExpand('panel7')}
                >
                  <AccordionSummary
                    expandIcon={<MdKeyboardArrowRight />}
                    className="centers-admin-info-accordion-summary"
                  >
                    <Typography component="h2">
                      What type of violence do you serve?{' '}
                      <span className="fn">(English, Spanish)</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="centers-admin-info-accordion-details">
                    <Grid container>
                      <Grid item xs={12} spacing={2}>
                        <FormControl>
                          <RadioGroup
                            name="language"
                            className="centers-admin-radio-group"
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}
                          >
                            <FormControlLabel
                              value="en"
                              control={<Radio color="primary" />}
                              label="English"
                            />
                            <FormControlLabel
                              value="es"
                              control={<Radio color="primary" />}
                              label="Spanish"
                            />
                          </RadioGroup>
                        </FormControl>
                        {Object.values(checkedItems3).filter((item) => item)
                          .length >= 1 ? (
                          <></>
                        ) : (
                          <Typography className="text-sc-4">
                            Please select type of violence.
                          </Typography>
                        )}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormControl component="fieldset">
                          {formFieldInfo.data?.categories &&
                            formFieldInfo.data?.categories[2]?.options?.map(
                              (doc: any) => {
                                return (
                                  <>
                                    <FormControlLabel
                                      className="centers-admin-checkbox"
                                      control={
                                        <Checkbox
                                          color="primary"
                                          name={doc.id}
                                          checked={
                                            checkedItems3[doc.id] === true
                                          }
                                          onChange={handleCheck3}
                                        />
                                      }
                                      label={
                                        language === 'en'
                                          ? `${doc.name.en}`
                                          : `${doc.name.es}`
                                      }
                                    />
                                    {checkedItems3[doc.id] === true && (
                                      <TextField
                                        name={doc.id}
                                        value={checkedId[doc.id]}
                                        onChange={handleCheckId}
                                      ></TextField>
                                    )}
                                  </>
                                );
                              }
                            )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  className="centers-admin-info-accordion"
                  expanded={defaultOpen ? true : expanded === 'panel4'}
                  onChange={handleExpand('panel4')}
                >
                  <AccordionSummary
                    expandIcon={<MdKeyboardArrowRight />}
                    className="centers-admin-info-accordion-summary"
                  >
                    <Typography component="h2">Contact Information</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="centers-admin-info-accordion-details">
                    {Contact?.map((x, i) => {
                      return (
                        <>
                          <Grid container spacing={2}>
                            <Grid item md xs={12}>
                              <TextField
                                className="centers-admin-input"
                                label="First Name"
                                name="first_name"
                                value={x.first_name}
                                required={x.first_name === ''}
                                error={i < 1 ? errors.first_name : ''}
                                {...(errors.first_name &&
                                  i < 1 && {
                                    error: true,
                                    helperText: errors.first_name,
                                  })}
                                onChange={(e) => handleChange(e, i)}
                              />
                            </Grid>
                            <Grid item md xs={12}>
                              <TextField
                                className="centers-admin-input"
                                label="Last Name"
                                name="last_name"
                                value={x.last_name}
                                required={x.last_name === ''}
                                error={i < 1 && errors.last_name}
                                {...(errors.last_name &&
                                  i < 1 && {
                                    error: true,
                                    helperText: errors.last_name,
                                  })}
                                onChange={(e) => handleChange(e, i)}
                              />
                            </Grid>
                            <Grid item md xs={12}>
                              <TextField
                                className="centers-admin-input"
                                label="Title"
                                name="title"
                                value={x.title}
                                required={x.title === ''}
                                error={i < 1 && errors.title}
                                {...(errors.title &&
                                  i < 1 && {
                                    error: true,
                                    helperText: errors.title,
                                  })}
                                onChange={(e) => handleChange(e, i)}
                              />
                            </Grid>
                            <Grid item md xs={12}>
                              <TextField
                                className="centers-admin-input"
                                type="email"
                                label="Email"
                                name="email"
                                value={x.email}
                                required={x.email === ''}
                                error={i < 1 && errors.email}
                                {...(errors.email &&
                                  i < 1 && {
                                    error: true,
                                    helperText: errors.email,
                                  })}
                                onChange={(e) => handleChange(e, i)}
                              />
                            </Grid>
                            <Grid item md xs={12}>
                              <TextField
                                className="centers-admin-input"
                                label="Phone"
                                name="phone"
                                value={x.phone}
                                required={x.phone === ''}
                                error={i < 1 && errors.phone}
                                {...(errors.phone &&
                                  i < 1 && {
                                    error: true,
                                    helperText: errors.phone,
                                  })}
                                onChange={(e) => handleChange(e, i)}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={3} className="pt-1">
                            <Grid item md={10} xs={12}>
                              <FormControlLabel
                                className="pr-5"
                                control={
                                  <Checkbox
                                    className="centers-admin-checkbox"
                                    color="primary"
                                    name="is_primary"
                                    // disabled={i != 0}
                                    checked={x.is_primary}
                                    onChange={(e) => handleChange(e, i)}
                                  />
                                }
                                label="Primary Contact"
                              />
                              <FormControlLabel
                                className="pr-5"
                                control={
                                  <Checkbox
                                    className="centers-admin-checkbox"
                                    color="primary"
                                    name="is_ar_manager"
                                    // disabled={i == 0}
                                    checked={x.is_ar_manager}
                                    onChange={(e) => handleChange(e, i)}
                                  />
                                }
                                label="Receive AR Manager Communication"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    className="centers-admin-checkbox"
                                    color="primary"
                                    name="is_volunteer"
                                    // disabled={i == 0}
                                    checked={x.is_volunteer}
                                    onChange={(e) => handleChange(e, i)}
                                  />
                                }
                                label="Receive Volunteer Requests"
                              />
                            </Grid>
                            <Grid item md={2} xs={12} className="float-left">
                              {Contact?.length !== 1 && (
                                <Tooltip title="Remove">
                                  {/* <MdDelete
                                title="delete"
                                className="centers-admin-table-icon"
                                onClick={() => handleRemoveClick(i)}
                              /> */}
                                  <Button
                                    className="float-left button-primary text-2xl"
                                    variant="outlined"
                                    onClick={() => handleRemoveClick(i)}
                                  >
                                    -
                                  </Button>
                                </Tooltip>
                              )}
                              {Contact?.length - 1 === i && (
                                <Tooltip title="Add more">
                                  <Button
                                    className="float-left ml-3 button-primary text-2xl"
                                    variant="outlined"
                                    onClick={handleAddClick}
                                  >
                                    +
                                  </Button>
                                </Tooltip>
                              )}
                            </Grid>
                          </Grid>
                          <div className="border-t border-tc-6 mt-6 mb-6"></div>
                        </>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className="centers-admin-info-accordion"
                  expanded={defaultOpen ? true : expanded === 'panel6'}
                  onChange={handleExpand('panel6')}
                >
                  <AccordionSummary
                    expandIcon={<MdKeyboardArrowRight />}
                    className="centers-admin-info-accordion-summary"
                  >
                    <Typography component="h2">
                      How should we link to you?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="centers-admin-info-accordion-details">
                    <TextField
                      className="centers-admin-input md:w-1/2"
                      label="website"
                      name="website"
                      value={website}
                      required={website === ''}
                      error={errors.website}
                      {...(errors.website && {
                        error: true,
                        helperText: errors.website,
                      })}
                      onChange={handleCenterInfo}
                    />
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography component="div" className="mt-7">
                {props.match.path === '/center_list_edit/:id' ? (
                  <TextField
                    label="Remarks"
                    disabled
                    className="centers-admin-input mb-3"
                    name="remark"
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                  />
                ) : null}

                {props.match.path === '/center_list_edit/:id' ? (
                  <Button
                    type="submit"
                    className="button-primary mr-3"
                    onClick={handleSubmit}
                  >
                    update
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    className="button-primary mr-3"
                    onClick={handleSubmit}
                  >
                    submit
                  </Button>
                )}

                <Button className="button-secondary" onClick={handleClear}>
                  Clear
                </Button>
              </Typography>
            </form>
          </Typography>
        </Container>
      </Layout>
    </>
  );
}
export default centerListAdd;
