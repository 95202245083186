import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import RouteSubRoute from './routeSubRoute';
import CenterSearch from '../../containers/CenterSearch';
import NoMatch from '../../containers/NoMatch';
import CenterDetail from '../../containers/CenterDetail';
import LogIn from '../../containers/Admin/LogIn';
import ForgotPassword from '../../component/Admin/ForgotPassword';
import ResetPassword from '../../component/Admin/ResetPassword';
import ProtectedRoute from './protectedRoute';
import centerListPage from '../../containers/Admin/CenterList/centerListPage';
import centerListAdd from '../../component/Admin/centerListAdd';
import categoryAdd from '../../component/Admin/categoryAdd';
import ApproveRequestList from '../../containers/Admin/ApproveRequest/ApproveRequestList';
import NationalList from '../../containers/Admin/NationalList/NationalListPage';
import NationalCenterAdd from '../../component/Admin/nationalCenterAdd';
import categoryList from '../../containers/Admin/Category/Categorylist';
import changePassword from '../../component/Admin/changePassword';
import InformationData from '../../containers/Admin/Information/InformationData';
import EmailTemplateList from '../../containers/Admin/EmailTemplate/EmailTemplateList';
import changeRequest from '../../component/Admin/changeRequest';
import Feedback from '../../component/Admin/Feedback';
import emailTemplateEdit from '../../component/Admin/emailTemplateEdit';
import contactUs from '../../component/Admin/contactUs';
import DeniedRequestList from '../../containers/Admin/DeniedRequest/DeniedRequestList';
import Signup from '../../containers/Admin/Signup/Signup';
import Requestcalldata from '../../containers/Admin/RequestCall/RequestCallData';
import NewCenter from '../../component/Admin/NewCenterApproveDecline';
import ApplicationForm from '../../component/Admin/ApplicationForm';
import AffiliateForm from '../../component/Admin/AffiliateForm';
import VolunteerList from '../../containers/Admin/Volunteer/volunteerList';
import VolunteerNeeds from '../../containers/Admin/Volunteer/volunteerNeeds';
import OpportunityList from '../../containers/Admin/Volunteer/OpportunityList';
import UploadCallData from '../../containers/Admin/UploadCall/UploadCallData';
import VolunteerSearch from '../../containers/VolunteerSearch';
import VolunteerCenterDetail from '../../containers/VolunteerDetail';
import VolunteerReqView from '../../component/Admin/volunteerReqView';
import VolunteerCentersCard from '../../component/Volunteer/VolunteerCentersCard';
import VolunteerListByCenter from '../../component/Admin/VolunteerListByCenter';
import ScrollToTop from '../../component/ScrollToTop';
import PageNotFound from '../../component/PageNotFound';

const RouteConfig: React.FunctionComponent = () => {
  const routes = [
    {
      path: '/',
      exact: true,
      component: CenterSearch,
    },
    {
      path: '/volunteer_search',
      exact: true,
      component: VolunteerSearch,
    },
    {
      path: '/volunteer_detail/:id',
      exact: true,
      component: VolunteerCenterDetail,
    },
    {
      path: '/volunteer-card',
      exact: true,
      component: VolunteerCentersCard,
    },
    {
      path: '/center-detail/:id',
      exact: true,
      component: CenterDetail,
    },
    {
      path: '/login',
      exact: true,
      component: LogIn,
    },
    {
      path: '/forgot_password',
      exact: true,
      component: ForgotPassword,
    },
    {
      path: '/application_no',
      exact: true,
      component: ApplicationForm,
    },
    {
      path: '/reset_password',
      exact: true,
      component: ResetPassword,
    },
    {
      path: '/registration',
      exact: true,
      component: AffiliateForm,
    },
    {
      path: '/registration/application_no/:id',
      exact: true,
      component: AffiliateForm,
    },
  ];
  const protected_route = [
    {
      path: '/center_list',
      exact: true,
      roles: ['1'],
      component: centerListPage,
    },
    {
      path: '/new_center/confirm/:id',
      exact: true,
      roles: ['1'],
      component: NewCenter,
    },
    {
      path: '/center_list_edit/:id',
      exact: true,
      roles: ['1'],
      component: centerListAdd,
    },
    {
      path: '/center_list_add',
      exact: true,
      roles: ['1'],
      component: centerListAdd,
    },
    {
      path: '/category_add',
      exact: true,
      roles: ['1'],
      component: categoryAdd,
    },
    {
      path: '/approve_request_list',
      exact: true,
      roles: ['1'],
      component: ApproveRequestList,
    },
    {
      path: '/confirm_change_request/:id',
      exact: true,
      roles: ['1'],
      component: changeRequest,
    },
    {
      path: '/national_center_list',
      exact: true,
      roles: ['1'],
      component: NationalList,
    },
    {
      path: '/national_center_add',
      exact: true,
      roles: ['1'],
      component: NationalCenterAdd,
    },
    {
      path: '/national_center_edit/:id',
      exact: true,
      roles: ['1'],
      component: NationalCenterAdd,
    },
    {
      path: '/category_list',
      exact: true,
      roles: ['1'],
      component: categoryList,
    },
    {
      path: '/denied_request_list',
      exact: true,
      roles: ['1'],
      component: DeniedRequestList,
    },
    {
      path: '/feedback/:id',
      exact: true,
      roles: ['1'],
      component: Feedback,
    },
    {
      path: '/email_templates',
      exact: true,
      roles: ['1'],
      component: EmailTemplateList,
    },
    {
      path: '/email_templates/:id',
      exact: true,
      roles: ['1'],
      component: emailTemplateEdit,
    },
    {
      path: '/contact_rainn',
      exact: true,
      roles: ['1', '2', '3', '4'],
      component: contactUs,
    },
    {
      path: '/category_edit/:id',
      exact: true,
      roles: ['1'],
      component: categoryAdd,
    },
    {
      path: '/subcategory_edit/:id',
      exact: true,
      roles: ['1'],
      component: categoryAdd,
    },
    {
      path: '/change_password',
      exact: true,
      roles: ['1', '2', '3', '4'],
      component: changePassword,
    },
    {
      path: '/information',
      exact: true,
      roles: ['2', '3', '4'],
      component: InformationData,
    },
    {
      path: '/upload_call_data',
      exact: true,
      roles: ['1'],
      component: UploadCallData,
    },
    {
      path: '/request_call_data',
      exact: true,
      roles: ['2', '3', '4'],
      component: Requestcalldata,
    },
    {
      path: '/volunteer_list',
      exact: true,
      roles: ['2', '3', '4'],
      component: VolunteerList,
    },
    {
      path: '/volunteer_request/view/:id',
      exact: true,
      roles: ['2', '3', '4'],
      component: VolunteerReqView,
    },
    {
      path: '/volunteer_needs',
      exact: true,
      roles: ['2', '3', '4'],
      component: VolunteerNeeds,
    },
    {
      path: '/opportunity_list',
      exact: true,
      roles: ['2', '3', '4'],
      component: OpportunityList,
    },
    {
      path: '/opportunity_edit/:id',
      exact: true,
      roles: ['2', '3', '4'],
      component: VolunteerNeeds,
    },
    {
      path: '/volunteer_opportunity',
      exact: true,
      roles: ['1'],
      component: VolunteerListByCenter,
    },
  ];
  return (
    <Router>
      <ScrollToTop />
      <div>
        <Switch>
          {routes.map((route, i) => (
            <RouteSubRoute key={i} {...route} />
          ))}
          {protected_route.map((protected_route, i) => (
            <ProtectedRoute key={i} {...protected_route} />
          ))}
          <Route path="*" component={PageNotFound} />
        </Switch>
      </div>
    </Router>
  );
};

export default RouteConfig;
