import Box from '@material-ui/core/Box';
import React, { ReactNode } from 'react';
import Footer from '../../Footer';
import Header from './Header';
import Testimonial from '../../Testimonial';

type IProps = {
  children: ReactNode;
};
const Layout = (props: any) => {
  return (
    <div>
      <Header {...props} />
      <Box>{props.children}</Box>
      <Testimonial />
      <Footer />
    </div>
  );
};

export default Layout;
