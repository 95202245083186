import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import logo from '../assets/images/logo.jpg';
import {
  Grid,
  List,
  Link,
  Container,
  ListItem,
  ListItemText,
  Typography,
  AppBar,
  Toolbar,
  TextField,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { MdSearch, MdDehaze } from 'react-icons/md';
import PrimaryNavigation from './PrimaryNavigation';
import { useHistory } from 'react-router';

const Header: React.FunctionComponent = (props: any) => {
  const [click, setClick] = useState(false);
  const [search, setSearch] = useState('');
  const history = useHistory();
  const [width, setWidth] = useState(window.innerWidth);
  const handleSearch = (event: React.MouseEvent<HTMLButtonElement>) => {
    setClick(!click);
  };
  const handleSearchVal = (event: any) => {
    setSearch(event.target.value);
    if (event.charCode === 13 || event.key == 'Enter') {
      window.open(`https://www.rainn.org/search/node/${search}`);
    }
  };
  const [navIcon, setNavIcon] = useState(false);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
  }, []);
  const handleNavIcon = () => {
    setNavIcon(!navIcon);
  };

  return (
    <>
      <Typography component="div" className="centers-header">
        <Container className="centers-donate mob-hide" maxWidth={false}>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="stretch"
            spacing={0}
          >
            <Grid item>
              <a
                className="uppercase px-6 py-2 text-tc-1 font-bold block"
                href="https://hotline.rainn.org/online?_ga=2.11974065.449650562.1637092225-1466333179.1632249051"
              >
                Get Help 24x7:
              </a>
            </Grid>
            <Grid item>
              {/* <div className="uppercase px-7 py-2 bg-sc-2 font-bold">
                call 800.656.hope (4673)
              </div> */}
              <Link
                href="https://rainn.org/about-national-sexual-assault-telephone-hotline?_ga=2.132242759.891802913.1636979794-1037315227.1636431899"
                className="uppercase px-7 py-2 text-tc-2 bg-sc-2 font-bold block"
              >
                call 800.656.hope (4673)
              </Link>
            </Grid>
            <Grid item>
              <div className="uppercase py-2 bg-sc-2 font-bold">|</div>
            </Grid>
            <Grid item>
              <Link
                href="https://hotline.rainn.org/online?_ga=2.169235816.891802913.1636979794-1037315227.1636431899"
                className="uppercase px-7 py-2 text-tc-2 bg-sc-2 font-bold block"
              >
                live chat
              </Link>
            </Grid>
            <Grid item>
              <Link
                href="http://donate.rainn.org"
                className="px-7 py-2 bg-sc-3 text-tc-1 font-bold block"
              >
                Donate
              </Link>
            </Grid>
            <Grid item>
              <Button
                onClick={handleSearch}
                className="h-10 text-tc-1 font-bold"
              >
                <MdSearch className="text-2xl m-auto" />
              </Button>
            </Grid>
          </Grid>
        </Container>

        {click && (
          <Typography component="div" className="centers-top-search">
            <TextField
              className="centers-banner-input w-full"
              placeholder="Search RAINN"
              name="search"
              value={search}
              onChange={handleSearchVal}
              onKeyPress={handleSearchVal}
            />
          </Typography>
        )}
        <Typography component="div" className="centers-menu">
          <Typography component="div" className="centers-nav">
            <Typography
              component="div"
              className="flex items-center justify-between"
            >
              <Link href="https://www.rainn.org/" className="brand-logo">
                <img src={logo} className="w-full" alt="rainn-logo" />
              </Link>
              <MdDehaze
                className="centers-menu-icon desktop-hide"
                onClick={handleNavIcon}
              />
            </Typography>
            {window.innerWidth > 760 && (
              <PrimaryNavigation user={props.user} {...props} />
            )}
            {navIcon && window.innerWidth <= 760 && <PrimaryNavigation />}
          </Typography>
        </Typography>
      </Typography>
    </>
  );
};

export default Header;
