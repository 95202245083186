import React from 'react';
import CenterList from '../../../component/Admin/centerListSearch';
import Layout from '../../../component/Admin/Layout/Layout';



const centerListPage: React.FunctionComponent = () => {

    return (
      <Layout>
        <CenterList/>
      </Layout>
  );
}

export default centerListPage;