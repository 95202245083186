import React from 'react';
import Form from '../component/Form';
import Layout from '../component/Layout/Layout';
import StaticHomeContent from '../component/StaticHomeContent';


const Centersearch: React.FunctionComponent = (props:any) => {

    return (
      <Layout>
      <Form/>
      <StaticHomeContent {...props}/>
    </Layout>
  );
}

export default Centersearch;