import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { MdPlace } from 'react-icons/md';

const Marker = () => {
  return (
    <>
    <MdPlace className="text-2xl text-sc-4"/>
    </>
  );
};

class CenterMap extends Component {
  render() {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.React_App_Google_Key }}
          defaultCenter={{
            lat: this.props.latitude,
            lng: this.props.longitude,
          }}
          defaultZoom={11}
        >
          <Marker lat={this.props.latitude} lng={this.props.longitude} />
        </GoogleMapReact>
      </div>
    );
  }
}

export default CenterMap;
