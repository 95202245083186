import React from 'react';
import VolunteerNeedsData from '../../../component/Admin/volunteerNeeds';
import Layout from '../../../component/Admin/Layout/Layout';



const VolunteerNeeds: React.FunctionComponent = (props:any) => {

    return (
      <Layout>
        <VolunteerNeedsData {...props}/>
      </Layout>
  );
}

export default VolunteerNeeds;