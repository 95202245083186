import { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Container from '@material-ui/core/Container';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { useForm } from '../../hooks/useForm';
import DeclineRequestlist from './declineRequestlist'
import api from '../../API/Api';
import moment from 'moment';
import { useSnackBar } from '../../Context/Snackbar/withSnackbar';

function DeclineRequestSearchList() {
  const { showSnackBar } = useSnackBar();

  const entryInput = {
    data: {
      categories: [
        { name: { en: '', es: '' }, options: [] },
        { name: { en: '', es: '' }, options: [] },
        {
          name: { en: '', es: '' },
          options: [
            {
              id: '',
              name: {
                en: '',
                es: '',
              },
            },
          ],
        },
      ],
      states: [],
      center_count: [],
    },
  };
  const [formFieldInfo, setformFieldInfo] = useState(entryInput);
  const [States, setStates] = useState(null);
  const [StatesCode, setStatesCode] = useState('');
  const [population, setPopulation] = useState([]);
  const [Provider, setProvider] = useState([]);
  const [populationCategoryId, setpopulationCategoryId] = useState([]);
  const [providerCategoryId, setproviderCategoryId] = useState([]);
  const categoryId = [...providerCategoryId, ...populationCategoryId];

  useEffect(() => {
    api.getFormData()
      .then((response: any) => {
        if (response.data && response.data.success) {
          setformFieldInfo(response.data);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join("\n"), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  }, []);

  const Initial = {
    centerName: '',
    zip: '',
  };
  const { Values, setValues, handleChange, resetForm } = useForm(Initial);
  const [exclude, setexclude] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const searchInitial = {
    centerName: '',
    state: '',
    zip: 0,
    categoryId: [],
    endDate: '',
    startDate: ''
  }
  const [searchData, setSearchData] = useState(searchInitial);

  const handleStartDate = (date: Date | null) => {
    setStartDate(moment(date).format('MM/DD/YYYY') as any);
  };

  const [endDate, setEndDate] = useState<Date | null>(null);

  const handleEndDate = (date: Date | null) => {
    setEndDate(moment(date).format('MM/DD/YYYY') as any);
  };
  const handleApply = () => {
    setSearchData({
      centerName: Values.centerName,
      state: StatesCode,
      zip: Values.zip | 0,
      categoryId: categoryId,
      endDate: endDate as any,
      startDate: startDate as any
    });
  };
  const handleClear = () => {
    setValues(Initial);
    setStartDate(null);
    setEndDate(null);
    setStates(null);
    setStatesCode('');
    setPopulation([]);
    setProvider([]);
    setpopulationCategoryId([]);
    setproviderCategoryId([]);
    setexclude(false)
    setSearchData(searchInitial);
  };

  return (
    <>
      <Container>
        <Typography
          component="div"
          className="rainn-lg-container centers-admin-panel"
        >
          <Typography
            component="div"
            className="flex justify-between items-center py-5 border-b border-tc-6 mb-3"
          >
            <Typography component="h1" className="centers-admin-section-text">
              Decline Request
            </Typography>

          </Typography>
          <Grid container spacing={3} className="bg-tc-7 w-full m-0">
            <Grid item md={4} xs={12}>
              <TextField
                className="centers-admin-input"
                label="Search Center Name"
                name="centerName"
                value={Values.centerName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Autocomplete
                disableClearable
                clearOnEscape
                options={formFieldInfo?.data.states}
                getOptionLabel={(option: any) => {
                  return option.name;
                }}
                openOnFocus
                value={States}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setStatesCode(newValue.code);
                  }
                  setStates(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="centers-admin-autocomplete"
                    label="Select state"
                  />
                )}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                type="number"
                className="centers-admin-input"
                label="Add Zip Code"
                name="zip"
                value={Values.zip}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Autocomplete
                multiple
                limitTags={2}
                id="population"
                options={formFieldInfo.data.categories[1].options}
                getOptionLabel={(option) => option.name?.en}
                value={population}
                onChange={(event, newInput) => {
                  if (newInput !== null) {
                    setpopulationCategoryId(
                      JSON.parse(JSON.stringify(newInput)).map(
                        (doc: { id: string }) => doc.id
                      )
                    );
                    setPopulation(newInput as any);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="centers-admin-autocomplete"
                    label={`${formFieldInfo.data.categories[1].name.en}`}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Autocomplete
                multiple
                limitTags={2}
                id="provider"
                options={formFieldInfo.data.categories[0].options}
                getOptionLabel={(option) => option.name?.en}
                value={Provider}
                onChange={(event, newInput) => {
                  if (newInput !== null) {
                    setproviderCategoryId(
                      JSON.parse(JSON.stringify(newInput)).map(
                        (doc: { id: string }) => doc.id
                      )
                    );
                    setProvider(newInput as any);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="centers-admin-autocomplete"
                    label={`${formFieldInfo.data.categories[0].name.en}`}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="start-date-picker"
                  label="Last Rejected Date (Start Date)"
                  value={startDate}
                  onChange={handleStartDate}
                  className="centers-admin-date-picker"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={4} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="end-date-picker"
                  label="Last Rejected Date (End Date)"
                  value={endDate}
                  onChange={handleEndDate}
                  className="centers-admin-date-picker"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} className="flex justify-end">
              <Button className="button-primary mr-3" onClick={handleApply}>
                Apply
              </Button>
              <Button className="button-transparent" onClick={handleClear}>
                Clear
              </Button>
            </Grid>
          </Grid>
        </Typography>
        <DeclineRequestlist
          centerName={searchData.centerName}
          state={searchData.state}
          zip={searchData.zip}
          categoryIds={searchData.categoryId}
          endDate={searchData.endDate}
          startDate={searchData.startDate}
        />
      </Container>
    </>
  );
}
export default DeclineRequestSearchList;
