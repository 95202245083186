import React from 'react';
import CategoryList from '../../../component/Admin/categoryList';
import Layout from '../../../component/Admin/Layout/Layout';



const Categorylist: React.FunctionComponent = () => {

    return (
      <Layout>
        <CategoryList/>
      </Layout>
  );
}

export default Categorylist;