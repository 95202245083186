import React from 'react';
import Information from '../../../component/Admin/Information';
import Layout from '../../../component/Admin/Layout/Layout';



const InformationData: React.FunctionComponent = () => {

    return (
      <Layout>
        <Information/>
      </Layout>
  );
}

export default InformationData;