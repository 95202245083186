import React from 'react';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import GuideStar from '../assets/images/GuideStar.png';
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import { useForm } from '../hooks/useForm';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
const Footer: React.FunctionComponent = () => {
  const Initial = {
    email: '',
    first_name: '',
    last_name: '',
  };
  const history = useHistory();
  const { Values, setValues, handleChange, resetForm } = useForm(Initial);
  const { first_name, last_name, email } = Values;
  const recaptchaRef = React.createRef<ReCAPTCHA>();

  return (
    <div>
      <Typography component="div" className="centers-footer">
        <Container maxWidth={false}>
          <Grid container direction="row" spacing={0}>
            <Grid item md={12} className="">
              <Typography
                component="h6"
                className="text-center text-tc-1 py-5 font-notosans"
              >
                Find help, advice, and the resources you need. Call
                <Link href="#" className="font-bold text-tc-1 ml-1 underline">
                  800.656.4673
                </Link>
              </Typography>
            </Grid>
            <Grid item md={12} className="p-0">
              <Divider orientation="horizontal" variant="fullWidth" />
            </Grid>
            <Grid item sm={5} className="mb-5">
              <Typography
                component="p"
                className="text-base text-blue-100 my-3"
              >
                Get RAINN updates
              </Typography>

              <form
                name="emailNewsletter"
                id="emailNewsletter"
                action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
                method="POST"
              >
                <input
                  type="hidden"
                  name="captcha_settings"
                  value='{"keyname":"RAINN_org_Newsletter","fallback":"true","orgId":"00D700000008OJ2","ts":""}'
                />

                <input name="oid" type="hidden" value="00D700000008OJ2" />

                <input
                  type="hidden"
                  name="retURL"
                  value="https://rainn.org/thank-you-newsletter"
                />
                <input
                  type="hidden"
                  name="Campaign_ID"
                  value="70170000000KLBL"
                />
                <input
                  type="hidden"
                  name="recordType"
                  value="012390000005yYP"
                />
                <input
                  type="hidden"
                  name="URL"
                  value="https://centers-staging.rainn.org/"
                />
                <input
                  type="hidden"
                  name="00N39000003V1iR"
                  value="https://www.rainn.org/thank-you"
                />

                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      className="input-footer"
                      placeholder="First Name"
                      id="first_name"
                      name="first_name"
                      fullWidth
                      value={first_name}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      className="input-footer"
                      placeholder="Last Name"
                      id="last_name"
                      name="last_name"
                      onChange={handleChange}
                      value={last_name}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography component="div" className="flex">
                      <TextField
                        className="input-footer with-button"
                        placeholder="Email Address"
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        fullWidth
                      />

                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.React_App_Site_Key as string}
                        size="invisible"
                        badge="bottomright"
                      />
                      <Button
                        className="button-footer-form whitespace-nowrap"
                        type="submit"
                      >
                        Sign Up
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item md={2} className=""></Grid>
            <Grid item md={5} className="">
              <List className="grid grid-cols-3 p-4 footer-links">
                <ListItem className="col-span-1">
                  <Link href="https://www.rainn.org/contact-us">
                    Contact & FAQ
                  </Link>
                </ListItem>
                <ListItem className="col-span-1">
                  <Link href="https://www.rainn.org/careers">
                    Work With RAINN
                  </Link>
                </ListItem>
                <ListItem className="col-span-1">
                  <Link href="https://www.rainn.org/leadership-and-governance">
                    Leadership
                  </Link>
                </ListItem>
                <ListItem className="col-span-1">
                  <Link href="https://www.rainn.org/statistics">
                    Statistics
                  </Link>
                </ListItem>
                <ListItem className="col-span-1">
                  <Link href="https://www.rainn.org/get-involved">
                    Volunteer
                  </Link>
                </ListItem>
                <ListItem className="col-span-1">
                  <Link href="https://www.rainn.org/action-center">
                    Take Action
                  </Link>
                </ListItem>
                <ListItem className="col-span-1">
                  <Link href="https://www.rainn.org/about-rainn">
                    About RAINN
                  </Link>
                </ListItem>
                <ListItem className="col-span-1">
                  <Link href="https://store.rainn.org/">Store</Link>
                </ListItem>
              </List>
            </Grid>
            <Grid item md={12} className="p-0">
              <Divider orientation="horizontal" variant="fullWidth" />
            </Grid>
            <Grid item md={12} className="py-3">
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={0}
              >
                <Grid item className="">
                  <List className="flex social-icon">
                    <ListItem disableGutters={true}>
                      <Link
                        href="https://facebook.com/rainn01"
                        title="follow us on facebook"
                      >
                        <FaFacebookF className="text-white text-lg" />
                      </Link>
                    </ListItem>
                    <ListItem disableGutters={true}>
                      <Link
                        href="https://twitter.com/rainn"
                        title="follow us on twitter"
                      >
                        <FaTwitter className="text-white text-lg" />
                      </Link>
                    </ListItem>
                    <ListItem disableGutters={true}>
                      <Link
                        href="https://www.instagram.com/rainn"
                        title="follow us on instagram"
                      >
                        <FaInstagram className="text-white text-lg" />
                      </Link>
                    </ListItem>
                    <ListItem disableGutters={true}>
                      <Link
                        href="https://youtube.com/rainn01"
                        title="follow us on youtube"
                      >
                        <FaYoutube className="text-white text-lg" />
                      </Link>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item className="">
                  <a
                    href="https://donate.rainn.org/"
                    className="button-footer-signup ml-3"
                  >
                    Support RAINN with a donation today.
                  </a>
                </Grid>
                <Grid item>
                  <img
                    src={GuideStar}
                    className="mx-3 p-3"
                    alt="Guidestar paltinum transparency logo"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Typography component="div" className="copyright-links">
          <Container>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={0}
            >
              <List>
                <ListItem disableGutters={true}>
                  <span className="text-tc-1 uppercase text-xs font-bold mx-5 opacity-75">
                    © RAINN 2021
                  </span>
                </ListItem>
              </List>
              <List>
                <ListItem disableGutters={true}>
                  <Link href="https://www.rainn.org/privacy-policy">
                    Privacy Policy
                  </Link>
                </ListItem>
                <ListItem disableGutters={true}>
                  <Link href="https://www.rainn.org/federal-funding-disclaimer">
                    Federal Funding Disclaimer
                  </Link>
                </ListItem>
              </List>
            </Grid>
          </Container>
        </Typography>
      </Typography>
    </div>
  );
};

export default Footer;
