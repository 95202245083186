import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { FiArrowLeft } from "react-icons/fi";
import Api from '../../API/Admin_Api';
import { Link, useHistory } from 'react-router-dom';
import Layout from './Layout/Layout'
import { useSnackBar } from '../../Context/Snackbar/withSnackbar';


interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className="centers-admin-tab-panel-wrap">{children}</Box>
            )}
        </div>
    );
}

function tabProps(index: any) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

function categoryAdd(props: any) {
    const { showSnackBar } = useSnackBar();
    const [id, setId] = useState(props.match.params.id);
    const [path, setPath] = useState(props.location.pathname);
    const history = useHistory();
    const [value, setValue] = useState(0);
    const [categoryType, setCategoryType] = useState<any>('');
    const [category, setCategory] = useState({ en: '', es: '' });
    const [categoryList,setCategoryList] =useState<any>([]);
    // Object for Dynamic Row
    const [subcategory, setSubcategory] = useState([
        { en: '', es: '' },
        { en: '', es: '' }
    ]);

    // Add Row Handler
    const handleAddRow = () => {
        setSubcategory([...subcategory, { en: '', es: '' }]);
    };

    // Remove Row Handler
    const handleRemoveSpecificRow = (index: number) => () => {
        const copySubcategory = [...subcategory];
        copySubcategory.splice(index, 1);
        setSubcategory(copySubcategory);
    };

    // Every TextField Change Handler
    const handleInputChange = (index: number, event: any) => {
        const { name, value } = event.target;
        const copySubcategory = [...subcategory];
        copySubcategory[index] = { ...copySubcategory[index], [name]: value };
        setSubcategory(copySubcategory);
    };

    const handleTabChange = (event: any, newValue: number) => {
        setValue(newValue);
    };

    // Cancel Category Click Handler
    const cancelSubCategory = () => {
        setSubcategory([
            { en: '', es: '' },
            { en: '', es: '' }
        ]);
        setCategoryType('');
    };

    // Add Sub Category Click Handler
    const addSubCategory = () => {
        if (id) {
            const data = subcategory[0];
            Api.updateSubCategory(id, data.en, data.es)
                .then((responce: any) => {
                    if (responce.data && responce.data.success) {
                        showSnackBar(responce.data.message);
                        setSubcategory([
                            { en: '', es: '' },
                            { en: '', es: '' }
                        ]);
                        setCategoryType('');
                        history.push('/category_list');
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        if (error.response.data.message) {
                            showSnackBar(error.response.data.message, 'error');
                        } else if (error.response.data.error) {
                            showSnackBar(error.response.data.error, 'error');
                        } else if (error.response.data.errors) {
                            showSnackBar(error.response.data.errors.join("\n"), 'error');
                        }
                    } else {
                        showSnackBar(error.message, 'error');
                    }
                });

        } else {
            const data = {
                'category_id': categoryType,
                'name': subcategory
            }
            Api.addSubcategory(data)
                .then((responce: any) => {
                    if (responce.data && responce.data.success) {
                        showSnackBar(responce.data.message);
                        setSubcategory([
                            { en: '', es: '' },
                            { en: '', es: '' }
                        ]);
                        setCategoryType('');
                        history.push('/category_list');
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        if (error.response.data.message) {
                            showSnackBar(error.response.data.message, 'error');
                        } else if (error.response.data.error) {
                            showSnackBar(error.response.data.error, 'error');
                        } else if (error.response.data.errors) {
                            showSnackBar(error.response.data.errors.join("\n"), 'error');
                        }
                    } else {
                        showSnackBar(error.message, 'error');
                    }
                });
        }
    };

    // Add Category Click Handler
    const addCategoryHandler = () => {
        if (id) {
            Api.updateCategory(id, category.en, category.es)
                .then((responce: any) => {
                    if (responce.data && responce.data.success) {
                        showSnackBar(responce.data.message);
                        setCategory({ en: '', es: '' });
                        history.push('/category_list');
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        if (error.response.data.message) {
                            showSnackBar(error.response.data.message, 'error');
                        } else if (error.response.data.error) {
                            showSnackBar(error.response.data.error, 'error');
                        } else if (error.response.data.errors) {
                            showSnackBar(error.response.data.errors.join("\n"), 'error');
                        }
                    } else {
                        showSnackBar(error.message, 'error');
                    }
                });

        } else {
            Api.addCategory(category.en, category.es)
                .then((responce: any) => {
                    if (responce.data && responce.data.success) {
                        showSnackBar(responce.data.message);
                        setCategory({ en: '', es: '' });
                        history.push('/category_list');
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        if (error.response.data.message) {
                            showSnackBar(error.response.data.message, 'error');
                        } else if (error.response.data.error) {
                            showSnackBar(error.response.data.error, 'error');
                        } else if (error.response.data.errors) {
                            showSnackBar(error.response.data.errors.join("\n"), 'error');
                        }
                    } else {
                        showSnackBar(error.message, 'error');
                    }
                });
        }
    };

    useEffect(() => {
        const pathName = path.slice(1, path.lastIndexOf('/'));
        if (pathName === 'category_edit') {
            setValue(0);
            if (id) {
                Api.viewCategory(id)
                    .then((responce: any) => {
                        if (responce.data && responce.data.success) {
                            showSnackBar(responce.data.message);
                            setCategory(responce.data.data.name);
                        }
                    })
                    .catch((error) => {
                        if (error.response && error.response.data) {
                            if (error.response.data.message) {
                                showSnackBar(error.response.data.message, 'error');
                            } else if (error.response.data.error) {
                                showSnackBar(error.response.data.error, 'error');
                            } else if (error.response.data.errors) {
                                showSnackBar(error.response.data.errors.join("\n"), 'error');
                            }
                        } else {
                            showSnackBar(error.message, 'error');
                        }
                    });
            }
        }
        else if (pathName === 'subcategory_edit') {
            setValue(1);
            if (id) {
                Api.viewSubCategory(id)
                    .then((responce: any) => {
                        if (responce.data && responce.data.success) {
                            showSnackBar(responce.data.message);
                            setCategoryType(responce.data.data.category_id);
                            setSubcategory([responce.data.data.name]);
                        }
                    })
                    .catch((error) => {
                        if (error.response && error.response.data) {
                            if (error.response.data.message) {
                                showSnackBar(error.response.data.message, 'error');
                            } else if (error.response.data.error) {
                                showSnackBar(error.response.data.error, 'error');
                            } else if (error.response.data.errors) {
                                showSnackBar(error.response.data.errors.join("\n"), 'error');
                            }
                        } else {
                            showSnackBar(error.message, 'error');
                        }
                    });
            }
        }

    }, []);

useEffect(() => {
    Api.getCategoryList()
    .then((response: any) => {
        if (response.data && response.data.success) {
             setCategoryList(response.data.data)
        }
    })
    .catch((error) => {
        if (error.response && error.response.data) {
            if (error.response.data.message) {
                showSnackBar(error.response.data.message, 'error');
            } else if (error.response.data.error) {
                showSnackBar(error.response.data.error, 'error');
            } else if (error.response.data.errors) {
                showSnackBar(error.response.data.errors.join("\n"), 'error');
            }
        } else {
            showSnackBar(error.message, 'error');
        }
    });
}, [])

    return (
        <>
            <Layout>
                <Container>
                    <Typography component="div" className="rainn-lg-container centers-admin-panel">
                        <Typography component="div" className="centers-admin-header">
                            <Typography component="div" className="centers-admin-header-top-bar">
                                <Typography component="h1" className="centers-admin-section-text">
                                    <Link to='/category_list'>
                                        <FiArrowLeft className="inline-block mr-1 text-pc-1" />
                                    </Link>
                                    {id ? 'Update' : 'Add'} Category</Typography>
                            </Typography>
                        </Typography>
                        <Typography component="div" className="mx-3">
                            <Tabs
                                value={value}
                                onChange={handleTabChange}
                                aria-label="scrollable auto tabs example"
                                className="centers-admin-tabs"
                            >
                                <Tab label="Category" disabled={id && value !== 0} {...tabProps(0)} className="centers-admin-tab" />
                                <Tab label="Sub Category" disabled={id && value !== 1} {...tabProps(1)} className="centers-admin-tab" />
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                <Grid container spacing={3} className="centers-admin-tab-panel-contents">
                                    <Grid item md={6} xs={12}>
                                        <TextField className="centers-admin-input" onChange={({ target }) => setCategory(state => ({ ...state, en: target.value }))} value={category.en} label="English" placeholder="Enter text here" />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField className="centers-admin-input" onChange={({ target }) => setCategory(state => ({ ...state, es: target.value }))} value={category.es} label="Spanish" placeholder="Enter text here" />
                                    </Grid>
                                </Grid>
                                <Typography component="div" className="centers-admin-tab-panel-buttons">
                                    <Button className="button-primary mr-3" disabled={category.en === '' || category.es === ''} onClick={addCategoryHandler}>{id ? 'Update' : 'Add'}</Button>
                                    <Button className="button-secondary" onClick={() => setCategory({ en: '', es: '' })}>Cancel</Button>
                                </Typography>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Grid container spacing={3} className="centers-admin-tab-panel-contents">
                                    <Grid item md={5} xs={12}>
                                        <InputLabel id="demo-simple-select" className="label-input">Select Category</InputLabel>
                                        <Select
                                            name="state_code"
                                            labelId="state"
                                            id="demo-simple-select"
                                            placeholder="Select your category type"
                                            className="centers-admin-autocomplete"
                                            value={categoryType}
                                            onChange={(e) => setCategoryType(e.target.value)}
                                        >
                                            {
                                                categoryList?.map((item:any)=>{
                                                   return(
                                                        <MenuItem value={item.id}>{item.name.en}</MenuItem>
                                                   )
                                                })
                                            }
                                         </Select>
                                    </Grid>
                                    <Grid item md={7} xs={12}>
                                    </Grid>

                                    {
                                        subcategory.map((data, i, dataArr) => {
                                            return <React.Fragment key={i}>
                                                <Grid item md={5} xs={12}>
                                                    <TextField className="centers-admin-input" name='en' value={data.en} onChange={event => handleInputChange(i, event)} placeholder='Type Category in English' />
                                                </Grid>
                                                <Grid item md={5} xs={12}>
                                                    <TextField className="centers-admin-input" name='es' value={data.es} onChange={event => handleInputChange(i, event)} placeholder='Type Category in Spanish' />
                                                </Grid>

                                                {
                                                    id ? <></> :
                                                        dataArr.length - 1 === i ?
                                                            <Grid item md={2} xs={12}>
                                                                <Button className="button-icon-sml" onClick={handleAddRow}>+</Button>
                                                            </Grid> :
                                                            <Grid item md={2} xs={12}>
                                                                <Button className="button-icon-sml" onClick={handleRemoveSpecificRow(i)}>-</Button>
                                                            </Grid>

                                                }

                                            </React.Fragment>
                                        })
                                    }

                                </Grid>
                                <Typography component="div" className="centers-admin-tab-panel-buttons">
                                    <Button className="button-primary mr-3" onClick={addSubCategory}>{id ? 'Update' : 'Add'}</Button>
                                    <Button className="button-secondary" onClick={cancelSubCategory}>Cancel</Button>
                                </Typography>
                            </TabPanel>
                        </Typography>
                    </Typography>
                </Container>
            </Layout>
        </>
    );
}

export default categoryAdd