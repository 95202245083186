import { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Container from '@material-ui/core/Container';
import { Autocomplete } from '@material-ui/lab';
import { MdArrowBack } from 'react-icons/md';
import Layout from './Layout/Layout';
import Admin_Api from '../../API/Admin_Api';
import Api from '../../API/Api';
import { Link, useHistory } from 'react-router-dom';
import { useSnackBar } from '../../Context/Snackbar/withSnackbar';
import { MenuItem } from '@material-ui/core';

function nationalCenterView(props: any) {
  const { showSnackBar } = useSnackBar();
  const [id, setId] = useState(props.match.params.id);
  const history = useHistory();
  const contact = {
    hotline_phone: '',
  };
  const center = {
    keywords: '',
    name: '',
    website: '',
    address1: '',
    address2: '',
    county: '',
    city: '',
    zip: '',
    state_code: '',
  };

  const [Center, setCenter] = useState(center);
  const [errors, setErrors] = useState({} as any);
  const [Contact, setContact] = useState(contact);
  const [population, setPopulation] = useState(([] as any | null) as any);
  const [Provider, setProvider] = useState(([] as any | null) as any);
  const [violence, setViolence] = useState(([] as any | null) as any);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setContact({
      ...Contact,
      [name]: value,
    });
    validate({ [name]: value }, e);
  };
  const handleCenter = (e: any) => {
    const { name, value } = e.target;
    setCenter({
      ...Center,
      [name]: value,
    });
    validate({ [name]: value }, e);
  };
  const { hotline_phone } = Contact;
  const {
    name,
    website,
    keywords,
    address1,
    address2,
    county,
    city,
    zip,
    state_code,
  } = Center;
  const entryInput = {
    data: {
      categories: [
        {
          name: { en: '', es: '' },
          options: [
            {
              id: '',
              name: {
                en: '',
                es: '',
              },
            },
          ],
        },
        {
          name: { en: '', es: '' },
          options: [
            {
              id: '',
              name: {
                en: '',
                es: '',
              },
            },
          ],
        },
        {
          name: { en: '', es: '' },
          options: [
            {
              id: '',
              name: {
                en: '',
                es: '',
              },
            },
          ],
        },
      ],
      states: [],
      center_count: [],
    },
  };
  const [formFieldInfo, setformFieldInfo] = useState(entryInput);
  useEffect(() => {
    Api.getFormData()
      .then((responce: any) => {
        if (responce.data && responce.data.success) {
          // showSnackBar(responce.data.message);
          setformFieldInfo(responce.data);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });

    if (id === undefined) {
      setContact(contact);
      setCenter(center);
      setPopulation([]);
      setProvider([]);
      setViolence([]);
    } else {
      const result = Admin_Api.NationalCenterView(id)
        .then((responce: any) => {
          if (responce.data && responce.data.success) {
            const info = responce.data.data;
            setContact({ hotline_phone: info.hotline_phone });
            setCenter({
              keywords: info.keywords,
              name: info.name,
              website: info.website,
              address1: info.address1,
              address2: info.address2,
              county: info.county,
              city: info.city,
              zip: info.zip,
              state_code: info.state_code,
            });
            info.categories.map((data: any) => {
              if (data.id === 2) {
                const populations = data.options.map((res: any) => res);
                setPopulation((populations as any | []) as any);
              }
              if (data.id === 1) {
                const provider = data.options.map((res: any) => res);
                setProvider((provider as any | []) as any);
              }
              if (data.id === 3) {
                const violences = data.options.map((res: any) => res);
                setViolence((violences as any | []) as any);
              }
            });
            // const populations =info.categories && info.categories[0] && info.categories[0]?.options.map((res: any) => res);
            // setPopulation(populations as any | [] as any)
            // const provider =info.categories && info.categories[1] && info.categories[1]?.options.map((res: any) => res);
            // setProvider(provider as any | [] as any)
            // const violences =info.categories && info.categories[2] && info.categories[2]?.options.map((res: any) => res);
            // setViolence(violences as any | [] as any)
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.data.message) {
              showSnackBar(error.response.data.message, 'error');
            } else if (error.response.data.error) {
              showSnackBar(error.response.data.error, 'error');
            } else if (error.response.data.errors) {
              showSnackBar(error.response.data.errors.join('\n'), 'error');
            }
          } else {
            showSnackBar(error.message, 'error');
          }
        });
    }
  }, []);
  //validate

  const validate: any = (
    fieldValues = {} as any,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const temp: any = { ...errors };
    const { name, type } = event.target;

    temp[name] = fieldValues[name] ? '' : 'This field is required.';

    if (name === 'website') {
      temp[name] = fieldValues[name] ? '' : 'This field is required.';
      if (fieldValues[name])
        temp[
          name
        ] = /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/.test(
          fieldValues[name]
        )
          ? ''
          : 'Website format is not valid.';
    }
    if (type === 'number' && name !== 'zip') {
      temp[name] = fieldValues[name] ? '' : 'This field is required.';
      if (fieldValues[name]) {
        temp[name] =
          fieldValues[name].length <= 11 && fieldValues[name].length >= 10
            ? ''
            : 'Number is not valid.';
      }
    }

    setErrors({
      ...temp,
    });
  };

  const formIsValid = () => {
    const isValid =
      name &&
      hotline_phone &&
      website &&
      keywords &&
      state_code &&
      address1 &&
      address2 &&
      county &&
      city &&
      zip &&
      population.length > 0 &&
      Provider.length > 0 &&
      violence.length > 0 &&
      Object.values(errors).every((x) => x === '');

    return isValid;
  };

  const handleSubmit = (e: any) => {
    const populationID = population.map((res: any) => res.id);
    const ProviderID = Provider.map((res: any) => res.id);
    const violenceID = violence.map((res: any) => res.id);
    const category_id = [...populationID, ...ProviderID, ...violenceID];
    const data = { category_id, ...Center, ...Contact };
    if (id === undefined) {
      Admin_Api.NationalCenterAdd(data)
        .then((responce: any) => {
          if (responce.data && responce.data.success) {
            showSnackBar(responce.data.message);
            history.push('/national_center_list');
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.data.message) {
              showSnackBar(error.response.data.message, 'error');
            } else if (error.response.data.error) {
              showSnackBar(error.response.data.error, 'error');
            } else if (error.response.data.errors) {
              showSnackBar(error.response.data.errors.join('\n'), 'error');
            }
          } else {
            showSnackBar(error.message, 'error');
          }
        });
    } else {
      Admin_Api.NationalCenterEdit(data, id)
        .then((responce: any) => {
          if (responce.data && responce.data.success) {
            showSnackBar(responce.data.message);
            history.push('/national_center_list');
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.data.message) {
              showSnackBar(error.response.data.message, 'error');
            } else if (error.response.data.error) {
              showSnackBar(error.response.data.error, 'error');
            } else if (error.response.data.errors) {
              showSnackBar(error.response.data.errors.join('\n'), 'error');
            }
          } else {
            showSnackBar(error.message, 'error');
          }
        });
    }
  };
  const handleClear = (e: any) => {
    setContact(contact);
    setCenter(center);
    setPopulation([]);
    setProvider([]);
  };
  return (
    <>
      <Layout>
        <Container>
          <Typography component="div" className="centers-admin-panel">
            <Typography component="div" className="centers-admin-header">
              <Typography
                component="div"
                className="centers-admin-header-top-bar"
              >
                <Typography
                  component="h3"
                  className="centers-admin-section-text flex items-center"
                >
                  <Link to="/national_center_list">
                    <MdArrowBack className="mr-1 text-pc-1" />
                  </Link>
                  Center Details
                </Typography>
              </Typography>
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  className="centers-admin-input md:w-1/2"
                  label="Name"
                  name="name"
                  value={name}
                  required
                  error={errors.name}
                  {...(errors.name && {
                    error: true,
                    helperText: errors.name,
                  })}
                  onChange={handleCenter}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="centers-admin-input md:w-1/2"
                  name="address1"
                  value={address1}
                  required={address1 === ''}
                  error={errors.address1}
                  {...(errors.address1 && {
                    error: true,
                    helperText: errors.address1,
                  })}
                  onChange={handleCenter}
                  label="Address"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="centers-admin-input md:w-1/2"
                  name="address2"
                  value={address2}
                  required={address2 === ''}
                  error={errors.address2}
                  {...(errors.address2 && {
                    error: true,
                    helperText: errors.address2,
                  })}
                  onChange={handleCenter}
                  label="Enter Address #2 here"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="centers-admin-input md:w-1/2"
                  name="city"
                  value={city}
                  required={city === ''}
                  error={errors.city}
                  {...(errors.city && {
                    error: true,
                    helperText: errors.city,
                  })}
                  onChange={handleCenter}
                  label="City"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="centers-admin-input md:w-1/2"
                  name="county"
                  value={county}
                  required={county === ''}
                  error={errors.county}
                  {...(errors.county && {
                    error: true,
                    helperText: errors.county,
                  })}
                  onChange={handleCenter}
                  label="Enter County here"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="state_code"
                  select
                  label="Select state"
                  id="demo-simple-select"
                  className="centers-admin-autocomplete md:w-1/2"
                  value={state_code}
                  required={state_code === ''}
                  error={errors.state_code}
                  {...(errors.state_code && {
                    error: true,
                    helperText: errors.state_code,
                  })}
                  onChange={handleCenter}
                >
                  {formFieldInfo?.data.states.map((res: any) => {
                    return <MenuItem value={res.code}>{res.name}</MenuItem>;
                  })}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  type="number"
                  className="centers-admin-input md:w-1/2"
                  label="Enter zip code here"
                  name="zip"
                  value={zip}
                  required={zip === ''}
                  error={errors.zip}
                  {...(errors.zip && {
                    error: true,
                    helperText: errors.zip,
                  })}
                  onChange={handleCenter}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  className="centers-admin-input md:w-1/2"
                  label="Hotline Phone Number"
                  name="hotline_phone"
                  value={hotline_phone}
                  onChange={handleChange}
                  required
                  error={errors.hotline_phone}
                  {...(errors.hotline_phone && {
                    error: true,
                    helperText: errors.hotline_phone,
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  className="centers-admin-input md:w-1/2 md:pr-3"
                  label="Website"
                  name="website"
                  value={website}
                  onChange={handleCenter}
                  required
                  error={errors.website}
                  {...(errors.website && {
                    error: true,
                    helperText: errors.website,
                  })}
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  limitTags={1}
                  disableClearable
                  clearOnEscape
                  options={formFieldInfo.data.categories[0]?.options}
                  getOptionLabel={(option: any) => {
                    return option?.name?.en;
                  }}
                  value={population}
                  onChange={(event, newInput) => {
                    if (newInput !== null) {
                      setPopulation(newInput as any);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="centers-admin-autocomplete md:w-1/2"
                      required
                      label={`${formFieldInfo.data.categories[0]?.name.en}`}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  limitTags={2}
                  id="provider"
                  options={formFieldInfo.data.categories[1]?.options}
                  getOptionLabel={(option) => option.name?.en}
                  value={Provider}
                  onChange={(event, newInput) => {
                    if (newInput !== null) {
                      setProvider(newInput as any);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="centers-admin-autocomplete md:w-1/2"
                      required
                      label={`${formFieldInfo.data.categories[1]?.name.en}`}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  limitTags={2}
                  id="violence"
                  options={formFieldInfo.data.categories[2]?.options}
                  getOptionLabel={(option) => option.name?.en}
                  value={violence}
                  onChange={(event, newInput) => {
                    if (newInput !== null) {
                      setViolence(newInput as any);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="centers-admin-autocomplete md:w-1/2"
                      required
                      label={`${formFieldInfo.data.categories[2]?.name.en}`}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <label className="text-tc-5 text-xs block">Keywords</label>
                <TextareaAutosize
                  className="centers-admin-textarea md:w-1/2"
                  rowsMin={5}
                  name="keywords"
                  value={keywords}
                  onChange={handleCenter}
                />
              </Grid>
            </Grid>
            <Typography
              component="div"
              className="border-t border-tc-6 mt-3 p-3 text-right"
            >
              <Button
                className="button-primary mr-2"
                //  disabled={!formIsValid()}
                onClick={handleSubmit}
              >
                Submit
              </Button>
              <Button className="button-secondary" onClick={handleClear}>
                Clear
              </Button>
            </Typography>
          </Typography>
        </Container>
      </Layout>
    </>
  );
}

export default nationalCenterView;
