import React, { useEffect, useState } from 'react';
import logo from '../../../assets/images/logo.jpg';
import Typography from '@material-ui/core/Typography';
import { MdDehaze, MdKeyboardArrowDown } from 'react-icons/md';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Link, Redirect, useHistory } from 'react-router-dom';
import AdminNavigation from './AdminNavigation';
import Avatar from '@material-ui/core/Avatar';
import Admin_Api from '../../../API/Admin_Api';
import { useSnackBar } from '../../../Context/Snackbar/withSnackbar';

const Header: React.FunctionComponent = (props:any) => {
  const { showSnackBar } = useSnackBar();
  const user = JSON.parse(localStorage.getItem('token') as any);
  const [proClick, setClick] = useState(false);
  const handlePro = (event: any) => {
    setClick(!proClick);
  };

  const history = useHistory();
  const [width, setWidth] = useState(window.innerWidth);
  const [navIcon, setNavIcon] = useState(false);
  const byteCharacters = atob(user?.userDetails.logo);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  const image = new Blob([byteArray], { type: 'image/jpeg' });
  const imageUrl = URL.createObjectURL(image);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
  }, []);
  const handleNavIcon = () => {
    setNavIcon(!navIcon);
  };
  const handleLogOut = () => {
    localStorage.removeItem('token');
    history.push('/login');
  };
  const handleCenterChange = (id: any) => {
    Admin_Api.Center_change(id)
    .then((response: any) => {
      localStorage.setItem('token', JSON.stringify({ token: `${response.data.access_token}`, role: `${response.data.current_user.role_id}`,other_centers: response.data.other_center ,"userDetails" : {first_name:`${response.data.current_user.firstName}`, last_name:`${response.data.current_user.lastName}`,logo:`${response.data.current_user.logo}`}}))
      history.go(0)
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        if (error.response.data.message) {
          showSnackBar(error.response.data.message, 'error');
        } else if (error.response.data.error) {
          showSnackBar(error.response.data.error, 'error');
        } else if (error.response.data.errors) {
          showSnackBar(error.response.data.errors.join("\n"), 'error');
        }
      } else {
        showSnackBar(error.message, 'error');
      }
    })
  };

  return (
    <>
      <Typography
        component="div"
        className="centers-header centers-admin-header-top"
      >
        <Typography component="div" className="centers-admin-header-content">
          <Typography component="div">
            <Typography component="div" className="centers-admin-profile">
              <Avatar src={imageUrl} className="admin-avtar-sml">
                {user?.userDetails.first_name.charAt(0)}
                {user?.userDetails.last_name.charAt(0)}
              </Avatar>

              <MdKeyboardArrowDown
                onClick={handlePro}
                className="cursor-pointer text-white h-6 w-6"
              />
              {proClick && (
                <Typography
                  component="div"
                  className="centers-admin-profile-menu"
                >
                  <List>
                    <ListItem>
                      <Typography component="div" className="profile-detail">
                        <Avatar src={imageUrl} className="admin-avtar-lg">
                          {user?.userDetails.first_name.charAt(0)}
                          {user?.userDetails.last_name.charAt(0)}
                        </Avatar>
                        <Typography component="div">
                          <Typography component="p" className="profile-name">
                            {user?.userDetails.first_name}&nbsp;{' '}
                            {user?.userDetails.last_name}
                          </Typography>
                          <Typography component="p" className="profile-roll">
                            {user?.role === '1'
                              ? 'Rainn Admin'
                              : 'Center Admin'}
                          </Typography>
                        </Typography>
                      </Typography>
                    </ListItem>
                    {user.other_centers.map((data: any) => {
                      return (
                        <>
                          <ListItem>
                            <Link
                              to="#"
                              onClick={() => handleCenterChange(data.id)}
                            >
                              {data.name}
                            </Link>
                          </ListItem>
                        </>
                      );
                    })}

                    <ListItem>
                      <Link to="/change_password">Change Password</Link>
                    </ListItem>
                    <ListItem>
                      <Link to="/contact_rainn">Contact RAINN</Link>
                    </ListItem>
                    <ListItem>
                      <Link to="#" onClick={handleLogOut}>
                        Sign Out
                      </Link>
                    </ListItem>
                  </List>
                </Typography>
              )}
            </Typography>
          </Typography>
        </Typography>

        <Typography component="div" className="centers-menu">
          <Typography component="div" className="centers-nav">
            <Typography
              component="div"
              className="flex items-center justify-between"
            >
              <Link to="#" className="brand-logo">
                <img src={logo} className="w-full" alt="rainn-logo" />
              </Link>
              <MdDehaze
                className="centers-menu-icon desktop-hide"
                onClick={handleNavIcon}
              />
            </Typography>
            {window.innerWidth > 760 && <AdminNavigation />}
            {navIcon && window.innerWidth <= 760 && <AdminNavigation />}
          </Typography>
        </Typography>
      </Typography>
    </>
  );
};

export default Header;
