import React from 'react';
import { Route } from "react-router-dom";

type Props = {
    [key: string]: any;
};

const RouteSubRoute: React.FunctionComponent<Props> = (route) => {
    return (
        <Route
            path={route.path}
            render={props => (
                // pass the sub-routes down to keep nesting
                <route.component {...props} routes={route.routes} />
            )}
        />
    );
}

export default RouteSubRoute;