import { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Container from '@material-ui/core/Container';
import 'date-fns';
import NationalList from './NationalList';
import { useForm } from '../../hooks/useForm';
import api from '../../API/Api';
import { Link } from 'react-router-dom';
import { useSnackBar } from '../../Context/Snackbar/withSnackbar';

function NationalSearchList() {
  const { showSnackBar } = useSnackBar();

  const entryInput = {
    data: {
      categories: [
        { name: { en: '', es: '' }, options: [] },
        { name: { en: '', es: '' }, options: [] },
        {
          name: { en: '', es: '' },
          options: [
            {
              id: '',
              name: {
                en: '',
                es: '',
              },
            },
          ],
        },
      ],
      states: [],
      center_count: [],
    },
  };
  const [formFieldInfo, setformFieldInfo] = useState(entryInput);
  const [population, setPopulation] = useState([]);
  const [Clear, setClear] = useState(false);
  const [Provider, setProvider] = useState([]);
  const [populationCategoryId, setpopulationCategoryId] = useState([]);
  const [providerCategoryId, setproviderCategoryId] = useState([]);
  const categoryId = [...providerCategoryId, ...populationCategoryId];
  const searchInitial = {
    centerName: '',
    categoryId: [],
    clear:false
  }
  const [searchData, setSearchData] = useState(searchInitial);

  useEffect(() => {
    api.getFormData()
      .then((response: any) => {
        if (response.data && response.data.success) {
          setformFieldInfo(response.data);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join("\n"), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  }, []);

  const Initial = {
    centerName: '',
    // zip: '',
  };
  const { Values, setValues, handleChange, resetForm } = useForm(Initial);

  const handleApply = () => {
    setSearchData({
      centerName: Values.centerName,
      categoryId: categoryId,
      clear:true
    });
    setClear(true)
  };
  const handleClear = () => {
    setValues(Initial);
    setClear(false)
    setPopulation([]);
    setProvider([]);
    setpopulationCategoryId([]);
    setproviderCategoryId([]);
    setSearchData(searchInitial);
  };
const handleApplyClick =()=>{
  setClear(false)
}
  return (
    <>
      <Container>
        <Typography
          component="div"
          className="rainn-lg-container centers-admin-panel"
        >
          <Typography
            component="div"
            className="flex justify-between items-center py-5 border-b border-tc-6 mb-3"
          >
            <Typography component="h1" className="centers-admin-section-text">
              National Resource Center List
            </Typography>
            <Button className="button-primary">
              <Link to="/national_center_add">Add Center</Link>
            </Button>
          </Typography>
          <Grid container spacing={3} className="bg-tc-7 w-full m-0">
            <Grid item md={4} xs={12}>
              <TextField
                className="centers-admin-input"
                label="Search Center Name"
                name="centerName"
                value={Values.centerName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Autocomplete
                multiple
                limitTags={2}
                id="population"
                options={formFieldInfo.data.categories[0].options}
                getOptionLabel={(option) => option.name?.en}
                value={population}
                onChange={(event, newInput) => {
                  if (newInput !== null) {
                    setpopulationCategoryId(
                      JSON.parse(JSON.stringify(newInput)).map(
                        (doc: { id: string }) => doc.id
                      )
                    );
                    setPopulation(newInput as any);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="centers-admin-autocomplete"
                    label={`${formFieldInfo.data.categories[0].name.en}`}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Autocomplete
                multiple
                limitTags={2}
                id="provider"
                options={formFieldInfo.data.categories[1].options}
                getOptionLabel={(option) => option.name?.en}
                value={Provider}
                onChange={(event, newInput) => {
                  if (newInput !== null) {
                    setproviderCategoryId(
                      JSON.parse(JSON.stringify(newInput)).map(
                        (doc: { id: string }) => doc.id
                      )
                    );
                    setProvider(newInput as any);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="centers-admin-autocomplete"
                    label={`${formFieldInfo.data.categories[1].name.en}`}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} className="flex justify-end">
              <Button className="button-primary mr-3" onClick={handleApply}>
                Apply
              </Button>
              <Button className="button-transparent" onClick={handleClear}>
                Clear
              </Button>
            </Grid>
          </Grid>
        </Typography>
        <NationalList
          centerName={searchData.centerName}
          categoryIds={searchData.categoryId}
          click={handleApplyClick}
          Clear= {Clear}
        />
      </Container>
    </>
  );
}
export default NationalSearchList;
