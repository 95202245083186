import { useEffect, useState } from 'react';
import { MdArrowBack } from 'react-icons/md';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Layout from './Layout/Layout';
import Admin_Api from '../../API/Admin_Api';
import { Link, useHistory } from 'react-router-dom';
import { useSnackBar } from '../../Context/Snackbar/withSnackbar';
import {
  Avatar,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';

function changeRequest(props: {
  match: { params: { id: any }; path: string };
}) {
  const { showSnackBar } = useSnackBar();
  const initial = {
    center: [
      {
        field_name: '',
        old_value: '',
        new_value: '',
      },
    ],
    contact: [
      {
        field_name: '',
        old_value: '',
        new_value: '',
      },
    ],
    alternateContact: [
      {
        field_name: '',
        old_value: '',
        new_value: '',
      },
    ],
    categories: {
      field_name: '',
      old_value: [],
      new_value: [],
    },
  };
  const [Record, setRecord] = useState<any>(initial);
  const [remark, setRemark] = useState('');
  const history = useHistory();
  const [id, setId] = useState(props.match.params.id);
  const [centerTypeId, setCenterTypeId] = useState('');

  useEffect(() => {
    const getdata = async () => {
      await Admin_Api.View_request(id)
        .then((responce: any) => {
          if (responce.data && responce.data.success) {
            showSnackBar(responce.data.message);
            setRecord(responce.data.data);
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.data.message) {
              showSnackBar(error.response.data.message, 'error');
            } else if (error.response.data.error) {
              showSnackBar(error.response.data.error, 'error');
            } else if (error.response.data.errors) {
              showSnackBar(error.response.data.errors.join('\n'), 'error');
            }
          } else {
            showSnackBar(error.message, 'error');
          }
        });
    };

    getdata();
  }, []);

  function handleApprove(e: any) {
    e.preventDefault();
    Admin_Api.Approve_change_request(remark, id, centerTypeId)
      .then((responce: any) => {
        if (responce.data && responce.data.success) {
          showSnackBar(responce.data.message);
          history.push('/approve_request_list');
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  }

  function handleDecline(e: any) {
    e.preventDefault();
    Admin_Api.Decline_change_request(remark, id, centerTypeId)
      .then((responce: any) => {
        if (responce.data && responce.data.success) {
          showSnackBar(responce.data.message);
          history.push('/approve_request_list');
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join('\n'), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  }

  const centerChange = Record.center.map((res: any) => {
    if (res.field_key === 'logo') {
      let byteCharacters: any, imageUrl, oldImageUrl;
      if (res.new_value) {
        byteCharacters = atob(res.new_value);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const image = new Blob([byteArray], { type: 'image/jpeg' });
        imageUrl = URL.createObjectURL(image);
      }
      if (res.old_value) {
        byteCharacters = atob(res.old_value);

        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const image = new Blob([byteArray], { type: 'image/jpeg' });
        oldImageUrl = URL.createObjectURL(image);
      }

      return (
        <>
          <TableRow>
            <TableCell>{res.field_name}</TableCell>
            <TableCell>
              <Avatar src={oldImageUrl} className="admin-avtar-sml" />
            </TableCell>
            <TableCell>
              <Avatar src={imageUrl} className="admin-avtar-sml" />
            </TableCell>
          </TableRow>
        </>
      );
    } else {
      return (
        <>
          <TableRow>
            <TableCell>{res.field_name}</TableCell>
            <TableCell>{res.old_value}</TableCell>
            <TableCell>{res.new_value}</TableCell>
          </TableRow>
        </>
      );
    }
  });
  return (
    <>
      <Layout>
        <Container>
          <Typography component="div" className="centers-admin-panel">
            <Typography component="div" className="centers-admin-header">
              <Typography
                component="div"
                className="centers-admin-header-top-bar"
              >
                <Typography
                  component="h3"
                  className="centers-admin-section-text flex items-center"
                >
                  <Link to="/approve_request_list">
                    <MdArrowBack className="mr-1 text-pc-1" />
                  </Link>
                  Change Request
                </Typography>
              </Typography>
            </Typography>
            <Typography component="div" className="centers-admin-table">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="capitalize">DETAILS</TableCell>
                      <TableCell className="capitalize">OLD DATE</TableCell>
                      <TableCell className="capitalize">NEW DATE</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Record.alternateContact?.map((res: any) => {
                      return (
                        <>
                          <TableRow>
                            <TableCell>{res.field_name}</TableCell>
                            <TableCell>{res.old_value}</TableCell>
                            <TableCell>{res.new_value}</TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                    {centerChange}
                    {Record.contact?.map((res: any) => {
                      return (
                        <>
                          <TableRow>
                            <TableCell>{res.field_name}</TableCell>
                            <TableCell>{res.old_value}</TableCell>
                            <TableCell>{res.new_value}</TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                    <TableRow>
                      <TableCell>{Record.user?.field_name}</TableCell>
                      <TableCell>
                        {Record.user?.old_value?.map((res: any) => {
                          return (
                            <>
                              <div>
                                <b>First name</b> :<span>{res.first_name}</span>
                                
                              </div>
                              <div>
                                <b>Last name</b> :<span>{res.last_name}</span>
                              </div>
                              <div>
                                <b>Email</b> :<span>{res.email}</span>
                              </div>
                              <div>
                                <b>Title</b> :<span>{res.title}</span>
                              </div>
                              <div>
                                <b>Phone</b> :<span>{res.phone}</span>
                              </div>
                              <div>
                                <b>Primary contact</b> :
                                <span>{res.is_primary== true ? "Yes" : "No"}</span>
                              </div>
                              <div>
                                <b>Ar manager</b> :
                                <span>{res.is_ar_manager== true ? "Yes" : "No"}</span>
                              </div>
                              <div>
                                <b>volunteer</b> :
                                <span>{res.is_volunteer== true ? "Yes" : "No"}</span>
                              </div>
                              <hr className="mb-2 mt-2" />
                            </>
                          )
                        })}
                      </TableCell>
                      <TableCell>
                        {Record.user?.new_value?.map((res: any) => {
                          return (
                            <>
                              <span>
                                <b>First name</b> :<span>{res.first_name}</span>
                              </span>
                              <div>
                                <b>Last name</b> :<span>{res.last_name}</span>
                              </div>
                              <div>
                                <b>Email</b> :<span>{res.email}</span>
                              </div>
                              <div>
                                <b>Title</b> :<span>{res.title}</span>
                              </div>
                              <div>
                                <b>Phone</b> :<span>{res.phone}</span>
                              </div>
                              <div>
                                <b>Primary contact</b> :
                                <span>{res.is_primary == true ? "Yes" : "No"}</span>
                              </div>
                              <div>
                                <b>Ar manager</b> :
                                <span>{res.is_ar_manager== true ? "Yes" : "No"}</span>
                              </div>
                              <div>
                                <b>volunteer</b> :
                                <span>{res.is_volunteer== true ? "Yes" : "No"}</span>
                              </div>
                              <hr className="mb-2 mt-2" />
                            </>
                          )
                        })}
                      </TableCell>
                    </TableRow>
                    {Record.categories?.field_name != '' && (
                      <TableRow>
                        <TableCell>{Record.categories?.field_name}</TableCell>
                        <TableCell>
                          {Record.categories?.old_value?.map((res: any) => {
                            return (
                              <>
                                <b>{res.name.en}</b> :
                                {res.options?.map((val: any) => {
                                  return <Typography>{val.name.en}</Typography>
                                })}
                              </>
                            );
                          })}
                        </TableCell>
                        <TableCell>
                          {Record.categories?.new_value?.map((res: any) => {
                            return (
                              <>
                                <b>{res.name.en}</b> :
                                {res.options?.map((val: any) => {
                                  return <Typography>{val.name.en}</Typography>
                                })}
                              </>
                            );
                          })}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Typography
                component="div"
                className="flex justify-end pt-2 border-t border-tc-6"
              >
                <Button
                  type="submit"
                  className="button-primary mr-3"
                  onClick={(e) => handleApprove(e)}
                >
                  Approve
                </Button>
                <Button
                  type="submit"
                  className="button-primary mr-3"
                  onClick={(e) => handleDecline(e)}
                >
                  Decline
                </Button>
              </Typography>
            </Typography>
          </Typography>
        </Container>
      </Layout>
    </>
  );
}

export default changeRequest;
