import React, { useState } from 'react';
import { Chart } from 'react-google-charts';
const Map = (props) => {
  const options = {
    backgroundColor: {
      fill: 'transparent',
      stroke: 'transparent',
      strokeWidth: 0,
    },
    colorAxis: { minValue: 0, maxValue: 1, colors: ['#ccc', '#ccc'] },
    legend: 'none',
    datalessRegionColor: '#1f83e0',
    displayMode: 'text',
    enableRegionInteractivity: 'true',
    resolution: 'provinces',
    sizeAxis: { minValue: 1, maxValue: 1, minSize: 10, maxSize: 10 },
    region: 'US',
    keepAspectRatio: true,
    tooltip: {
      textStyle: { color: '#444444' },
      trigger: 'focus',
      isHtml: true,
    },
  }

  const data = [
    [
      'Lat',
      'Lon',
      'Count',
      {
        role: 'tooltip',
        p: { html: true },
      },
    ],
    ...props.centers.map((result) => [
      result.latitude,
      result.longitude,
      result.count.toString(),
      result.stateCode.toString(),
    ]),
  ];
  const chartEvent = [
    {
      eventName: 'select',
      callback: ({ chartWrapper }) => {
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();
        if (selection.length === 1) {
          var selectedRow = selection[0].row + 1;
          var selectedRegion = data[selectedRow];
          props.getCentersFromMap(selectedRegion[3]);
        }
      },
    },
  ];
  return (
    <div className="centers-map">
      <Chart
        chartVersion="42"
        options={options}
        chartType="GeoChart"
        data={data}
        chartEvents={chartEvent}
      />
    </div>
  );
};

export default Map;
