import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useForm } from '../../hooks/useForm'
import Button from '@material-ui/core/Button'
import { useSnackBar } from '../../Context/Snackbar/withSnackbar'
import Api from '../../API/Api'
import { IconButton, Input, InputAdornment, InputLabel } from '@material-ui/core'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md'

const LogIn_component: React.FunctionComponent = () => {
  const { showSnackBar } = useSnackBar();

  const Initial = {
    email: '',
    password: '',
    showPassword: false
  }
  const history = useHistory()
  const { Values,
    setValues,
    handleChange,
    resetForm } = useForm(Initial)
  const [Token, setToken] = useState('')

  const handleClickShowPassword = () => {
    setValues({ ...Values, showPassword: !Values.showPassword });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleLogin = async (e: any) => {
    e.preventDefault();
    const { email, password } = Values

    await Api.logInAPI(email, password)
      .then((responce: any) => {
          localStorage.setItem('token', JSON.stringify({ token: `${responce.data.access_token}`, role: `${responce.data.current_user.role_id}`,other_centers: responce.data.other_center ,"userDetails" : {first_name:`${responce.data.current_user.firstName}`, last_name:`${responce.data.current_user.lastName}`,logo:`${responce.data.current_user.logo}`}}))
          setToken(responce.data.access_token)

          if(responce.data.access_token){
          //   if(responce.data.current_user.role_id == '2')
          //   {
          // history.push('/information');
          //   }
            if(responce.data.current_user.role_id == '1'){
          history.push('/center_list');
            }
            else{
          history.push('/information');

            }
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            showSnackBar(error.response.data.message, 'error');
          } else if (error.response.data.error) {
            showSnackBar(error.response.data.error, 'error');
          } else if (error.response.data.errors) {
            showSnackBar(error.response.data.errors.join("\n"), 'error');
          }
        } else {
          showSnackBar(error.message, 'error');
        }
      });
  }

  return (
    <form onSubmit={handleLogin}>
      <Typography component="div" className="centers-login-container mb-48 mt-20">
        <Typography component="h1" className="centers-login-header ">Account Login</Typography>
        <Typography component="h4" className="centers-login-text">We are glad to see you again!</Typography>
        <Input
          className="centers-login-input rounded-b-none"
          placeholder="Username/Email"
          type="email"
          name="email"
          value={Values.email}
          onChange={handleChange}
        />
          <Input
            id="standard-adornment-password"
          className="centers-login-input rounded-t-none border-t-0"
            type={Values.showPassword ? 'text' : 'password'}
            value={Values.password}
          name="password"
            placeholder="Password"
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {Values.showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        <Typography component="div" className="my-4 flex items-center justify-between">
          <Button className="button-primary" type="submit">Login</Button>
          <Link to="/forgot_password" className="text-sm">Forgot Password?</Link>
        </Typography>
        <Typography component="p" className="text-sm text-tc-3">New User? <Link to="/registration" className="">Sign Up</Link>
         <Link to="/application_no" className="text-sm float-right">Enter your Application number</Link>
        </Typography>
      </Typography>
    </form>
  )
}

export default LogIn_component
