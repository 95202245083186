import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import { MdVisibility, MdModeEdit, MdUpdate, MdCheckCircle, MdCancel} from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import Admin_Api from '../../API/Admin_Api';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import { useSnackBar } from '../../Context/Snackbar/withSnackbar';

interface Data {
  id: any;
  city: string;
  createdDate: any;
  requestType: string;
  centerName: string;
  address1: 'string';
  address2: 'string';
  requestTypeId: number;
  ACTION: any;
}

type Order = 'asc' | 'desc';
interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'centerName', numeric: false, disablePadding: true, label: 'CENTER NAME' },
  { id: 'city', numeric: false, disablePadding: false, label: 'LOCATION' },
  { id: 'requestType', numeric: false, disablePadding: false, label: 'REQUEST TYPE' },
  { id: 'createdDate', numeric: false, disablePadding: false, label: 'REQUESTED DATE' },
  { id: 'ACTION', numeric: false, disablePadding: false, label: 'ACTION' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'left'}
            padding={headCell.disablePadding ? 'default' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

export default function EnhancedTable() {
  const { showSnackBar } = useSnackBar();
  const initial: any = [
    {
      id: '',
      centerName: '',
      address1: '',
      address2: '',
      city: '',
      zip: '',
      stateCode: '',
      requestTypeId: '',
      requestType: "",
      createdDate: ""
    },
  ];
  const classes = useStyles();
  const [rows, setRows] = useState(initial);
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('createdDate');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = React.useState(true);
  const [emptyData, setemptyData] = React.useState(false);
  const history = useHistory()

  useEffect(() => {
    const getdata = async () => {
      await Admin_Api.Approve_requestlist(
        page + 1,
        rowsPerPage,
        order,
        orderBy
      ).then((response: any) => {
        if (response.data && response.data.success) {
          setLoading(false);
          if (!response.data.data.records.length) {
            setemptyData(true);
          } else {
            setRows(response.data.data.records);
            setCount(response.data.data.records_total);
          }
        }
      })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.data.message) {
              showSnackBar(error.response.data.message, 'error');
            } else if (error.response.data.error) {
              showSnackBar(error.response.data.error, 'error');
            } else if (error.response.data.errors) {
              showSnackBar(error.response.data.errors.join("\n"), 'error');
            }
          } else {
            showSnackBar(error.message, 'error');
          }
        });
    };

    getdata();
  }, [page, rowsPerPage, order, orderBy]);



  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSurvivor = (id: any) => {
    history.push(`/feedback/${id}`)
  }

  return (
    <Container>
      <Typography component="div" className="rainn-lg-container centers-admin-panel">
        <Typography component="div" className="flex justify-between items-center py-5 border-b border-tc-6">
          <Typography component="h1" className="centers-admin-section-text">Approve Request</Typography>
        </Typography>
        {emptyData ? <Typography component="div" className="text-center pt-10 pb-10">No pending data</Typography> :
          <Typography component="div" className="centers-admin-table">
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                {loading ? (
                  <CircularProgress disableShrink />
                ) : (
                  <TableBody>
                    {rows?.map((row: any, index: any) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow>
                          <TableCell component="th" id={labelId} scope="row">
                            {row.centerName}
                          </TableCell>
                          <TableCell>{row.city}</TableCell>
                          <TableCell>{row.requestType}</TableCell>
                          <TableCell>{row.createdDate}</TableCell>
                          <TableCell>
                            <span className="flex items-center">
                              {row.requestTypeId === 1 ?
                                <>
                                  <MdVisibility className="centers-admin-table-icon" onClick={() => handleSurvivor(row.id)} />
                                </>
                                : row.requestTypeId === 2 ?
                                  (<>
                                    <Link to={`/confirm_change_request/${row.id}`}>
                                      <MdCheckCircle title="approve" className="centers-admin-table-icon" />
                                    </Link>
                                  </>
                                  )
                                  : row.requestTypeId === 3 ?
                                    (<>
                                      <MdModeEdit title="edit" className="centers-admin-table-icon" />
                                      <MdCheckCircle title="approve" className="centers-admin-table-icon" />
                                      <Link to="">
                                        <MdCancel title="decline" className="centers-admin-table-icon" />
                                      </Link>
                                    </>
                                    )
                                    : (<>
                                      <Link to={`/new_center/confirm/${row.id}`}>
                                        <MdCheckCircle title="approve" className="centers-admin-table-icon" />
                                      </Link>
                                    </>
                                    )

                              }
                            </span>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )
                }
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25,50]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Typography>
        }
      </Typography>
    </Container>
  );
}