import React from 'react';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next';
const Testimonial:React.FunctionComponent = () => {
   const {t, i18n} = useTranslation();
    return (
        <React.Fragment>

         {/* Testimonials */}
    <Typography>
       <Grid
          container
          direction="row"
          alignItems="stretch"
          spacing={0}
          >
          <Grid item md={4}>
          <Typography component="p" className="p-10 h-full" style={{backgroundImage: "url(" + "https://www.rainn.org/sites/all/themes/rainn/img/texture_blue-light_paint.png" + ")", backgroundSize: "160px auto"}}>
          {i18n.language == 'en' ? 
            (<a href="https://www.rainn.org/survivor-stories/julianna" className="block-quote smaller">
               <Typography component="p" className="text-tc-1 text-3xl pb-5">
                  {t('testimonial_one.title')}
               </Typography>
               <Typography component="span" className="uppercase font-bold text-pc-1" >
                  {t('testimonial_one.name')}
               </Typography>
            </a>)
            : 
            (<>
               <Typography component="p" className="text-tc-1 text-3xl pb-5">
                  {t('testimonial_one.title')}
               </Typography>
               <Button className="button-white mt-5" href="https://rainn.org/articles/despues-de-un-asalto-abuso-sexual">{t('testimonial_one.name')}</Button>
            </>)
         }
         </Typography>
      </Grid>
    <Grid item md={4}>
    <Typography component="div" className="p-10 h-full" style={{backgroundImage: "url(" + "https://www.rainn.org/sites/all/themes/rainn/img/texture_blue-lightest_halftone.png" + ")", backgroundSize: "160px auto"}}>
    <Typography component="p" className="text-pc-1 font-bold fs-32">
      {t('testimonial_two.title')}
    </Typography>
    <Button className="button-transparent-blue mt-5" href={t('testimonial_two.link')}>{t('testimonial_two.button')}</Button>
    </Typography>
    </Grid>
    <Grid item md={4}>
    <Typography component="div" className="p-10 h-full" style={{backgroundImage: "url(" + "https://www.rainn.org/sites/all/themes/rainn/img/texture_green_pixels.png" + ")", backgroundSize: "160px auto"}}>
    <Typography component="p" className="text-tc-1 font-bold fs-32">
    {t('testimonial_three.title')}
    </Typography>
    <Button className="button-transparent-white mt-5" href={t('testimonial_three.link')}>{t('testimonial_three.button')}</Button>
    </Typography>
    </Grid>
    </Grid>
    </Typography>   

        </React.Fragment>
    )
}

export default Testimonial
