import { Typography } from '@material-ui/core'
import React from 'react'
import Layout from './Layout/Layout'

function PageNotFound() {
  return (
    <div>
      <Layout>
      <Typography component="div" className="m-auto text-center m-10 h-36">
           <h3 className="text-pc-1 font-bold text-6xl mt-5">404</h3> 
           <p className="text-pc-1">Page not found.</p>
      </Typography>
      </Layout>
    </div>
  )
}

export default PageNotFound
